import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './index';
import { Employer } from '../../components/typescript/types';

export const employerApi = createApi({
  reducerPath: 'employerApi',
  baseQuery,
  endpoints: (builder) => ({
    getEmployerById: builder.query<Employer, { orgName: string; employerId: string }>({
      query: ({ orgName, employerId }) => `employer/${orgName}/${employerId}`,
    }),
  }),
});

export const { useGetEmployerByIdQuery } = employerApi;
