import dayjs from 'dayjs';
import { Individual, NoteBullet } from '../typescript/types';
import Icon from '../../asssets/icons';
import useTranslate from '../../hooks/useTranslate';
import { capitalize } from '../../helpers/helperFunctions';
import { Box, Typography } from '@mui/material';
import { InlineContainer, PinImage } from '../UI/StyledComponents/GlobalStyledComponents';
import { OverviewTitle } from '../UI/StyledComponents/ViewStyledComponents';
import { IconStyling } from '../UI/StyledComponents/RecommendationStyledComponent';
import { ServicesType } from '../../helpers/constants';

const IndividualNotes = (props: { individual?: Individual; showTitle?: boolean }) => {
  const { individual } = props;
  const t = useTranslate();

  const servicesTypeMap = (type: ServicesType) => {
    if (!type) {
      return null;
    }
    const map = {
      jobCoaching: t('jobCoaching') as string,
      preparationForInterview: t('preparationForInterview') as string,
      attendanceAtInterview: t('attendanceAtInterview') as string,
      caseManagement: t('caseManagement') as string,
    };
    return map[type];
  };
  return (
    <section>
      {props.showTitle === true && (
        <InlineContainer
          justifyContent="flex-start"
          className="iet-header inline full-width"
          marginTop="1.5em"
        >
          <img src={Icon.AttachmentIcon} alt="doc-icon" />
          <Typography variant="h3" color="black">
            {t('notesComments').toUpperCase()}
          </Typography>
        </InlineContainer>
      )}
      <Box className="individual-notes">
        {individual && JSON.parse(individual?.notes as unknown as string).length === 0 ? (
          <div className="listing-empty">
            <p className="lisiting-info">{t('emptyNotesMsgIndividual')}</p>
          </div>
        ) : individual ? (
          JSON.parse(individual?.notes as unknown as string).map(
            (note: NoteBullet, index: number) => {
              return (
                <div className="note-parent inline full-width listing" key={index + '-notesKey'}>
                  <PinImage className="pin-img" src={Icon.Notes} alt="note-bullet" />
                  <div className="detailed-note">
                    <p className="note-content">{note.body}</p>
                    <p className="note-meta">
                      {capitalize(t('addedBy'))} {note.providerName} {t('on')}{' '}
                      {dayjs(note.ts).format('MMM DD, YYYY')}
                    </p>
                    <p className="note-meta">
                      {t('servicesProvided') + ": "}  
                      {note.servicesProvided && note.servicesProvided.length > 0 ? (
                        note.servicesProvided.map((activity, index) => (
                          <span key={index}>
                            {servicesTypeMap(activity as ServicesType)}
                            {index < (note.servicesProvided?.length || 0) - 1 ? ', ' : null}
                          </span>
                        ))
                      ) : (
                        <span>None</span>
                      )}
                    </p>
                  </div>
                </div>
              );
            }
          )
        ) : null}
      </Box>
    </section>
  );
};

export default IndividualNotes;
