import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '.';
import { KeyWordSuggestions } from '../components/typescript/types';
import { compareLabel } from '../helpers/helperFunctions';

interface Tag {
  value: number;
  label: string;
  type: string;
}

const initialState = {
  tags: {
    skills: [],
    interests: [],
    all: [],
  },
  status: 'idle',
  error: null,
};

export const thunkFetchTags = createAsyncThunk(
  'keywords/fetch',
  async (params: { token: string }) => {
    return axios
      .get(`${process.env.REACT_APP_SERVER_URL}/api/v1/tags`, {
        headers: {
          Authorization: `Bearer ${params.token}`,
        },
      })
      .then((response) => {
        // console.info('Keywords Payload:', response.data);
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
);
export const thunkSubmitKeywordRequest = createAsyncThunk(
  'keywords/submit',
  async (params: { keywordObject: KeyWordSuggestions; token: string }) => {
    console.log('SENDING SUGGESTIONS!!!', params.keywordObject.data);
    return axios
      .post(`${process.env.REACT_APP_SERVER_URL}/api/v1/tags/suggestions`, params.keywordObject, {
        headers: {
          Authorization: `Bearer ${params.token}`,
        },
      })
      .then((response) => {
        // console.info('Keywords Payload:', response.data);
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
);

const keywordSlice = createSlice({
  name: 'keyword',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(thunkFetchTags.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(thunkFetchTags.fulfilled, (state, action) => {
        //console.info('Tag payload', action.payload);
        state.status = 'succeeded';
        const transformedArray = action.payload
          .map((tag: Tag) => {
            return { value: tag.value, label: tag.label };
          })
          .sort(compareLabel);

        state.tags.skills = action.payload
          .filter((tag: Tag) => tag.type === 'Skill')
          .map((tag: Tag) => {
            return { value: tag.value, label: tag.label };
          })
          .sort(compareLabel);
        state.tags.interests = action.payload
          .filter((tag: Tag) => tag.type === 'Interest')
          .map((tag: Tag) => {
            return { value: tag.value, label: tag.label };
          })
          .sort(compareLabel);
        state.tags.all = transformedArray;
      })
      .addCase(thunkSubmitKeywordRequest.fulfilled, () => {
        console.log('Submission Recieved!');
      });
  },
});

export const getTags = (state: RootState, t: (str: string) => any) => {
  return {
    skills: state.keyword.tags.skills.map((skill: { value: any; label: string }) => {
      return { value: skill.value, label: t(skill.label.trim()) };
    }),
    interests: state.keyword.tags.interests.map((interest: { value: any; label: string }) => {
      return { value: interest.value, label: t(interest.label.trim()) };
    }),
    all: state.keyword.tags.all.map((tag: { value: any; label: string }) => {
      return { value: tag.value, label: t(tag.label.trim()) };
    }),
  };
};
export const getSkills = (state: RootState, t: (str: string) => any) => {
  return state.keyword.tags.skills.map((skill: { value: any; label: string }) => {
    return { value: skill.value, label: t(skill.label.trim()) };
  });
};
export const getInterests = (state: RootState, t: (str: string) => any) => {
  return state.keyword.tags.interests.map((interest: { value: any; label: string }) => {
    return { value: interest.value, label: t(interest.label.trim()) };
  });
};
export const getTagStatus = (state: RootState) => state.keyword.status;
export default keywordSlice.reducer;
