import React, { useEffect, useState } from 'react';
import { Employment } from '../../typescript/types';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import dayjs from 'dayjs';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CurrentEmploymentHeader, HistoricalEmploymentHeader } from '../IndividualEmploymentTables';
import { InlineContainer } from '../../UI/StyledComponents/GlobalStyledComponents';
import useTranslate from '../../../hooks/useTranslate';

const MobileEmploymentHistoryCards = (props: { record: Employment[]; hours: number }) => {
  const { record, hours } = props;
  const [activeJobs, setActiveJobs] = useState<Employment[]>([]);
  const [oldJobs, setHistoricalJobs] = useState<Employment[]>([]);
  const t = useTranslate();

  useEffect(() => {
    let active = record?.filter((job: Employment) => job.isCurrent);
    let inactive = record?.filter((job: Employment) => !job.isCurrent);
    setActiveJobs((currState) => (currState = active));
    setHistoricalJobs((currState) => (currState = inactive));
  }, [record]);

  return (
    <>
      <Box>
        <CurrentEmploymentHeader hours={hours} />
        {activeJobs?.map((job: Employment, index: number) => {
          const startDate = dayjs(job.startDate);
          const endDate = dayjs(job.endDate);
          const elapsedMonths = dayjs().diff(startDate, 'month');
          const elapsedYears = dayjs().diff(startDate, 'year');
          console.info(job);
          return (
            <Accordion
              key={`active-list-${index}`}
              sx={{
                background: 'var(--background) !important',
              }}
            >
              <AccordionSummary
                key={`${job.employerName}-${index}`}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                onClick={(e) => e.stopPropagation()}
              >
                <Typography variant="h3" paddingLeft="15px">
                  {job.employerName} - {job.role}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  sx={{
                    padding: '15px',
                  }}
                >
                  <InlineContainer justifyContent={'flex-start'}>
                    <Typography variant="h4">Days: &nbsp; </Typography>
                    <Typography variant="body1">{job.day.join(', ')}</Typography>
                  </InlineContainer>
                  <InlineContainer justifyContent={'flex-start'}>
                    <Typography variant="h4">{t('totalHrsLabel')}: &nbsp;</Typography>
                    <Typography variant="body1">{job.hours}</Typography>
                  </InlineContainer>
                  <InlineContainer justifyContent={'flex-start'}>
                    <Typography variant="h4">Started: &nbsp; </Typography>
                    <Typography variant="body1"> {startDate.format('MMM, DD, YYYY')}</Typography>
                  </InlineContainer>
                  <InlineContainer justifyContent={'flex-start'}>
                    <Typography variant="h4">Ended: &nbsp; </Typography>
                    <Typography variant="body1"> {endDate.format('MMM, DD, YYYY')}</Typography>
                  </InlineContainer>
                  <InlineContainer justifyContent={'flex-start'}>
                    <Typography variant="h4">Duration: &nbsp;</Typography>
                    <Typography variant="body1">
                      {elapsedMonths > 12
                        ? elapsedYears + ' years.'
                        : elapsedMonths > 1
                          ? elapsedMonths + ' months.'
                          : elapsedMonths === 1
                            ? elapsedMonths + ' month.'
                            : 'less than a month'}
                    </Typography>
                  </InlineContainer>
                  <InlineContainer justifyContent={'flex-start'}>
                    <Typography variant="h4">Source:</Typography>
                    <Typography variant="body1">
                      &nbsp; {job.isPlaceMaker ? 'MJM' : 'Other'}
                    </Typography>
                  </InlineContainer>
                </Box>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Box>

      <Box>
        <HistoricalEmploymentHeader />
        {oldJobs?.map((job: Employment, index: number) => {
          const startDate = dayjs(job.startDate);
          const endDate = dayjs(job.endDate);
          const elapsedMonths = dayjs().diff(startDate, 'month');
          const elapsedYears = dayjs().diff(startDate, 'year');
          console.info(job);
          return (
            <Accordion
              key={`inactive-list-${index}`}
              sx={{
                background: 'var(--background) !important',
              }}
            >
              <AccordionSummary
                key={`${job.employerName}-${index}`}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                onClick={(e) => e.stopPropagation()}
              >
                <Typography variant="h3" paddingLeft="15px">
                  {job.employerName} - {job.role}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  sx={{
                    padding: '15px',
                  }}
                >
                  <InlineContainer justifyContent={'flex-start'}>
                    <Typography variant="h4">Days: &nbsp; </Typography>
                    <Typography variant="body1">{job.day.join(', ')}</Typography>
                  </InlineContainer>
                  <InlineContainer justifyContent={'flex-start'}>
                    <Typography variant="h4">{t('totalHrsLabel')}: &nbsp;</Typography>
                    <Typography variant="body1">{job.hours}</Typography>
                  </InlineContainer>
                  <InlineContainer justifyContent={'flex-start'}>
                    <Typography variant="h4">Source:</Typography>
                    <Typography variant="body1">
                      &nbsp; {job.isPlaceMaker ? 'MJM' : 'Other'}
                    </Typography>
                  </InlineContainer>
                </Box>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Box>
    </>
  );
};

export default MobileEmploymentHistoryCards;
