import { Button, Typography } from '@mui/material';
import React from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import Icon from '../asssets/icons';
import NavigationPlaceholderComponent from '../components/NavigationComponent/NavigationPlaceholderComponent';
import { addItemBtnStyling } from '../helpers/styling';
import PageTitleComponent from '../components/PageTitleComponent';
import useTranslate from '../hooks/useTranslate';
import EmployerComponent from '../components/EmployerComponents/EmployerComponent';
import useDetectResize from '../hooks/useDetectResize';
import Box from '@mui/system/Box';
import MobileEmployersTable from '../components/EmployerComponents/MobileTablet/MobileEmployersTable';
import {
  InlineContainer,
  SectionHeaderWrapper,
  SectionTitle,
  TitleBadgeWrapper,
} from '../components/UI/StyledComponents/GlobalStyledComponents';
import MobileEmployerComponent from '../components/EmployerComponents/MobileTablet/MobileEmployerComponent';
import MobileRecommendationsComponent from '../components/RecommendationComponents/MobileTablet/MobileRecommendationsComponent';

const EmployersCreateComponent = React.lazy(
  () => import('../components/EmployerComponents/EmployersCreateComponent')
);
const RecommendationsComponent = React.lazy(
  () => import('../components/RecommendationComponents/RecommendationsComponent')
);
const DataTable = React.lazy(
  () => import('../components/EmployerComponents/EmployerTableComponent')
);

function EmployersComponent(props: { isNavCollapsed: boolean }) {
  const { isNavCollapsed } = props;
  const t = useTranslate();
  const { isMobile, isTablet, isLaptop, isDesktop } = useDetectResize();

  const EmployersListDesktopLaptopView = () => {
    return (
      <>
        <PageTitleComponent greeting={t('employerNav')} />

        <div className="employers-table-cont">
          <EmployerHeaderContent />
          <DataTable />
        </div>
      </>
    );
  };

  const MobileEmployerWrapper = () => {
    return (
      <Box>
        <MobileEmployersTable />
      </Box>
    );
  };

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <React.Suspense fallback={<NavigationPlaceholderComponent />}>
              {isDesktop || isLaptop ? (
                <EmployersListDesktopLaptopView />
              ) : isMobile || isTablet ? (
                <MobileEmployerWrapper />
              ) : (
                <p>Condition not met -_-</p>
              )}
            </React.Suspense>
          }
        />
        <Route
          path="create"
          element={
            <React.Suspense fallback={<NavigationPlaceholderComponent />}>
              {<EmployersCreateComponent isEdit={false} isNavCollapsed={isNavCollapsed} />}
            </React.Suspense>
          }
        />
        <Route
          path=":parentCompName/:employerId"
          element={
            <React.Suspense fallback={<NavigationPlaceholderComponent />}>
              {isDesktop || isLaptop ? (
                <EmployerComponent />
              ) : isMobile || isTablet ? (
                <MobileEmployerComponent isNavCollapsed={isNavCollapsed} />
              ) : (
                <p>Condition not met -_-</p>
              )}
            </React.Suspense>
          }
        />
        <Route
          path=":parentCompName/:employerId/edit"
          element={
            <React.Suspense fallback={<NavigationPlaceholderComponent />}>
              {<EmployersCreateComponent isEdit={true} isNavCollapsed={isNavCollapsed} />}
            </React.Suspense>
          }
        />
        <Route
          path=":parentCompName/:employerId/recommendations/:searchState/:userType"
          element={
            <React.Suspense fallback={<>Loading....</>}>
              {isDesktop || isLaptop ? (
                <RecommendationsComponent />
              ) : isMobile || isTablet ? (
                <MobileRecommendationsComponent />
              ) : (
                <p>Condition not met -_-</p>
              )}
            </React.Suspense>
          }
        />
      </Routes>
    </>
  );
}

export default EmployersComponent;

export const EmployerHeaderContent = () => {
  const navigate = useNavigate();
  const { isMobile, isTablet } = useDetectResize();
  const t = useTranslate();

  return (
    <SectionHeaderWrapper className="section-header">
      <InlineContainer
        width={isTablet ? '90% !important' : '100%'}
        marginLeft={isMobile ? 'none' : 'auto'}
        marginRight={isMobile ? 'none' : 'auto'}
        flexGrow={isMobile || isTablet ? 'none' : 1}
      >
        <TitleBadgeWrapper>
          <SectionTitle variant="h2" marginRight={isMobile || isTablet ? '0px' : '10px'}>
            {t('empList').toUpperCase()}
          </SectionTitle>
          <Typography
            className="badge"
            variant="caption"
            color="#050505"
            fontWeight={'bold'}
            style={{ minHeight: '20px' }}
          >
            <img src={Icon.LockIcon} alt="Private" style={{ paddingRight: '5px' }} />
            {isMobile ? null : t('privateBtn')}
          </Typography>
        </TitleBadgeWrapper>

        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => {
            navigate('create');
          }}
          sx={addItemBtnStyling}
        >
          {isMobile ? 'Add' : t('addEmployerBtn')}
        </Button>
      </InlineContainer>
    </SectionHeaderWrapper>
  );
};
