import { useDispatch, useSelector } from 'react-redux';
import useTranslate from '../hooks/useTranslate';
import {
  deleteProvider,
  deleteProviderById,
  getProvidersInOrganization,
  updateProviderPermission,
  updateProviderRole,
} from '../store/providerSlice';
import {
  Avatar,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import useDetectResize from '../hooks/useDetectResize';
import { Provider } from '../components/typescript/types';
import { InlineContainer } from '../components/UI/StyledComponents/GlobalStyledComponents';
import { getFullName, stringAvatar } from '../helpers/helperFunctions';
import { DeleteOutlineOutlined } from '@mui/icons-material';
import { getAccessToken } from '../store/authSlice';
import { useAppDispatch } from '../components/typescript/hooks';
import { useEffect, useState } from 'react';
import { updateIndividualProviders } from '../store/individualSlice';
import { updateJobProviders } from '../store/jobSlice';

interface AdminCardProps {
  provider: Provider;
}
export default function ProvAdminComponent() {
  const t = useTranslate();
  const initialProviderList = useSelector(getProvidersInOrganization);
  const { isDesktop, isLaptop } = useDetectResize();
  const [orgProviderList, setOrgProviderList] = useState(initialProviderList);

  useEffect(() => {
    setOrgProviderList(initialProviderList);
  }, [initialProviderList]);

  return (
    <Box>
      <Box
        className="profile-title"
        sx={{
          padding: isDesktop || isLaptop ? '10px' : '0',
        }}
      >
        {(isDesktop || isLaptop) && <Typography variant="h1">{t('provMgt')}</Typography>}
        <Typography sx={{ color: 'text.primary' }} mt={1}>
          {t('provMgtText')}
        </Typography>
      </Box>
      <Box
        sx={{
          overflowY: 'auto',
        }}
        mt={1}
      >
        {orgProviderList.map((provider) => (
          <AdminCard
            key={provider.id}
            provider={provider}
            setOrgProviderList={setOrgProviderList}
          />
        ))}
      </Box>
    </Box>
  );
}

function AdminCard({
  provider,
  setOrgProviderList,
}: AdminCardProps & { setOrgProviderList: React.Dispatch<React.SetStateAction<Provider[]>> }) {
  const [prov, setProv] = useState(provider);
  const dispatch = useAppDispatch();
  const t = useTranslate();
  const token = useSelector(getAccessToken);

  const handleRoleChange = async (event: SelectChangeEvent) => {
    if (window.confirm("Do you want to update this provider's role")) {
      const newRole = event.target.value === 'Admin';

      try {
        const updatedProvider = await dispatch(
          updateProviderPermission({
            providerId: prov.id,
            isAdmin: newRole,
            orgId: prov.orgId,
            token: token!,
          })
        ).unwrap();

        setProv((prevProv) => ({
          ...prevProv,
          isAdmin: updatedProvider[0].isAdmin,
        }));
        dispatch(updateProviderRole({ providerId: provider.id, isAdmin: newRole }));
      } catch (error) {
        console.error('Failed to update provider role:', error);
      }
    }
  };

  const handleProviderDelete = async (provId: string) => {
    if (window.confirm('Do you want to delete this provider?')) {
      try {
        const deleteResult = await dispatch(
          deleteProvider({
            providerId: provId,
            orgId: prov.orgId,
            token: token!,
          })
        ).unwrap();

        setOrgProviderList((prevList) => prevList.filter((p) => p.id !== provId));
        dispatch(
          updateIndividualProviders({
            providerName: deleteResult.providerName,
            oldprovId: provId,
            providerId: deleteResult.providerId,
          })
        );
        dispatch(
          updateJobProviders({
            providerName: deleteResult.providerName,
            oldprovId: provId,
            providerId: deleteResult.providerId,
            providerEmail: deleteResult.providerEmail,
            providerPhone: deleteResult.providerPhone,
          })
        );
        dispatch(deleteProviderById(provId));
      } catch (error) {
        console.error('Failed to delete provider:', error);
      }
    }
  };

  return (
    <Box
      className="parent-activity-card"
      display={'flex'}
      flexDirection={'row'}
      alignItems="center"
      justifyItems={'space-between'}
      sx={{
        padding: '10px',
      }}
    >
      <InlineContainer justifyContent="flex-start" width={'67.5%!important'}>
        <Avatar {...stringAvatar(getFullName(prov))} />
        <Typography fontWeight={600}>
          {getFullName(prov)}
          <span style={{ fontWeight: 'normal' }}> - {prov.email}</span>
        </Typography>
      </InlineContainer>
      <FormControl variant="outlined" size="small" sx={{ minWidth: 120, mx: 'auto' }}>
        <InputLabel>Manage Role</InputLabel>
        <Select
          value={prov.isAdmin === true ? 'Admin' : 'User'}
          onChange={handleRoleChange}
          label="Manage Role"
        >
          <MenuItem value="Admin">Admin</MenuItem>
          <MenuItem value="User">User</MenuItem>
        </Select>
      </FormControl>
      <Button
        variant="contained"
        sx={{ backgroundColor: '#d32f2f !important', color: 'white', ml: 'auto' }}
        onClick={() => handleProviderDelete(prov.id)}
      >
        {<DeleteOutlineOutlined sx={{ color: 'white' }} />}
        {' ' + t('delete')}
      </Button>
    </Box>
  );
}
