import React, {
  DetailedHTMLProps,
  HTMLAttributes,
  LegacyRef,
  Ref,
  useEffect,
  useState,
} from 'react';
import { ViewAccordion } from '../../EmployerComponents/MobileTablet/ReusableAccordion';
import { detailedAccordingStyling } from '../../../helpers/styling';
import useTranslate from '../../../hooks/useTranslate';
import { InlineContainer } from '../../UI/StyledComponents/GlobalStyledComponents';
import { Button, Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { WidgetListItems } from '../WidgetComponent';
import { useSelector } from 'react-redux';
import { getEmployers } from '../../../store/employerSlice';
import { getAllIndividuals, getIndividualsCount } from '../../../store/individualSlice';
import { getAllJobs, getJobCount } from '../../../store/jobSlice';
import { nanoid } from '@reduxjs/toolkit';
import Icon from '../../../asssets/icons';
import { capitalize } from '../../../helpers/helperFunctions';
import { WidgetFormLink } from '../../UI/StyledComponents/DashboardStyledComponents';
import TopWidgetComponent from '../TopWidgetComponent';
import useDetectResize from '../../../hooks/useDetectResize';

interface MobileDashProps {
  navigationOpen: boolean;
}

const MobileDashboard = (props: MobileDashProps) => {
  const t = useTranslate();
  const employerList = useSelector(getEmployers);
  const individualsList = useSelector(getAllIndividuals);
  const jobsList = useSelector(getAllJobs);
  const openJobsCount = useSelector(getJobCount);
  const unplacedIndividuals = useSelector(getIndividualsCount);
  const [expanded, setExpanded] = useState<string>('panel1');
  const { navigationOpen } = props;
  const { isMobile } = useDetectResize();

  const accordionSummaryContentSx = {
    ...detailedAccordingStyling,
    '.MuiAccordionDetails-root': {
      maxHeight: '35vh',
      overflowY: 'scroll',
      scrollbarWidth: 'thin',
      scrollbarColor: 'var(--secondary-color)',
      '::-webkit-scrollbar': {
        width: 6,
        height: 6,
      },
      '::-webkit-scrollbar-track': {
        backgroundColor: '#d3d9de',
      },
      '::-webkit-scrollbar-thumb': {
        backgroundColor: 'var(--secondary-color)',
        borderRadius: 2.5,
      },
    },
    '& > div > div.MuiAccordionSummary-content': {
      margin: '22px 0 !important',
    },
  };

  useEffect(() => {
    setExpanded((currState) => (currState = 'panel1'));
  }, []);

  useEffect(() => {
    if (navigationOpen) setExpanded((currState) => (currState = ''));
  }, [navigationOpen]);

  const NavButton = (props: { widget: string }) => {
    const { widget } = props;
    return (
      <InlineContainer justifyContent="flex-end" sx={{ marginTop: '-10px' }}>
        <WidgetFormLink>
          <Link key={nanoid()} to={`/${widget}s/create`}>
            <img id="dash-widget-icon-2" src={Icon.AddField} alt="+" />
            <Typography variant="subtitle2" color="text.secondary">
              {t('add')} {widget === 'Individual' ? t('a(f)') : t('a(m)')}{' '}
              {true ? capitalize(widget.toLowerCase()) : t(widget.toLowerCase())}
            </Typography>
          </Link>
        </WidgetFormLink>
      </InlineContainer>
    );
  };
  return (
    <Box
      sx={{
        padding: '20px',
        height: 'inherit',
      }}
    >
      <InlineContainer justifyContent={'space-between'} sx={{ padding: '0 0 10px 0' }}>
        <TopWidgetComponent
          title={isMobile ? 'Jobs open' : t('globalJobsTitle')}
          count={openJobsCount}
          IconImg={Icon.WhiteJicon}
          isMobile={true}
          subTitle={isMobile ? 'Network wide' : ''}
        />
        <TopWidgetComponent
          title={isMobile ? 'Individuals' : t('privateIndividualTitle')}
          count={unplacedIndividuals}
          IconImg={Icon.WhiteIicon}
          isMobile={true}
          subTitle={isMobile ? 'Open to work' : ''}
        />
      </InlineContainer>

      <Box>
        <ViewAccordion
          title={t('jobsWidgetTitle')}
          sx={accordionSummaryContentSx}
          hasIcon
          navButton={<NavButton widget="Job" />}
          ac_id="panel1"
          expanded={expanded}
          setExpanded={setExpanded}
        >
          <WidgetListItems
            widget="Job"
            list={jobsList!.filter((job) => job.isJobActive)}
            isMobileAndJobWidget
          />
        </ViewAccordion>

        <ViewAccordion
          title={t('individualWidgetTitle')}
          sx={accordionSummaryContentSx}
          hasIcon
          isPrivateIcon
          navButton={<NavButton widget="Individual" />}
          ac_id="panel2"
          expanded={expanded}
          setExpanded={setExpanded}
        >
          <WidgetListItems
            widget="Individual"
            list={individualsList!.filter((individual) => individual.status)}
          />
        </ViewAccordion>

        <ViewAccordion
          title={t('privateEmployerTitle')}
          sx={accordionSummaryContentSx}
          hasIcon
          isPrivateIcon
          navButton={<NavButton widget="Employer" />}
          ac_id="panel3"
          expanded={expanded}
          setExpanded={setExpanded}
        >
          <WidgetListItems
            widget="Employer"
            list={employerList!.filter((employer) => employer.status)}
          />
        </ViewAccordion>
      </Box>
    </Box>
  );
};

export default MobileDashboard;

function useRef() {
  throw new Error('Function not implemented.');
}
