import { Accordion, AccordionSummary, AccordionDetails, Typography, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ReactNode, RefObject, useRef, useState } from 'react';
import {
  InlineContainer,
  TitleBadgeWrapper,
} from '../../UI/StyledComponents/GlobalStyledComponents';
import Icon from '../../../asssets/icons';
import useDetectResize from '../../../hooks/useDetectResize';

interface AccordionProps {
  title: string;
  children: ReactNode;
  sx?: any;
  hasIcon?: boolean;
  isPrivateIcon?: boolean;
  navButton?: ReactNode;
  ac_id: string;
  expanded: string;
  setExpanded: React.Dispatch<React.SetStateAction<string>>;
}

// A resuable accordion component we can use across different screens and pages, will nedd some styling but creating the skeleton for it
export const ViewAccordion = (props: AccordionProps) => {
  const { title, children, sx, hasIcon, isPrivateIcon, navButton, ac_id, expanded, setExpanded } =
    props;
  const { isTablet } = useDetectResize();
  const accordionRef = useRef<null | HTMLDivElement>(null);
  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded((currState) => (currState = panel));
  };
  return (
    <>
      <Accordion
        className="accordion-component"
        sx={sx}
        ref={accordionRef as unknown as RefObject<HTMLDivElement>}
        expanded={expanded === ac_id}
        onClick={() => {
          setExpanded((currState) => (currState = expanded === ac_id ? '' : ac_id));
          accordionRef.current!.scrollIntoView({
            block: 'start',
            inline: 'center',
            behavior: 'smooth',
          });
        }}
        onChange={handleChange(ac_id)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            position: 'relative',
          }}
        >
          <Box sx={{ display: 'block', width: '100%' }}>
            <InlineContainer justifyContent={'flex-start'}>
              <TitleBadgeWrapper sx={{ minHeight: '20px' }}>
                <Typography variant="h2" style={{ width: 'fit-content' }} marginRight="5px">
                  {title}
                </Typography>

                {hasIcon && isPrivateIcon && (
                  <Typography
                    className="badge"
                    variant="caption"
                    sx={{ '& > img': { marginRight: hasIcon ? '5px' : '0px' } }}
                  >
                    <img src={Icon.LockIcon} alt="Private" />
                    {hasIcon ? ' Private' : null}
                  </Typography>
                )}

                {hasIcon && !isPrivateIcon && (
                  <Typography
                    className="badge"
                    variant="caption"
                    sx={{ '& > img': { marginRight: hasIcon ? '5px' : '0px' } }}
                  >
                    <img src={Icon.GlobeIcon} alt="Public" />
                    {hasIcon ? ' Public' : null}
                  </Typography>
                )}
              </TitleBadgeWrapper>
            </InlineContainer>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ width: '100%' }}>
          {navButton && navButton}
          {children}
        </AccordionDetails>
      </Accordion>
    </>
  );
};
