import { createApi } from '@reduxjs/toolkit/query/react';
import { Job } from '../../components/typescript/types';
import { baseQuery } from './index';

export const jobApi = createApi({
  reducerPath: 'jobApi',
  baseQuery,
  endpoints: (builder) => ({
    getJobById: builder.query<Job, { orgId: string; jobId: string }>({
      query: ({ orgId, jobId }) => `job/${orgId}/${jobId}`,
    }),
  }),
});

export const { useGetJobByIdQuery } = jobApi;
