import React from 'react';
import { Employer } from '../../typescript/types';
import Box from '@mui/system/Box';
import { useSelector } from 'react-redux';
import { getProviderById } from '../../../store/providerSlice';
import { RootState } from '../../../store';
import Typography from '@mui/material/Typography';
import { capitalize, getFullName, stringAvatar } from '../../../helpers/helperFunctions';
import {
  InlineContainer,
  MobileCardTitle,
  MultiLineOverFlow,
  StatusBadge,
} from '../../UI/StyledComponents/GlobalStyledComponents';
import useTranslate from '../../../hooks/useTranslate';
import { Avatar } from '@mui/material';

const EmployerMobileTableCard = (props: { emp: Employer }) => {
  const t = useTranslate();
  const { emp } = props;
  const providerDetails = useSelector((state: RootState) =>
    getProviderById(state, emp.leadProviderId)
  );

  return (
    <Box
      className="emp-mob-card"
      sx={{
        display: 'inline-grid',
        gridTemplateRows: '60% 40%',
        gridTemplateColumns: '1fr',
        height: '140px',
        width: '100%',
        flex: 1,
      }}
    >
      <Box
        className="emp-mob-top"
        sx={{
          display: 'flex',
          flexFlow: 'column',
          flex: 1,
          borderBottom: '1px solid',
          borderColor: '#d3d9de',
        }}
      >
        <InlineContainer
          justifyContent={'space-between'}
          alignItems={'flex-start'}
          sx={{
            padding: '10px 0px 2px 10px',
            height: `calc(140px * 0.60)`,
          }}
        >
          <Box
            className="mob-titles"
            sx={{
              minHeight: '60px',
              display: 'inline-grid',
              alignItems: 'flex-start',
              gridTemplateRows: 'min-content min-content',
              gap: '3px',
              marginTop: '5px',
            }}
          >
            <MultiLineOverFlow
              variant="h3"
              color="black"
              sx={{
                fontWeight: 500,
                fontFamily: 'LexendDeca',
              }}
              lineCount={2}
            >
              {emp.orgName}
            </MultiLineOverFlow>
            {emp.multiLocation && (
              <MultiLineOverFlow
                variant="h4"
                color="text.secondary"
                sx={{ paddingTop: '0.4em' }}
                lineCount={1}
              >
                {emp.parentCompName}
              </MultiLineOverFlow>
            )}
          </Box>

          <Box>
            <StatusBadge backgroundColor={emp.status ? '#3d7652' : '#eb393d'}>
              <Typography variant="caption" fontWeight="400">
                {emp.status ? capitalize(t('active')) : capitalize(t('inactive'))}
              </Typography>
            </StatusBadge>
          </Box>
        </InlineContainer>
      </Box>
      <Box
        className="emp-mob-bottom"
        sx={{
          display: 'inline-grid',
          gridTemplateRows: '37% 37%',
          gap: '2px',
          alignContent: 'center',
          justifyItems: 'center',
          alignItems: 'center',
          height: 'calc(140px * 0.40)',
          marginTop: '0px',
        }}
      >
        <MobileCardTitle
          variant="h5"
          sx={{
            fontFamily: 'LexendDeca',
            textAlign: 'center',
            margin: '3px 0',
          }}
        >
          {t('supportLead').toUpperCase()}
        </MobileCardTitle>
        <Typography
          sx={{
            textAlign: 'center',
            lineHeight: 1.2,
          }}
        >
          {getFullName(providerDetails)}
        </Typography>
      </Box>
    </Box>
  );
};

export default EmployerMobileTableCard;

export const EmployerTabletCard = (props: { emp: Employer }) => {
  const t = useTranslate();
  const { emp } = props;
  const providerDetails = useSelector((state: RootState) =>
    getProviderById(state, emp.leadProviderId)
  );

  return (
    <Box
      className="emp-tablet-card"
      sx={{
        display: 'grid',
        gridTemplateRows: '55% 45%',
        gridTemplateColumns: '1fr',
        height: '145px',
        flex: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexFlow: 'column',
          flex: 1,
          borderBottom: '1px solid',
          borderColor: '#d3d9de',
        }}
      >
        <InlineContainer
          justifyContent={'space-between'}
          alignItems={'flex-start'}
          sx={{
            padding: '0px 10px',
          }}
        >
          <InlineContainer justifyContent={'flex-start'} alignItems={'center'}>
            <Avatar {...stringAvatar(emp.parentCompName.trim(), true, emp.orgName)} />
            <Box
              sx={{
                minHeight: '60px',
                display: 'inline-grid',
                alignItems: 'flex-start',
                paddingTop: '10px',
              }}
            >
              <Typography variant="h3" sx={{ fontWeight: 600, fontFamily: 'LexendDeca' }}>
                {emp.orgName}
              </Typography>
              {emp.multiLocation && (
                <Typography variant="h4" color="text.secondary" sx={{ paddingTop: '0.4em' }}>
                  {emp.parentCompName}
                </Typography>
              )}
            </Box>
          </InlineContainer>

          <Box sx={{ paddingTop: '10px' }}>
            <StatusBadge backgroundColor={emp.status ? '#3d7652' : '#eb393d'}>
              {emp.status ? capitalize(t('active')) : capitalize(t('inactive'))}
            </StatusBadge>
          </Box>
        </InlineContainer>
      </Box>
      <Box className="emp-details" display="block" marginTop="10px">
        <Typography
          variant="h3"
          color="black"
          sx={{
            fontFamily: 'LexendDeca',
            textAlign: 'center',
            margin: '3px 0',
          }}
        >
          {t('supportLead')}
        </Typography>
        <Typography
          sx={{
            textAlign: 'center',
            lineHeight: 1.2,
          }}
        >
          {getFullName(providerDetails)}
        </Typography>
      </Box>
    </Box>
  );
};
