import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import type { RootState } from '../store';
import { ActivityProp } from '../components/typescript/types';

interface ActivitySlice {
  status: string;
  error: string | null;
  myActivity: ActivityProp[];
}

const initialState: ActivitySlice = {
  status: 'idle',
  error: null,
  myActivity: [],
};

const activitySlice = createSlice({
  name: 'activity',
  initialState,
  reducers: {
    setActivityList: (state: ActivitySlice, action: PayloadAction<{ data: ActivityProp[] }>) => {
      console.warn('Setting list! ', action.payload);
      state.myActivity = [...action.payload.data];
    },
  },
  extraReducers: (builder) => {
    // refer to: https://redux-toolkit.js.org/api/createSlice
    builder
      .addCase(getIndividualActivity.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getIndividualActivity.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.myActivity = [...action.payload.data];
        console.warn('THUNK ACTIVITY: ', action.payload.data);
        return;
      })
      .addCase(getIndividualActivity.rejected, (state, action) => {
        state.status = 'error';
        state.error = action.error.message!;
      });
  },
});

export const postActivityReport = createAsyncThunk(
  'activity/postActivity',
  async (params: {
    activityObjArr: { field: string; value: string }[];
    info: {
      orgId: string;
      provId: string;
      indId: string;
      tag: string;
      source: string;
    };
    token: string;
  }) => {
    return axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/api/b2c/activity/b2b/create`,
        { rawList: params.activityObjArr!, info: { ...params.info } },
        {
          headers: {
            Authorization: `Bearer ${params.token}`,
          },
        }
      )
      .then((response) => {
        console.info('Reducer Activity Record POST:', response);
        return response.data;
      })
      .catch((err) => {
        return err.message;
      });
  }
);

export const updateAppliedJobStatus = createAsyncThunk(
  'b2c-user/applied-jobs-update',
  async (params: {
    indId: string;
    newStatus: string;
    jobId: string;
    orgId: string;
    provId: string;
    token: string;
  }) => {
    return axios
      .put(
        `${process.env.REACT_APP_SERVER_URL}/api/b2c/user/b2b/applied-jobs-status`,
        { params },
        {
          headers: {
            Authorization: `Bearer ${params.token}`,
          },
        }
      )
      .then((response) => {
        console.info('Reducer Applied Jobs update:', response);
        return response.data;
      })
      .catch((err) => {
        return err.message;
      });
  }
);

export const addLogReport = createAsyncThunk(
  'activity/logUser',
  async (params: {
    activityObjArr: { field: string; value: string }[];
    info: {
      orgId: string;
      provId: string;
      indId: string;
      tag: string;
    };
    token: string;
  }) => {
    return axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/api/b2c/activity/log`,
        { rawList: params.activityObjArr!, info: { ...params.info } },
        {
          headers: {
            Authorization: `Bearer ${params.token}`,
          },
        }
      )
      .then((response) => {
        console.info('Reducer logged user!:', response);
        return response.data;
      })
      .catch((err) => {
        return err.message;
      });
  }
);

export const getIndividualActivity = createAsyncThunk(
  'individual-activity/get',
  async (params: { indId: string; token: string }) => {
    return axios
      .get(`${process.env.REACT_APP_SERVER_URL}/api/b2c/activity/b2b/${params.indId}`, {
        headers: {
          Authorization: `Bearer ${params.token}`,
        },
      })
      .then((response) => {
        console.info('Reducer Activity FETCH', response);
        return response.data;
      })
      .catch((err) => {
        return err.message;
      });
  }
);

export const thunkFetchCustomActivity = createAsyncThunk(
  'activity/get',
  async (params: { indId: string; start: string; end: string; token: string }) => {
    return axios
      .get(`${process.env.REACT_APP_SERVER_URL}/api/b2c/activity/custom/${params.indId}`, {
        headers: {
          Authorization: `Bearer ${params.token}`,
        },
        params: {
          start: params.start,
          end: params.end,
        },
      })
      .then((response) => {
        console.info('Reducer Activity FETCH', response);
        return response.data;
      })
      .catch((err) => {
        return err.message;
      });
  }
);

export const getAllActivity = (state: RootState) => {
  return state.activity.myActivity;
};
export const { setActivityList } = activitySlice.actions;

export default activitySlice.reducer;
