import { styled } from '@mui/material/styles';
import { Avatar, Box, Button, Drawer, Paper, TextField, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { SystemProps, Theme } from '@mui/system';
import zIndex from '@mui/material/styles/zIndex';

export interface StyledProps extends SystemProps<Theme> {
  backgroundColor?: string;
  background?: string;
  isInterest?: boolean;
  lineCount?: number;
  isTablet?: boolean;
  isMobile?: boolean;
}

export interface SupportCardProps extends SystemProps<Theme> {
  hasBorder?: boolean;
  centerText?: boolean;
  hideBorder?: boolean;
  isMobile?: boolean;
}

//Styled Components
export const CardItem = styled(Paper)(({}) => ({
  display: 'inline-flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: 'var( --background)',
  padding: '1px 2px 1px 5px',
  textAlign: 'left',
  color: 'var( --secondary-font)',
  width: '98%',
  boxShadow: 'unset',
  border: '1px solid #0379a017',
  borderRadius: '5px',
}));

export const CardItemInfo = styled(Box)(({}) => ({
  display: 'inline-flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
}));

export const NumberBadge = styled(Box)(({}) => ({
  display: 'inline',
  padding: '0px 4px',
  borderRadius: '20%',
  marginLeft: '1px',
  fontWeight: 'bolder',
}));

export const CardItemTotalCount = styled(Box)(({}) => ({
  background: 'var(--secondary-color)',
  height: 'inherit',
  padding: '10px 15px',
  color: 'white',
  borderRadius: '4px',
  fontWeight: 'bolder',
}));

export const AltCardItemTotalCount = styled(CardItemTotalCount)(({}) => ({
  padding: '10px 0px 10px 10px',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const CrossBrowserMultiLine = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'lineCount',
})<StyledProps>(({ theme, lineCount }) => ({
  display: 'block', //pure css
  WebkitBoxOrient: 'vertical',
  position: 'relative',

  lineHeight: '1.2 !important',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  padding: '0 !important',

  whiteSpace: 'normal',
  [theme.breakpoints.down('md')]: {},
  [theme.breakpoints.up('md')]: {},
}));
export const MultiLineOverFlow = styled(CrossBrowserMultiLine, {
  shouldForwardProp: (prop) => prop !== 'lineCount',
})<StyledProps>(({ theme, lineCount }) => ({
  display: '-webkit-box', //if supported, if not falls back to inherited display prop.
  WebkitLineClamp: `${lineCount}`, //if supported
  height: `calc(1em * 1.2 * ${lineCount})`,
  //textWrap: 'balance',
  [theme.breakpoints.down('md')]: {},
  [theme.breakpoints.up('md')]: {},
}));

export const NavigationContainer = styled(Box)(({ theme }) => ({
  height: 'fit-content',
  width: '100%',

  [theme.breakpoints.up('sm')]: {
    width: '90%',
    margin: '10px 0',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  [theme.breakpoints.up('md')]: {
    width: '100%',
    margin: '0px',
    marginBottom: '10px',
  },
}));

export const MJMLogo = styled('img')(({ theme }) => ({
  height: 'auto',
  padding: '0px 15px',
  zIndex: '10',
  [theme.breakpoints.down('md')]: {
    width: '275px',
    [theme.breakpoints.down('sm')]: {
      width: '190px',
    },
  },
}));

export const TopNavIcon = styled('img')(({ theme }) => ({
  width: '35px',
  height: '35px',
  borderRadius: '10px',
  border: '1px solid var(--secondary-font)',
  padding: '4px 8px',
  background: 'white',
  [theme.breakpoints.down('md')]: {
    height: '45px',
    width: '45px',
  },
}));

export const AppContainer = styled(Box)(({ theme, display, padding }) => ({
  display: `${display}`, //toggle between mobile&tablet vs. desktop views
  height: 'max-content',
  maxWidth: 'inherit',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'var( --background)',
  padding: `${padding || '15px'}`,

  [theme.breakpoints.up('md')]: {
    height: 'inherit',
    alignItems: 'flex-start',
    maxHeight: '100vh',
    paddingLeft: '0px',
  },
  [theme.breakpoints.up('lg')]: {
    '@media (min-height: 1000px)': {
      alignItems: 'center',
    },
  },
}));

export const MainDisplayModal = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isMobile' && prop !== 'isTablet',
})<StyledProps>(({ theme, height, color, backgroundColor, isMobile, isTablet }) => ({
  width: '100%',
  height: '75vh', //98vh;
  maxHeight: '1000px',
  position: 'relative',
  border: `${isMobile || isTablet ? 'none' : `1px solid ${theme.palette.primary.dark}`}`,
  backgroundColor: `${backgroundColor || 'white'}`,
  color: `${theme.palette.text.primary}`, //Main Text setting

  '@media (max-width: 1080px) and (min-height: 700px)': {
    height: '90.5vh!important',
  },

  [theme.breakpoints.down('sm')]: {
    height: 'fit-content',
    '@media (min-height: 550px)': {
      minHeight: `calc(${height}px * 0.73)`,
      // paddingBottom: '40px',
    },
    // height adjustments for mobile
    '@media (min-height: 650px)': {
      minHeight: `calc(${height}px * 0.75)`,
      // paddingBottom: '40px',
    },
    '@media (min-height: 700px)': {
      minHeight: `calc(${height}px * 0.74)`,
      paddingBottom: '10px',
    },
    '@media (min-height: 800px)': {
      minHeight: `calc(${height}px * 0.80)`,
      paddingBottom: '5px',
    },
    '@media (min-height: 1000px)': {
      minHeight: `calc(${height}px * 0.80)`,
      paddingBottom: '5px',
    },
  },

  [theme.breakpoints.up('sm')]: {
    //everything tablet upwards
    height: 'max-content',
    maxWidth: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
    '@media (min-height: 1000px)': {
      minHeight: `calc(${height}px * 0.81)`,
    },
  },

  [theme.breakpoints.down('md')]: {
    flexGrow: 1,
    marginBottom: '60px',
  },

  [theme.breakpoints.up('md')]: {
    flexGrow: 'none',
    border: 'none',
    height: '100%',

    [`&::-webkit-scrollbar`]: {
      width: '3px',
      background: 'transparent',
      height: '0px',
    },

    margin: '0.35rem',
    padding: '0.85rem',

    '@media (min-resolution: 1.49dppx)': {
      height: '96vh',
    },
  },

  [`&::-webkit-scrollbar`]: {
    width: '0px', //causes ui issues when using overlays for loading (Recommendations page, loading gif has white-space right side of container if this is > 0px)
    background: 'transparent',
    height: '0px',
  },
}));

export const InlineContainer = styled(Box)(({ theme, justifyContent, alignItems, marginTop }) => ({
  width: '100%',
  display: 'inline-flex',
  justifyContent: `${justifyContent || 'center'}`,
  alignItems: `${alignItems || 'center'}`,
  marginTop: `${marginTop || 'unset'}`,
}));

export const IconWrapper = styled(InlineContainer)(({ theme }) => ({
  width: '40px',
  height: '40px',
}));

export const StackContents = styled(Box)(({ theme, marginTop }) => ({
  width: '100%',
  display: 'inline-grid',
  justifyContent: 'space-between',
  marginTop: `${marginTop}`,
  gridTemplateColumns: '100%',
}));

export const SectionHeaderWrapper = styled(Box)(({ theme, minHeight }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  width: '100%',
  minHeight: '60px',
  margin: '10px 0',

  ' & > button': {
    fontFamily: 'Nunito',
    borderRadius: '14px',
    marginBottom: '5px',
  },
  ' & > button ? a.link-text': {
    textTransform: 'capitalize',
  },

  [theme.breakpoints.up('sm')]: {
    margin: '20px 0',
    '& > h1': {
      marginLeft: 'auto',
      marginRight: 'auto',
      justifyContent: 'center',
      height: 'fit-content',
    },
  },
  [theme.breakpoints.up('md')]: {
    display: 'block',
    margin: '0px',
    '& > h1': {
      fontSize: '1.8rem',
      width: 'max-content',
      margin: '0', //reset centering in mobile/tablet format
      marginTop: '-2em',
    },
  },
}));

export const TitleBadgeWrapper = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  margin: 'auto',
  display: 'inline-flex',
  alignItems: 'center',
  '& > span.badge': {
    display: 'inline-flex',
    alignItems: 'center',
    textTransform: 'capitalize',
    backgroundColor: 'lightgrey',
    padding: '0.3em 0.6em',
    borderRadius: '5px',
  },
}));

export const SectionTitle = styled(Typography)(({ theme, marginRight }) => ({
  marginRight: `${marginRight}`,
}));

export const CustomDataGridDesign = styled(DataGrid, {
  shouldForwardProp: (prop) => prop !== 'isMobile',
})<SupportCardProps>(({ theme, isMobile }) => ({
  minHeight: '58vh',
  border: 'none',

  '@media (min-width: 1260px) and (max-width: 1400px)': {
    height: 'unset',
    minHeight: '55vh',
  },

  '@media (min-width: 1025px) and (max-width: 1260px)': {
    height: 'unset',
    minHeight: '52vh',
  },

  '*': {
    scrollbarWidth: 'thin',
    scrollbarColor: 'var(--secondary-color) #d3d9de',
  },
  '*::-webkit-scrollbar': {
    width: 3,
    height: 6,
  },
  '*::-webkit-scrollbar-track': {
    backgroundColor: '#d3d9de',
  },
  '*::-webkit-scrollbar-thumb': {
    backgroundColor: 'var(--secondary-color)',
    borderRadius: 2.5,
  },

  '.MuiDataGrid-columnHeaderTitleContainerContent > span': {
    padding: '0px',
  },
  '.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows, .MuiInputBase-input': {
    fontFamily: 'Nunito',
    fontSize: '0.9em',
  },
  '.MuiTablePagination-actions': {
    fontSize: '0.9em',
  },
  '.MuiDataGrid-footerContainer': {
    marginBottom: isMobile ? 'none' : '2em',
  },
  '.MuiDataGrid-root': {
    minHeight: '82vh',
  },
  '.MuiDataGrid-main': {
    overflow: isMobile ? '' : 'unset',
  },
  '.MuiDataGrid-virtualScroller': {
    paddingTop: isMobile ? '0px' : '10px', //causes styling error when set to mobile, as we remove the header row for columns, for desktop its set to '60px !important'
    marginTop: isMobile ? '0px' : '60px !important',
  },
  '.MuiDataGrid-virtualScrollerContent': {
    display: 'inline-flex',
    width: isMobile ? 'inherit !important' : 'fit-content',
    justifyContent: 'center',
  },
  '.MuiDataGrid-virtualScrollerRenderZone': {
    gap: isMobile ? '20px' : '0px',
    position: 'unset !important',
  },
  '.MuiDataGrid-columnHeaderTitle': {
    fontWeight: 600,
  },
  '.MuiDataGrid-columnHeadersInner': {
    margin: isMobile ? '0' : '20px 0px',
    border: isMobile ? 'none' : '1px solid #00000024',
    borderRadius: isMobile ? '0' : '15px',
  },
  '.MuiDataGrid-row': {
    display: isMobile ? 'block' : 'inline-flex',
    borderRadius: '15px',
    border: '1px solid #00000024',
    margin: '2px 0px',
    alignItems: 'center',
    fontFamily: 'Nunito',
    fontWeight: 500,
    color: '#050505',
  },
  '.MuiDataGrid-cell': {
    border: 'none',
    display: isMobile ? 'block !important' : 'inline-flex',
    minHeight: isMobile ? 'none' : '98px !important',
  },
  '.MuiDataGrid-toolbarContainer': {
    '.MuiButton-root': {
      fontFamily: 'LexendDeca',
      fontWeight: '600',
    },
  },
}));

export const MobileViewContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  borderRadius: '25px',
  backgroundColor: 'white',
  height: '80vh',
}));

export const MobileContainerHeader = styled(Box)(({ theme }) => ({
  borderBottom: '1.5px solid #d9e1e7',
  display: 'inline-flex',
  width: '100%',
  padding: '0.75em',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const MobileContainerBody = styled(Box)(({ theme }) => ({
  width: '100%',
  borderBottom: '1.5px solid #d9e1e7',
  padding: '0.75em',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: 'fit-content',
}));

export const StatusBadge = styled(Box)<StyledProps>(({ theme, backgroundColor }) => ({
  display: 'inline-flex !important',
  minHeight: '23px !important',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: `${backgroundColor}`, //'#3d7652',
  padding: '0.3em 1em',
  color: 'white',
  fontSize: '0.65em',
  borderRadius: '5px',
  textAlign: 'center',
  textTransform: 'capitalize',

  '@media (min-resolution: 1.25dppx)': {
    height: 'fit-content',
  },

  [theme.breakpoints.down('sm')]: {
    minWidth: '55px',
  },
  [theme.breakpoints.up('sm')]: {
    minWidth: '60px',
  },
  [theme.breakpoints.up('md')]: {},
}));

export const MobileCardTitle = styled(Typography)<StyledProps>(({ theme }) => ({
  color: 'black',
  fontWeight: 900,
}));

export const FilledHoursBadge = styled(Box)<StyledProps>(({ theme }) => ({
  padding: '0.3em 1em',
  borderRadius: '5px',
  backgroundColor: '#4f5f71',
  color: 'white',
  marginRRight: '1em',
  fontSize: '0.65em',
  minWidth: '75px',
  minHeight: '23px',

  [theme.breakpoints.down('sm')]: {
    minWidth: '55px',
  },
  [theme.breakpoints.up('sm')]: {
    minWidth: '70px',
    marginLeft: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  [theme.breakpoints.up('md')]: {},
}));

export const BackDrop = styled(Box)(({ theme, display }) => ({
  width: 'fit-content',
  height: '82vh',

  display: 'inline-grid',
  justifyContent: 'center',
  alignItems: 'center',
  justifyItems: 'end',

  padding: '1em 5em',

  backgroundColor: `${theme.palette.background.paper}`,
  border: `1px solid ${theme.palette.primary.dark}`,
  borderRadius: '20px',
}));

export const BorderBox = styled(InlineContainer)(({ theme }) => ({
  width: '100%',
  height: '75vh',
  display: 'block',

  border: `2px solid ${theme.palette.primary.dark}`,
  borderRadius: '20px',

  padding: '5px 0',

  overflow: 'hidden',

  [theme.breakpoints.up('md')]: {
    maxWidth: '800px',
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: '1200px',
  },
  [`&::-webkit-scrollbar`]: {
    width: '0px',
    background: 'transparent',
    height: '0px',
  },
}));

export const BackdropOverlay = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  top: '0',
  left: '0',
  width: '100vw',
  height: '100vh',
  background: '#00000023',
  zIndex: 10,
}));

export const AnimationOverlay = styled(Box)(({ theme, height }) => ({
  width: '100%',
  height: '-webkit-fill-available',
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'center',
  zIndex: '1000',

  [theme.breakpoints.down('md')]: {
    // height: `calc(${height}px * 88.5)`,
    position: 'absolute',
    top: '-60px',
  },
}));

export const EmpSupportCard = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'hasBorder' && prop !== 'centerText',
})<SupportCardProps>(({ theme, border, hasBorder, centerText }) => ({
  width: '100%',
  border: hasBorder ? `2px solid ${theme.palette.primary.light}` : 'none',
  borderRadius: '16px',

  '& > h3': {
    textAlign: `${centerText ? 'center' : 'left'}`,
  },
  [theme.breakpoints.down('sm')]: {
    maxWidth: '68vw',
  },
  [theme.breakpoints.up('sm')]: {
    maxWidth: '350px',
  },
  [theme.breakpoints.down('md')]: {
    padding: '10px',
  },
  [theme.breakpoints.up('md')]: {
    maxWidth: '50vw',
    padding: '0.2em 0.2em 0.7em 0.7em',
    border: 'none',
    borderRadius: '0px',
    borderBottom: `2px solid ${theme.palette.primary.light}`,
    marginBottom: '10px',
  },
}));

export const LandingLoginWrapper = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  width: '90%',
  marginTop: '20px',
  justifyContent: 'space-around',
  '& > a': {
    marginLeft: 'unset',
  },

  '& > button': {
    minWidth: '150px',
  },

  [theme.breakpoints.down('md')]: {
    display: 'inline-grid',
    rowGap: '1em',
    justifyContent: 'space-around',
    maxWidth: 'unset',
    width: 'unset',

    '& > button': {
      float: 'unset',
      marginTop: 'unset',
    },
  },
  [theme.breakpoints.up('md')]: {
    margin: ' 40px',
  },
}));

export const LandingContent = styled(Box)(({ theme }) => ({
  '& > h1': {
    fontFamily: 'LexendDeca',
    fontWeight: 'bold',
    lineHeight: 1.5,
  },
  '& > p': {
    maxWidth: '525px',
    width: 'max-content',
    marginTop: '10px',
    lineHeight: '1.38',
    color: '#425466',
    fontFamily: 'Nunito',
    marginLeft: 'auto',
    marginRight: 'auto',
  },

  [theme.breakpoints.down('md')]: {
    padding: '25px',
    '& > h1': {
      textAlign: 'center',
    },

    '& > p': {
      maxWidth: '480px',
      width: '100%',
      textAlign: 'center',
      fontSize: '0.9em',
      paddingTop: '0.7em',
      paddingBottom: '0.7em',
      paddingLeft: '1.7em',
      paddingRight: '1.7em',
      marginBottom: '0',
      lineHeight: '1',
    },
  },
  [theme.breakpoints.up('md')]: {
    width: '50vw',
    minWidth: '525px',
    maxWidth: '800px',
    padding: '10px',
    lineHeight: '0',
  },
}));

export const CustomCard = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'hideBorder',
})<SupportCardProps>(({ theme, border, hideBorder, centerText }) => ({
  borderRadius: '25px',
  border: `${hideBorder ? 'none' : '2px solid var( --background)'}`,
  color: 'text.primary',
}));

export const ViewTableContainer = styled(
  Box,
  {}
)(({ theme, height }) => ({
  height: `calc(${height}px * 0.75)`,
  padding: '5px',
  overflow: 'hidden',
  [theme.breakpoints.down('sm')]: {
    '@media (min-height: 550px)': {},
    '@media (min-height: 700px)': {
      paddingBottom: '10px',
    },
    '@media (min-height: 800px)': {
      paddingBottom: '5px',
    },
  },
  [theme.breakpoints.up('sm')]: {
    height: `calc(${height}px * 0.79)`,
  },
}));

export const JobEmpToggleButton = styled(Button)(({ theme, variant }) => ({
  borderRadius: '20px',
  minWidth: '120px',
  fontSize: '0.75em',
  fontFamily: 'LexendDeca',
  fontWeight: '600',
  '@media (max-resolution: 1dppx) and (min-height: 1030px)': {
    fontSize: '1em',
  },
  ...(variant === 'contained' && {
    backgroundColor: `#0379A0 !important`,
  }),
  ...(variant === 'text' && {
    backgroundColor: `none`,
  }),

  [theme.breakpoints.down('md')]: {
    width: '50%',
  },
  [theme.breakpoints.up('md')]: {},
}));

export const IndMobileAvatar = styled(Avatar)(({ theme }) => ({
  width: '75px !important',
  height: '75px !important',

  [theme.breakpoints.down('sm')]: {},
  [theme.breakpoints.up('sm')]: {},
}));

export const JobBadge = styled(MultiLineOverFlow)(({ theme }) => ({
  height: '100% !important',
  minWidth: '50px',
  maxWidth: '40%',
  borderRadius: '8px',
  backgroundColor: '#707070',
  fontWeight: '600',
  textAlign: 'center',
  color: 'white',
  lineHeight: '1.2 !important',
  padding: '0.2em 1em !important',
  '&::after': {
    paddingRight: '0.2em !important',
  },
}));

export const PinImage = styled('img')(({ theme }) => ({
  paddingRight: '0.5em',

  [theme.breakpoints.down('md')]: {
    minHeight: '0.75em',
    width: '23px',
  },
  [theme.breakpoints.up('md')]: {
    minHeight: '1.5em',
    maxHeight: '3em',
    width: '25px',
  },
}));

export const AnimationWrapper = styled(Box)(({ theme, height }) => ({
  background: '#E4F2F6',
  width: '100%',
  zIndex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',

  [theme.breakpoints.down('md')]: {},
  [theme.breakpoints.up('md')]: {
    borderRadius: '25px',
    height: `calc(${height}px * 0.615)`,
    '@media (min-height: 900px)': {
      height: `calc(${height}px * 0.615)`,
    },
  },
}));

export const IndToggleContainer = styled(Box)(({ theme }) => ({
  height: 'fit-content',
  margin: '10px 0px',
  padding: '3px',
  background: 'var( --background)',
  borderRadius: '20px',
  boxShadow: '1px 1px 4px 2px #0000001a',
  overflow: 'hidden',

  [theme.breakpoints.down('md')]: {},
  [theme.breakpoints.up('md')]: {},
  [theme.breakpoints.up('lg')]: {
    '@media (min-resolution: 1.5dppx)': {
      margin: '16px 0',
    },
  },
}));

export const ViewContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {},

  [theme.breakpoints.up('md')]: {
    width: '100%',
    maxWidth: '1740px',
    display: 'inline-grid',
    gridTemplateRows: '120px auto',
    gridTemplateColumns: '100%',
    height: '97%',
    marginTop: '-4rem',
  },

  '@media (min-width: 1400px)': {
    height: '100%',
  },

  '@media (min-width: 1200px) and (max-width: 1400px)': {
    gridTemplateRows: '20% 60% 20%',
    rowGap: '0.25em',
  },

  [theme.breakpoints.up('xl')]: {},
}));
export const IndViewContainer = styled(ViewContainer)(({ theme }) => ({
  '@media (min-width: 1200px) and (max-width: 1400px)': {
    gap: '0px',
    gridTemplateRows: '21% 53% 10% 17%',
  },

  '@media (min-width: 1400px)': {
    height: '97%',
  },
}));

export const LandingContainer = styled(Box)(({ theme }) => ({
  padding: '30px 60px',
  border: '1px solid #0379a036',
  borderRadius: '16px',
  boxShadow: '1px 1px 2px 3px #0379a045',
  textAlign: 'center',

  [theme.breakpoints.down('sm')]: {
    height: 'unset',
    width: '90vw',
    display: 'block',
    padding: '0px',
    margin: 'unset',
  },
  [theme.breakpoints.up('sm')]: {
    width: '50vw',
    height: 'fit-content',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '2em',
    marginLeft: '5vw',
    marginRight: 'auto',
  },
}));

export const LandingPageComponentMain = styled(Box)(({ theme }) => ({
  minHeight: '700px',

  '@media (min-resolution: 1.38dppx) and (max-height: 692px)': {
    paddingLeft: '100px',
    paddingRight: '100px',
  },

  [theme.breakpoints.down('sm')]: {
    display: 'inline-grid',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    gridtemplateRows: '20vh 60vh 20vh',
  },
  [theme.breakpoints.down('md')]: {
    minHeight: '800px',
    display: 'inline-grid',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    gridTemplateRows: '20% 60% 20%',
  },
  [theme.breakpoints.up('md')]: {
    width: '70%',
    height: '100%',

    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    gridTemplateRows: '20% 60% 20%',
    marginTop: '-8%',
  },
}));

export const CustomNavDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'isCollapsed',
})<{ isCollapsed: boolean }>(({ theme, isCollapsed }) => ({
  width: `${isCollapsed ? '120px' : '18vw'}`,
  maxWidth: '280px',
  height: '100vh',
  maxHeight: '1000px',
  transition: 'width 0.30s ease',

  backgroundColor: 'var( --background)',
  alignItems: 'center',
  display: 'inline-flex',

  '@media (max-resolution: 1dppx) and (min-height: 1030px)': {
    width: '17vw',
    maxHeight: '1000px',
  },

  '& .MuiDrawer-paper': {
    backgroundColor: 'var( --background)',
    borderRight: 'none',
    overflow: 'hidden',
    position: 'inherit',
    maxWidth: '17.5vw',
  },
  [theme.breakpoints.up('md')]: {
    height: '100%',
  },

  '@media (max-width: 1080px) and (min-height: 700px)': {
    height: '92vh',
  },

  [theme.breakpoints.up('xl')]: {
    '& .MuiDrawer-paper': {
      width: 'inherit',
      maxWidth: '265px',
    },
  },
}));

export const ESPBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    textAlign: 'center',
  },
}));

export const ActivityFeedContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '80vh',

  [theme.breakpoints.down('md')]: {

  },
  [theme.breakpoints.up('md')]: {
    '@media(max-height: 700px)': {
      height: '77vh',
    },
    '@media(max-height: 600px)': {
      height: '75vh',
    },
    '@media(max-height: 550px)': {
      height: '73vh',
    },
  },
}));

export const ActivityFeedContent = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  width: 'inherit',
  marginTop: '1rem',
  overflow: 'hidden',
  height: '100%',

  [theme.breakpoints.down('md')]: {
    maxHeight: '785px',
  },
  [theme.breakpoints.up('md')]: {},
}));

export const SearchBarInput = styled(TextField)(({ theme }) => ({
  width: '100%',
  '.MuiInputBase-root': {
    padding: '6px 5px',

    borderRadius: '15px',

    fontWeight: 600,
    fontSize: '15px',
    color: theme.palette.text.secondary,
  },
  '.MuiOutlinedInput-notchedOutline': {
    border: `1px solid ${theme.palette.primary.dark}`,
  },
}));

export const WidgetCardImage = styled('img')(({ theme }) => ({
  display: 'block',

  width: 'auto',
  height: '120px',

  margin: '0 auto',
  borderRadius: '20px',

  [theme.breakpoints.up('sm')]: {
    height: '180px',
  },
  [theme.breakpoints.up('md')]: {
    width: '100%',
    maxWidth: '550px',
    height: 'auto',
  },
  [theme.breakpoints.up('lg')]: {},
}));

export const ActivityParentBox = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',

  [theme.breakpoints.down('md')]: {},
  [theme.breakpoints.up('md')]: {},
}));

export const ActivityJobCardParent = styled(Box)(({ theme }) => ({
  display: 'inline-grid',
  gridTemplateColumns: '100%',
  gridAutoRows: 'min-content',

  justifyItems: 'center',
  margin: '0 auto',
  [theme.breakpoints.down('md')]: {},
  [theme.breakpoints.up('md')]: {
    width: '28vw',
    maxWidth: '550px',
    '@media(min-width: 1300px)': {
      width: '30vw',
    },
    '@media(min-width: 1600px)': {
      width: '32vw',
    },
    '@media(min-resolution: 1.49dppx)': {
      width: '27vw',
    },
  },
}));

export const ActivityCardHolder = styled(Box)(({ theme }) => ({
  display: 'inline-grid',
  height: '100%',

  rowGap: '20px',

  overflow: 'scroll',
  padding: '20px 0',
}));

export const ActivityChildBox = styled(Box)(({ theme }) => ({
  overflow: 'scroll',
  marginTop: '10px',
  height: '75%',
  marginBottom: '35px',

  '@media(min-width: 1300px)': {
    width: '100%',
  },

  [theme.breakpoints.down('md')]: {},
  [theme.breakpoints.up('md')]: {},
}));

export const ActivityInfoContainer = styled(Box)(({ theme, }) => ({
  width: '100%',
  height: 'inherit',
  display: 'grid',
  gridTemplateRows: '20% 63% 17%',
  gridTemplateColumns: '100%',
  /* padding: '5px', */
  border: '2px solid var( --background)',
  borderStyle: 'none solid none none',
  overflow: 'hidden !important',

  [theme.breakpoints.down('md')]: {
    alignItems: 'center',
  },
  [theme.breakpoints.up('md')]: {
    width: '45%',
    height: 'inherit',
    display: 'grid',
    gridTemplateRows: '16% 68% 17%',
    gridTemplateColumns: '100%',
    border: '2px solid var( --background)',
    borderStyle: 'none solid none none',
    overflow: 'hidden !important',
    '@media(max-height: 700px)': {
      gridTemplateRows: '16% 64% 20%',
    },
    '@media(max-height: 650px)': {
      gridTemplateRows: '16% 63% 21%',
    },
    '@media(max-height: 600px)': {
      gridTemplateRows: '16% 62% 22%',
    },
    '@media(max-height: 550px)': {
      gridTemplateRows: '16% 60% 25%',
    },
    '@media(max-height: 500px)': {
      gridTemplateRows: '16% 58% 26%',
    },
  },
}));
export const ActivityListItems = styled(Box)(({ theme }) => ({
  height: '59vh',
  overflowY: 'scroll',
  overflowX: 'hidden',

  '&::-webkit-scrollbar': {
    width: '6px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '10px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    cursor: 'pointer',
    backgroundColor: theme.palette.primary.main,
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#f1f1f1',
    borderRadius: '10px',
  },
  //add breakpoints
  [theme.breakpoints.up('sm')]: {},
  [theme.breakpoints.up('md')]: {},
}));

export const Template = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {},
  [theme.breakpoints.up('md')]: {},
}));

export const LandingPageUpperBox = styled(Box)(({ theme }) => ({
  height: 'unset',
  marginTop: '-5em',
  //marginLeft: '5.5em',
  justifyContent: 'center',
  alignItems: 'center',

  [theme.breakpoints.down('sm')]: {
    marginTop: '5em',
    marginLeft: '0em',
  },
  [theme.breakpoints.up('md')]: {},
}));

export const CenteredFullBackDrop = styled(BackDrop)(({ theme, display }) => ({
  width: '96vw', // see CenterFullOverlay styled component for full styling
  height: '96vh',
}));
