import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@mui/material';
import useTranslate from '../../hooks/useTranslate';

const LogoutButton = () => {
  const { logout } = useAuth0();
  const t = useTranslate();

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: 'https://myjobmatch.ca',
      },
    });
  };

  return (
    <Button
      variant="text"
      sx={{
        fontWeight: '600',
        fontFamily: 'Nunito',
        color: '#444444',

        '&:hover': {
          background: '#0F1177',
          color: 'white',
        },
      }}
      onClick={handleLogout}
    >
      {t('logoutBtn')}
    </Button>
  );
};

export default LogoutButton;
