import React, { ChangeEvent } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { Box, InputAdornment } from '@mui/material';
import { SearchBarInput } from '../UI/StyledComponents/GlobalStyledComponents';

const QuickSearchBarComponent = (props: {
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
  searchQuery: string;
}) => {
  const { setSearchQuery, searchQuery } = props;

  return (
    <Box marginTop="10px" width="100%">
      <SearchBarInput
        id="search-bar"
        className="search-bar"
        defaultValue={searchQuery}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          setSearchQuery(e.target.value);
        }}
        variant="outlined"
        placeholder="Search individuals..."
        size="small"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon style={{ fill: 'grey' }} />
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default QuickSearchBarComponent;
