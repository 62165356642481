import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { getAccessToken } from '../../store/authSlice';
import { useState } from 'react';
import { useAppDispatch } from '../typescript/hooks';
import { getMyProviderDetails, sendProviderEmail } from '../../store/providerSlice';

interface ProviderEmailFormProps {
  open: boolean;
  handleClose: () => void;
  name: string | undefined;
  providerEmail: string | undefined;
}

export default function ProviderEmailForm({
  open,
  handleClose,
  name,
  providerEmail,
}: ProviderEmailFormProps) {
  const dispatch = useAppDispatch();
  const token = useSelector(getAccessToken);
  const provider = useSelector(getMyProviderDetails);

  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async () => {
    if (!subject || !body) {
      alert('Please fill out both the subject and body.');
      return;
    }

    setLoading(true);
    try {
      if (!providerEmail) {
        throw new Error('No email to send message to');
      }

      await dispatch(
        sendProviderEmail({
          providerEmail,
          senderEmail: provider.email,
          subject,
          body,
          token: token!,
        })
      ).unwrap();
      setSuccess(true);
    } catch (error) {
      alert('Failed to send email');
    } finally {
      setLoading(false);
    }
  };

  const handleCloseDialog = () => {
    setSuccess(false);
    setSubject('');
    setBody('');
    handleClose();
  };

  return (
    <Dialog open={open} onClose={!loading ? handleCloseDialog : undefined}>
      <Box
        display={'flex'}
        flexDirection={'row'}
        sx={{
          borderBottom: 'solid 1px #e0f7fa',
          paddingBottom: '0.75rem',
          alignItems: 'center',
          justifyContent: 'space-around',
          padding: '16px 24px',
        }}
      >
        <DialogTitle
          sx={{
            flexGrow: 1,
            padding: 'unset',
          }}
        >
          {success ? 'Email Sent' : `Send an email to ${name ? name : 'Loading...'}`}
        </DialogTitle>

        <Close onClick={handleClose} sx={{ cursor: 'pointer' }} />
      </Box>
      <DialogContent>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
            <CircularProgress /> {/* Loader */}
          </Box>
        ) : success ? (
          <Box display="flex" flexDirection="column" alignItems="center" minHeight="100px">
            <DialogContentText fontSize="1.5rem!important" mb={2} color="success.main">
              The email has been sent successfully!
            </DialogContentText>
            <Button onClick={handleCloseDialog} variant="contained">
              Close
            </Button>
          </Box>
        ) : (
          <>
            <DialogContentText fontSize="0.8rem">
              Fill in the subject and message for your email. Replies will be sent to your
              registered email address.
            </DialogContentText>
            <Box display="flex" flexDirection="column" rowGap="1.5rem" mt={3}>
              <TextField
                label="Subject"
                helperText="Provide a brief, clear subject for your email."
                fullWidth
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
              <TextField
                label="Body"
                helperText="Compose your message. This is the main content your recipient will see."
                multiline
                fullWidth
                rows={10}
                value={body}
                onChange={(e) => setBody(e.target.value)}
              />
            </Box>
          </>
        )}
      </DialogContent>

      {!success && (
        <DialogActions>
          <Grid container justifyContent="space-between" mt={2} mx={2} mb={1.5}>
            <Grid item>
              <Button onClick={handleCloseDialog} variant="outlined" disabled={loading}>
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" onClick={handleSubmit} disabled={loading}>
                Submit
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      )}
    </Dialog>
  );
}
