import { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { Individual } from '../components/typescript/types';
import { ContactInfo } from '../components/RecommendationComponents/RecommendationsComponent';
import useTranslate from '../hooks/useTranslate';
import { useSelector } from 'react-redux';
import { getAllIndividuals } from '../store/individualSlice';
import { getFullName } from '../helpers/helperFunctions';
import { getProvidersInOrganization } from '../store/providerSlice';
import {
  ActivityFeedContainer,
  ActivityFeedContent,
  BackdropOverlay,
  InlineContainer,
  SectionHeaderWrapper,
  SectionTitle,
  TitleBadgeWrapper,
} from '../components/UI/StyledComponents/GlobalStyledComponents';
import PuffLoader from '../components/UI/Animation/PuffLoader';
import useDetectResize from '../hooks/useDetectResize';

import ActivityInfoComponent from '../components/ActivityFeedComponents/ActivityInfoComponent';
import PageTitleComponent from '../components/PageTitleComponent';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ActivityFeedList from '../components/ActivityFeedComponents/ActivityFeedList';
import Modal from '../components/UI/Popup/BackdropComponent';
import { FullScreenOverlay } from '../components/UI/StyledComponents/RecommendationStyledComponent';
import { useGetIndividualByOrgIdAndIndividualIdQuery } from '../store/services/individualApi';

const ActivityFeedComponent = () => {
  let { individualId, leadProviderOrgId } = useParams();
  const { data, isLoading } = useGetIndividualByOrgIdAndIndividualIdQuery({
    orgId: leadProviderOrgId!,
    individualId: individualId!,
  });
  const { state } = useLocation();

  const allIndividuals = useSelector(getAllIndividuals);
  const allProviders = useSelector(getProvidersInOrganization);
  const { isMobile, isTablet, windowDimensions } = useDetectResize();

  const [leadProvider, setLeadProvider] = useState<ContactInfo | null>(null);

  const [selectedIndividual, setSelectedIndividual] = useState<Individual | undefined>();
  const [displayAnimation, setDisplayAnimation] = useState<boolean>(false); //TODO CHANGED from true
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [listCompiled, setListCompiled] = useState<boolean>(true); //TODO CHANGED from false

  const [activeClass, setActiveClass] = useState<string>(individualId ?? '');

  const t = useTranslate();

  const getContactInfo = (individual: Individual): ContactInfo | null => {
    const provider = allProviders.find((provider) => provider?.id === individual.leadProviderId)!;
    if (provider) {
      return {
        img: 'defaultProviderLogo.png',
        name: getFullName(provider),
        email: provider.email,
        phone: provider.phone,
        photoUrl: provider.photoUrl,
        orgId: '',
      };
    }
    return null;
  };

  // @ts-ignore
  const individual = allIndividuals.find((ind: Individual) => ind.id === data?.ind_id);

  useEffect(() => {
    if (!isLoading && leadProviderOrgId && individualId) {
      setActive(individualId);
    }
  }, [leadProviderOrgId, individualId, isLoading, allIndividuals]);

  const setActive = (id: string) => {
    const selectedInd = allIndividuals.find((ind: Individual) => ind.id === id);
    const contactInfo = getContactInfo(selectedInd!);
    setActiveClass(id);
    setIsVisible(true);
    setSelectedIndividual(selectedInd);
    setLeadProvider(contactInfo);
  };

  return (
    <>
      {isMobile || isTablet ? (
        <>
          <ActivityFeedList
            activeClass={activeClass}
            listCompiled={listCompiled}
            displayAnimation={displayAnimation}
            setActive={setActive}
            setDisplayAnimation={setDisplayAnimation}
            setIsVisible={setIsVisible}
            setActiveClass={setActiveClass}
            setLeadProvider={setLeadProvider}
          />
          {isVisible && (
            <Modal
              onHide={() => {
                setIsVisible(false);
              }}
            >
              <BackdropOverlay
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <FullScreenOverlay
                  className={`modal-overlay-wrapper`}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  top={windowDimensions.h}
                  right={windowDimensions.w}
                >
                  <ActivityInfoComponent
                    activity={state?.activity | 0}
                    isVisible={isVisible}
                    setIsVisible={setIsVisible}
                    info={selectedIndividual! || null}
                    leadProvider={leadProvider!}
                  />
                </FullScreenOverlay>
              </BackdropOverlay>
            </Modal>
          )}
        </>
      ) : (
        <>
          <PageTitleComponent greeting={t('activity-feed')} />
          <ActivityFeedContainer>
            <ActivityFeedContent className="card" height="inherit !important">
              <Box className="recomm-column1">
                <ActivityFeedList
                  activeClass={activeClass}
                  listCompiled={listCompiled}
                  displayAnimation={displayAnimation}
                  setActive={setActive}
                  setDisplayAnimation={setDisplayAnimation}
                  setIsVisible={setIsVisible}
                  setActiveClass={setActiveClass}
                  setLeadProvider={setLeadProvider}
                />
              </Box>
              <ActivityInfoComponent
                activity={state?.activity | 0}
                setIsVisible={setIsVisible}
                isVisible={isVisible}
                info={selectedIndividual! || individual || null}
                leadProvider={leadProvider!}
              />
            </ActivityFeedContent>
          </ActivityFeedContainer>
        </>
      )}
    </>
  );
};

export default ActivityFeedComponent;

export const ActivityFeedHeader = () => {
  const { isMobile, isTablet } = useDetectResize();
  const t = useTranslate();

  return (
    <SectionHeaderWrapper className="table-header">
      <InlineContainer
        width={isTablet ? '90% !important' : '100%'}
        marginLeft={isMobile ? 'none' : 'auto'}
        marginRight={isMobile ? 'none' : 'auto'}
        flexGrow={isMobile || isTablet ? 'none' : 1}
        justifyContent={'center'}
      >
        <TitleBadgeWrapper>
          <SectionTitle variant="h2" marginRight={isMobile || isTablet ? '5px' : '10px'}>
            {t('activity-feed').toUpperCase()}
          </SectionTitle>
        </TitleBadgeWrapper>
      </InlineContainer>
    </SectionHeaderWrapper>
  );
};
