import { styled } from '@mui/material/styles';
import { Box, Card, Grid, Paper, Typography } from '@mui/material';
import { StyledBoxImgProp } from '../../typescript/types';

export const ModalOverlayPositioning = styled(Box)(({ theme, top, right }) => ({
  position: 'absolute',
  height: 'fit-content',
  zIndex: 15,
}));

export const LogoutOverlay = styled(ModalOverlayPositioning)(({ theme, top, right }) => ({
  [theme.breakpoints.down('md')]: {
    top: '60px',
    right: `calc(${right}px * 0.05)`,
  },
  [theme.breakpoints.down('sm')]: {
    top: '60px',
    right: '40px',
  },
  [theme.breakpoints.up('md')]: {
    top: `calc(${top}px * 0.1)`,
    right: `calc(${right}px * 0.05)`,
  },
  [theme.breakpoints.up('lg')]: {
    ['@media(min-width: 2000px)']: {
      top: `calc(${top}px * 1.25)`,
      right: `calc(${right}px * 2.4)`,
      ['@media(min-height: 777px)']: {
        top: `calc(${top}px * 0.11)`,
        right: `calc(${right}px * 0.115)`,
      },
    },
    ['@media(min-width: 2500px)']: {
      top: `calc(${top}px * 1.5)`,
      right: `calc(${right}px * 2.8)`,
    },
  },
}));

export const NotificationOverlay = styled(ModalOverlayPositioning)(({ theme, top, right }) => ({
  [theme.breakpoints.down('md')]: {
    top: `calc(${top}px * 0.08)`,
    right: `calc(${right}px * 0.02)`,
  },
  [theme.breakpoints.up('md')]: {
    top: `calc(${top}px * 0.1)`,
    right: `calc(${right}px * 0.08)`,
  },
  [theme.breakpoints.up('lg')]: {
    ['@media(min-width: 2000px)']: {
      top: `calc(${top}px * 1.25)`,
      right: `calc(${right}px * 2.4)`,
      ['@media(min-height: 777px)']: {
        top: `calc(${top}px * 0.11)`,
        right: `calc(${right}px * 0.105)`,
      },
    },
    ['@media(min-width: 2500px)']: {
      top: `calc(${top}px * 1.5)`,
      right: `calc(${right}px * 2.8)`,
    },
  },
}));

export const NotificationCardContainer = styled(Card)(({ theme }) => ({
  padding: '10px 15px',
  width: '100%',
  borderRadius: '0%',
  border: '0px',
  borderBottom: '2px solid rgb(233, 233, 233)',
  marginLeft: 'auto',
  marginRight: 'auto',
  [theme.breakpoints.down('sm')]: {
    maxWidth: '440px',
  },
  [theme.breakpoints.down('md')]: {
    maxWidth: '550px',
  },
  [theme.breakpoints.up('md')]: {
    maxWidth: '440px',
  },
}));

export const NotificationTypography = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {},
  [theme.breakpoints.up('sm')]: {
    fontSize: '18px !important',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: 'initial !important',
  },
}));

export const ModalNotificationDropDown = styled(Box)(({ theme }) => ({
  minWidth: 'fit-content',
  width: 'fit-content',
  background: 'white',
  marginTop: '10px',
  animation: 'rotateMenu 250ms ease-in',
  borderRadius: '15px',

  '&::-webkit-scrollbar': {
    width: '3px',
    background: 'transparent',
    height: '0px',
  },

  [theme.breakpoints.up('md')]: {
    minWidth: 'fit-content',
    width: 'fit-content',
    maxWidth: '450px',
    maxHeight: '470px',
  },
}));

export const NotificationGrid = styled(Grid)(({ theme }) => ({
  padding: '5px',
  [theme.breakpoints.up('xs')]: {
    width: '96vw',
  },
  [theme.breakpoints.down('sm')]: {
    maxWidth: '450px',
  },
  [theme.breakpoints.down('md')]: {
    maxWidth: '550px',
  },
  [theme.breakpoints.up('md')]: {
    width: 'min-content',
    maxWidth: '450px',
  },
}));

export const NotificationCardStyling = styled(Box)(({ theme, width }) => ({
  width: `calc(${width}px * 0.75)`,
}));

export const NotificationContainer = styled(Box)(({ theme }) => ({
  minWidth: '365px',
  minHeight: '50px',
  width: 'inherit',
  right: '0px',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
}));

export const PopupModalContainer = styled(Box)(({ theme }) => ({
  fontFamily: 'Nunito',
  textAlign: 'center',

  backgroundColor: 'white',
  border: '1px solid var( --background)',
  borderRadius: '10px',
  boxShadow: '0 8px 40px -12px rgba(0,0,0,0.3)',
  transition: '0.3s',
  margin: 'auto',
  padding: 30,

  [theme.breakpoints.down('sm')]: {
    width: '100vw',
    maxWidth: '500px',
  },
  [theme.breakpoints.up('md')]: {
    width: '56vw',
  },

  '&:hover': {
    boxShadow: '0 16px 70px -12.125px rgba(0,0,0,0.3)',
  },
}));

export const DeleteOverlay = styled(ModalOverlayPositioning)(({ theme, top, right }) => ({
  [theme.breakpoints.down('sm')]: {
    top: `calc(${top}px * 0.315)`,
    right: `calc(${right}px * 0.02)`,
  },
  [theme.breakpoints.down('md')]: {
    top: `calc(${top}px * 0.315)`,
    right: `calc(${right}px * 0.185)`,
    '& > div > div > h4': {
      fontSize: '20px !important',
    },
  },
  [theme.breakpoints.up('md')]: {
    fontSize: 'initial !important',
    top: `calc(${top}px * 0.215)`,
    right: `calc(${right}px * 0.315)`,
  },
  [theme.breakpoints.up('lg')]: {
    ['@media(min-width: 2000px)']: {
      top: `calc(${top}px * 0.25)`,
      right: `calc(${right}px * 0.35)`,
    },
    ['@media(min-width: 2500px)']: {
      top: `calc(${top}px * 0.25)`,
      right: `calc(${right}px * 0.35)`,
    },
  },
}));

export const KeywordSubmitOverlay = styled(DeleteOverlay)(({ theme, top, right }) => ({
  [theme.breakpoints.down('sm')]: {},
  [theme.breakpoints.down('md')]: {},
  [theme.breakpoints.up('md')]: {},
  [theme.breakpoints.up('lg')]: {},
}));

export const SuccessOverlay = styled(DeleteOverlay)(({ theme, top, right }) => ({
  [theme.breakpoints.down('sm')]: {},
  [theme.breakpoints.down('md')]: {},
  [theme.breakpoints.up('md')]: {},
  [theme.breakpoints.up('lg')]: {},
}));

export const HelpOverlay = styled(ModalOverlayPositioning)(({ theme, top, right }) => ({
  position: 'relative',
  '& > div': {
    width: '100vw',
    maxWidth: '500px',
  },

  [theme.breakpoints.down('sm')]: {
    top: `calc(${top}px * -0.5)`,
    right: `calc(${right}px * -0.50)`,
    '& > div  h4': {
      fontSize: '20px !important',
    },
  },
  [theme.breakpoints.up('sm')]: {
    top: `calc(${top}px * -0.6)`,
    right: `calc(${right}px * -0.5)`,
  },
  [theme.breakpoints.up('md')]: {
    top: `calc(${top}px * -0.5)`,
    right: `calc(${right}px * -0.6)`,
  },
  [theme.breakpoints.up('lg')]: {
    top: `calc(${top}px * -0.5)`,
    right: `calc(${right}px * -0.55)`,
  },
}));

export const CenteredOverlay = styled(ModalOverlayPositioning)(({ theme, top, right }) => ({
  [theme.breakpoints.down('md')]: {
    top: `calc(${top}px * 0.1)`,
    right: `calc(${right}px * -0.75)`,
  },
  [theme.breakpoints.up('md')]: {
    top: `calc(${top}px * 0)`,
    left: `calc(${right}px * 0)`,
  },
}));

export const AltSuccessModal = styled(Box)(({ theme }) => ({
  display: 'block',
  alignItems: 'center',
  justifyContent: 'center',
  background: 'white',
  borderRadius: '25px',
  border: '2px solid #D9E1E7',

  '& > p': {
    fontFamily: 'LexendDeca',
    color: 'var(--primary-color)',
    fontWeight: '700',
  },
  '& > img': {
    [theme.breakpoints.down('md')]: {
      width: '60px',
    },
    [theme.breakpoints.up('md')]: {
      width: '120px',
    },
  },

  [theme.breakpoints.down('sm')]: {
    width: '82vw',
    height: '175px',
  },
  [theme.breakpoints.up('sm')]: {},
  [theme.breakpoints.up('md')]: {
    width: '500px',
    height: '300px',
  },
}));

export const AltSuccessModalBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  '& > p': {
    fontFamily: 'LexendDeca',
    color: 'var(--primary-color)',
    fontWeight: '700',
  },
  '& > img': {
    [theme.breakpoints.down('md')]: {
      width: '60px',
    },
    [theme.breakpoints.up('md')]: {
      width: '120px',
    },
  },

  [theme.breakpoints.down('sm')]: {
    width: '82vw',
    height: '175px',
  },
  [theme.breakpoints.up('sm')]: {},
  [theme.breakpoints.up('md')]: {
    width: '450px',
    height: '200px',
  },
}));

export const CloseAllJobsOverlay = styled(ModalOverlayPositioning)(({ theme, top, right }) => ({
  [theme.breakpoints.down('sm')]: {
    top: `calc(${top}px * 0.315)`,
    right: `calc(${right}px * 0.02)`,
  },
  [theme.breakpoints.down('md')]: {
    top: `calc(${top}px * 0.315)`,
    right: `calc(${right}px * 0.185)`,
    '& > div > div > h4': {
      fontSize: '20px !important',
    },
  },
  [theme.breakpoints.up('md')]: {
    fontSize: 'initial !important',
    top: `calc(${top}px * 0.215)`,
    right: `calc(${right}px * 0.315)`,
  },
  [theme.breakpoints.up('lg')]: {
    ['@media(min-width: 2000px)']: {
      top: `calc(${top}px * 0.25)`,
      right: `calc(${right}px * 0.35)`,
    },
    ['@media(min-width: 2500px)']: {
      top: `calc(${top}px * 0.25)`,
      right: `calc(${right}px * 0.35)`,
    },
  },
}));

export const MobileNavigationOverlay = styled(ModalOverlayPositioning)(({ theme, top, right }) => ({
  [theme.breakpoints.down('md')]: {
    top: `calc(${top}px * 0.315)`,
    right: `calc(${right}px * 0)`,
  },
  [theme.breakpoints.up('md')]: {
    top: `calc(${top}px * 0.215)`,
    right: `calc(${right}px * 0.315)`,
  },
  [theme.breakpoints.up('lg')]: {
    ['@media(min-width: 2000px)']: {
      top: `calc(${top}px * 0.25)`,
      right: `calc(${right}px * 0.35)`,
    },
    ['@media(min-width: 2500px)']: {
      top: `calc(${top}px * 0.25)`,
      right: `calc(${right}px * 0.35)`,
    },
  },
}));

export const HelpModal = styled(Box)(({ theme }) => ({
  position: 'absolute',
  background: '#ffffff',
  borderRadius: '25px',
  boxShadow: '0 10px 20px rgba(black, 0.2)',
  pointerEvents: 'none',
  transform: 'translate(-50%, -50%)',
  width: '30em',
  textAlign: 'left',
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('md')]: {
    width: '20em',
  },
}));

export const CloseJobIcon = styled('img')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    width: '120px',
  },
  [theme.breakpoints.up('md')]: {
    width: '10vw',
    maxWidth: '200px',
  },
  [theme.breakpoints.up('lg')]: {},
}));

export const ActivityIcon = styled(Box)<StyledBoxImgProp>(({ theme }) => ({
  width: '20px',
  margin: '0px 10px',
  height: 'auto',
  [theme.breakpoints.down('md')]: {},
  [theme.breakpoints.up('md')]: {},
  [theme.breakpoints.up('lg')]: {},
}));

export const CharCountBox = styled(Box)(({ theme, top, right }) => ({
  top: `calc(${top}px * 0.30)`,
  right: `calc(${right}px * 0.25)`,

  [theme.breakpoints.down('md')]: {
    ['@media(max-width: 767px)']: {},
  },
  [theme.breakpoints.up('md')]: {},
  [theme.breakpoints.up('lg')]: {
    ['@media(min-width: 1920px)']: {},
  },
}));

export const GeneralOverlay = styled(Box)(({ theme }) => ({
  transform: 'translate(-50%, -50%)',
  top: '50%',
  left: '50%',
  position: 'absolute',
  right: 'unset',
  zIndex: 15,

  '& > div': {
    width: '85vw',
    maxWidth: '500px',
  },
}));
