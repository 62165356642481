import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { InlineContainer } from './UI/StyledComponents/GlobalStyledComponents';
import { Controller, useForm } from 'react-hook-form';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

const UploadFileComponent = (props: {
  text: string;
  fileName: string;
  filePostition: number;
  controllerName: string;
  canDelete: boolean;
  control: any;
  removeFileHandler: (filePostition: number) => void;
  setEditedResumeName: (name: any) => void;
  setEditedCoverLetterName: (name: any) => void;
  setFileNames: (name: any) => void;
}) => {
  const {
    text,
    fileName,
    filePostition,
    controllerName,
    canDelete,
    control,
    removeFileHandler,
    setEditedResumeName,
    setEditedCoverLetterName,
    setFileNames,
  } = props;

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [editedFileName, setEditedFileName] = useState<string>(fileName);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showTextField, setShowTextField] = useState(false);

  useEffect(() => {
    if (selectedFile) {
      console.log('Updated selectedFile:', selectedFile?.name || 'Unknown file');
    }
  }, [selectedFile]);

  const handleFileSelection = (file: File) => {
    setSelectedFile(file);
    const fileExtension = file.name.substring(file.name.lastIndexOf('.')); // Extract file extension
    setEditedFileName(file.name.replace(fileExtension, '')); // Set initial name without extension
    setIsModalOpen(true);
  };

  const handleSaveFileName = () => {
    if (selectedFile) {
      const fileExtension = selectedFile.name.substring(selectedFile.name.lastIndexOf('.')); // Extract file extension
      const finalFileName = `${editedFileName.trim()}${fileExtension}`; // Append original extension to the new name
      const renamedFile = new File([selectedFile], finalFileName, { type: selectedFile.type });
      console.log('Edited Name is: ' + finalFileName);
      console.log('renamedFile name is: ' + renamedFile.name);
      if (text === 'Upload Resume' || text === 'Joindre un CV') {
        setEditedResumeName(renamedFile.name);
      } else if (text === 'Upload Cover Letter' || text === 'Joindre une lettre d’accompagnement') {
        setEditedCoverLetterName(renamedFile.name);
      }
      setSelectedFile(renamedFile);
      // @ts-ignore
      setFileNames((currState) => {
        let newArr = [...currState];
        newArr[filePostition] = renamedFile!.name;
        return (currState = [...newArr]);
      });
    }
    setIsModalOpen(false);
    setShowTextField(false); // Hide the text field
  };

  const handleCancel = () => {
    const fileExtension = selectedFile?.name.substring(selectedFile.name.lastIndexOf('.')) || '';
    const originalName = selectedFile?.name.replace(fileExtension, '') || fileName;
    setEditedFileName(originalName); // Reset to original name without extension
    // @ts-ignore
    setFileNames((currState) => {
      let newArr = [...currState];
      newArr[filePostition] = selectedFile!.name;
      return newArr;
    });
    setIsModalOpen(false);
    setShowTextField(false); // Hide the text field
  };

  return (
    <>
      <InlineContainer className="blue-stitch file-upload" justifyContent="flex-start">
        <Button
          variant="text"
          component="label"
          disabled={!canDelete || fileName?.startsWith('https')}
        >
          {fileName?.toLowerCase() === 'empty' ||
          fileName === '' ||
          fileName === null ||
          fileName === undefined ? (
            <div className="inline-a">
              {/*<img src={AttachmentIcon} alt="icon" />*/}
              {text}
            </div>
          ) : (
            <Tooltip title={selectedFile?.name || fileName.split('-').pop()} placement="top">
              <Typography variant="body1" className="text-overflow" sx={{ textTransform: 'none' }}>
                {selectedFile?.name || fileName.split('-').pop()}
              </Typography>
            </Tooltip>
          )}

          <Controller
            control={control}
            name={controllerName as any}
            render={({ field: { onChange, value } }) => (
              <input
                type="file"
                name={controllerName as any}
                onChange={(e) => {
                  console.log(e);
                  console.log('On Change Triggered');
                  const file = e.target.files![0];
                  console.log('File selected:', file, file?.name);
                  if (file) {
                    handleFileSelection(file); // Handle file selection with rename prompt
                    if (selectedFile) {
                      onChange(selectedFile);
                    } else {
                      onChange(file);
                    }
                  }
                  e.target.value = ''; //edge case, if user removes, then re-adds same file, onChange will not fire if you don't do this, causing a glitch in
                }}
                multiple={false}
                disabled={!canDelete}
                hidden
              />
            )}
          />
        </Button>

        <Tooltip
          title={`${selectedFile ? selectedFile.name + ' This is the Selected File' : fileName} will be deleted`}
          placement="top"
        >
          <div className="meat-balls notif-highlight">
            {canDelete && fileName?.startsWith('https://') && (
              <IconButton
                color="inherit"
                onClick={() => {
                  removeFileHandler(filePostition);
                }}
                sx={{
                  opacity: fileName !== 'empty' ? '1' : '0',
                  width: fileName !== 'empty' ? '50px' : '0px',
                  display: fileName !== 'empty' ? 'content' : 'none',
                }}
              >
                {<DeleteOutlineOutlinedIcon />}
              </IconButton>
            )}
          </div>
        </Tooltip>
      </InlineContainer>
      <Dialog
        open={isModalOpen}
        onClose={handleCancel}
        disableEnforceFocus
        disableScrollLock
        sx={{
          '& .MuiDialog-paper': {
            width: '600px',
            maxWidth: '90%',
          },
          '& .MuiDialog-container': {
            zIndex: 1300,
          },
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <DialogTitle>File Name Editor</DialogTitle>
        </Box>
        <DialogContent sx={{ display: 'flex', justifyContent: 'center', mb: '10px' }}>
          {!showTextField ? (
            <Typography>Would you like to keep the original file name or edit it?</Typography>
          ) : (
            <TextField
              label="File Name"
              value={editedFileName}
              onChange={(e) => setEditedFileName(e.target.value)}
              fullWidth
              margin="normal"
            />
          )}
        </DialogContent>
        <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
          {!showTextField ? (
            <>
              <Button onClick={handleCancel}>Keep Original</Button>
              <Button onClick={() => setShowTextField(true)} variant="contained">
                Edit Name
              </Button>
            </>
          ) : (
            <>
              <Button onClick={handleCancel}>Cancel</Button>
              <Button onClick={handleSaveFileName} variant="contained">
                Save
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UploadFileComponent;
