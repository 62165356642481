import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  ActivityListItems,
  AnimationOverlay,
  AnimationWrapper,
  InlineContainer,
} from '../UI/StyledComponents/GlobalStyledComponents';
import PuffLoader from '../UI/Animation/PuffLoader';
import ActivityCard from './ActivityCard';
import { Individual } from '../typescript/types';
import useTranslate from '../../hooks/useTranslate';
import useDetectResize from '../../hooks/useDetectResize';
import ToggleIndividualsList, { ToggleActivityList } from './ToggleIndividualsList';
import QuickSearchBarComponent from '../SharedComponents/QuickSearchBarComponent';
import PaginationComponent from './PaginationComponent';
import { useSelector } from 'react-redux';
import { getAllIndividuals, getAppliedIndividuals } from '../../store/individualSlice';
import { getMyProviderDetails } from '../../store/providerSlice';
import { ContactInfo } from '../RecommendationComponents/RecommendationsComponent';

const ActivityFeedList = (props: {
  listCompiled: boolean;
  displayAnimation: boolean;
  setActive: (str: string) => void;
  activeClass: string;
  setDisplayAnimation: React.Dispatch<React.SetStateAction<boolean>>;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setActiveClass: React.Dispatch<React.SetStateAction<string>>;
  setLeadProvider: React.Dispatch<React.SetStateAction<ContactInfo | null>>;
}) => {
  const {
    activeClass,
    listCompiled,
    displayAnimation,
    setActive,
    setDisplayAnimation,
    setIsVisible,
    setActiveClass,
    setLeadProvider,
  } = props;

  const t = useTranslate();
  const { windowDimensions, isDesktop, isLaptop } = useDetectResize();

  const allIndividuals = useSelector(getAllIndividuals);
  const appliedIndividuals = useSelector(getAppliedIndividuals);
  const myProvider = useSelector(getMyProviderDetails);

  const { isMobile, isTablet } = useDetectResize();

  const [data, setData] = useState<Individual[]>(
    allIndividuals.filter((ind: Individual) => ind.leadProviderId === myProvider!.id)
  );
  //application started filter
  const [checked, setChecked] = React.useState(true);
  //pagination
  const [page, setPage] = React.useState(0);
  const [count, setCount] = React.useState<number>(
    allIndividuals.filter((ind: Individual) => ind.leadProviderId === myProvider.id).length
  );
  const [sliceRange, setSliceRange] = useState<{ start: number; end: number }>({
    start: 0,
    end: allIndividuals.filter((ind: Individual) => ind.leadProviderId === myProvider.id)!.length,
  });
  //search query states.
  const [searchQuery, setSearchQuery] = useState('');
  const [searchBy, setSearchBy] = useState<ToggleActivityList>(ToggleActivityList.provider);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  useEffect(() => {
    if (searchBy === ToggleActivityList.provider) {
      const list = allIndividuals.filter((ind: Individual) => ind.leadProviderId === myProvider.id);
      console.info('List: ', list);
      setData((currState) => (currState = [...list]));
      setCount((currState) => (currState = list.length));
    } else {
      setData((currState) => (currState = [...allIndividuals]));
      setCount((currState) => (currState = allIndividuals.length));
    }
  }, [allIndividuals, searchBy]);

  useEffect(() => {
    // getListItems();
    setDisplayAnimation(true);
    setIsVisible(false);
    setActiveClass((currState) => {
      return (currState = '');
    });
    setLeadProvider((currState) => (currState = null));
    setPage(0);

    let timer = setTimeout(() => {
      setDisplayAnimation(false);
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchBy]);

  useEffect(() => {
    setCount(data.length);
    queryFilterSortData(searchQuery); //DOUBLE CHECK W TEAMfilter((ind:Individual) => job.isJobActive) but for individual status
  }, [page, sliceRange]);

  useEffect(() => {
    queryFilterSortData(searchQuery); //DOUBLE CHECK W TEAMfilter((ind:Individual) => job.isJobActive) but for individual status
    setCount(data.length);
  }, [searchQuery]);

  useEffect(() => {
    queryFilterSortData(searchQuery);
    setPage((currState) => (currState = 0));
  }, [checked]);

  function queryFilterSortData(query: string) {
    let results: Individual[] = [];
    if (query === '') {
      results = sortData(filterData(allIndividuals!)).slice(sliceRange.start, sliceRange.end);
      setData((currState) => (currState = [...results]));
    } else {
      const match = (str: string) => {
        return str.toLowerCase().includes(query.toLowerCase().trim());
      };
      const byFullName = (data: Individual) => {
        return match(`${data.firstName} ${data.lastName}`);
      };
      results = sortData(filterData(allIndividuals!))
        .filter((ind: Individual) => byFullName(ind))
        .slice(sliceRange.start, sliceRange.end);
      setData((currState) => (currState = [...results]));
    }
  }

  function sortData(unSortedData: Individual[]) {
    switch (searchBy) {
      case ToggleActivityList.provider:
        const myResults = unSortedData
          ?.filter((ind: Individual) => ind.leadProviderId === myProvider.id)
          .sort(sortByFullName);
        setCount(myResults.length);
        return [...myResults];
      case ToggleActivityList.organization:
        const orgResults = [...unSortedData!].sort(sortByFullName);
        setCount(orgResults.length);
        return [...orgResults];
    }
  }

  function filterData(filterData: Individual[]) {
    if (checked) {
      return filterData.filter((ind: Individual) => appliedIndividuals.includes(ind.id));
    } else {
      return filterData;
    }
  }

  const sortByFullName = (a: Individual, b: Individual) => {
    const byFullName = (data: Individual) => {
      return `${data.firstName.toLowerCase()} ${data.lastName.toLowerCase()}`;
    };

    if (byFullName(a) > byFullName(b)) {
      return -1;
    } else if (byFullName(a) < byFullName(b)) {
      return 1;
    }
    return 0;
  };

  return (
    <>
      {(isLaptop || isDesktop) && (
        <Typography variant="h4">{t('individualNav').toUpperCase() + 'S'}</Typography>
      )}
      <Box
        className="activity-parent-container"
        sx={{
          padding: isMobile || isTablet ? '0 0.5em' : 'unset',
        }}
      >
        <ToggleIndividualsList searchState={searchBy!} setQueryType={setSearchBy} />
        <QuickSearchBarComponent setSearchQuery={setSearchQuery} searchQuery={searchQuery} />
        <InlineContainer justifyContent="space-between">
          <FormControlLabel
            label={t('jobs-applicationsStarted')}
            control={
              <Checkbox
                checked={checked}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
          />
          <PaginationComponent
            totalItems={count}
            page={page}
            setPage={setPage}
            setBoundaries={setSliceRange!}
          />
        </InlineContainer>
      </Box>
      {displayAnimation && listCompiled ? (
        <AnimationOverlay>
          <AnimationWrapper height={windowDimensions.h}>
            <PuffLoader color={'#0379A0'} size={240} />
          </AnimationWrapper>
        </AnimationOverlay>
      ) : (
        <ActivityListItems className="activity-list-items">
          {data!.length === 0 ? (
            <InlineContainer>
              <Typography variant="subtitle2">{t('no-conn-id')}</Typography>
            </InlineContainer>
          ) : (
            [...data].map((individual: Individual) => {
              return (
                <Box
                  className={activeClass === individual?.id! ? 'recomm-highlight' : ''}
                  key={individual?.id!}
                  onClick={() => {
                    setActive(individual?.id!);
                  }}
                >
                  <ActivityCard key={individual?.id!} details={individual} />
                </Box>
              );
            })
          )}
        </ActivityListItems>
      )}
    </>
  );
};

export default ActivityFeedList;
