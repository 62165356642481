import './index.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Auth0Provider } from '@auth0/auth0-react';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './store/index';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import TranslationProvider from './transtlation/translationContext';
import { globalTheme } from './helpers/theme';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <TranslationProvider>
      <ThemeProvider theme={globalTheme}>
        <Auth0Provider
          domain={`${process.env.REACT_APP_AUTH0_DOMAIN!}`}
          clientId={`${process.env.REACT_APP_AUTH0_CLIENT_ID!}`}
          authorizationParams={{
            redirect_uri: `${process.env.REACT_APP_URL!}/dashboard`,
            audience: `${process.env.REACT_APP_AUTH0_AUDIENCE!}`,
          }}
          cacheLocation="localstorage"
        >
          <BrowserRouter>
            <Provider store={store}>
              <App />
            </Provider>
          </BrowserRouter>
        </Auth0Provider>
      </ThemeProvider>
    </TranslationProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
