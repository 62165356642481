import { Box, Button, Card, Typography } from '@mui/material';
import React from 'react';
import { PopupModalContainer } from '../StyledComponents/NotificationModalStyledComponents';
import { capitalize } from '../../../helpers/helperFunctions';
import useTranslate from '../../../hooks/useTranslate';

const SelectAnOptionPopupComponent = (props: {
  msg?: string;
  jsx?: JSX.Element;
  title?: string;
  confirmAction: () => void;
  goBack: () => void;
  buttonYes?: string;
  buttonNo?: string;
}) => {
  const t = useTranslate();
  return (
    <PopupModalContainer className="reusable-confirmation">
      {props.title && <Typography variant="h2">{props.title}</Typography>}
      {props.jsx && props.jsx}
      {props.msg && <Typography variant="h4">{props.msg}</Typography>}
      <br />
      <Box className="inline-around full-width">
        <Button variant="contained" onClick={props.confirmAction}>
          {props.buttonYes ? props.buttonYes.toUpperCase() : t('yes').toUpperCase()}
        </Button>
        <Button variant="outlined" onClick={props.goBack}>
          {props.buttonNo ? props.buttonNo.toUpperCase() : t('no').toUpperCase()}
        </Button>
      </Box>
    </PopupModalContainer>
  );
};

export default SelectAnOptionPopupComponent;
