import { Typography } from '@mui/material';
import useTranslate from '../../hooks/useTranslate';
import SanitizeHTML from '../SharedComponents/SanitizeHTML';
import { Job } from '../typescript/types';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { InlineContainer } from '../UI/StyledComponents/GlobalStyledComponents';

const HowToApply = (props: { job?: Job; showTitle?: boolean }) => {
  const { job, showTitle } = props;
  const t = useTranslate();
  return (
    <>
      {showTitle && (
        <InlineContainer justifyContent="flex-start">
          <EmailOutlinedIcon sx={{ marginRight: '1em' }} />
          <Typography variant="h3" color={'black'}>
            {t('howToApply').toUpperCase()}
          </Typography>
        </InlineContainer>
      )}
      <section className="listing">
        {job !== undefined && job?.notes.length > 12 ? (
          <Typography variant="body1">
            <SanitizeHTML htmlString={job!.notes} />
          </Typography>
        ) : (
          <Typography variant="body1">{t('emptyInstructions')}</Typography>
        )}
      </section>
    </>
  );
};

export default HowToApply;
