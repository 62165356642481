import { createApi } from '@reduxjs/toolkit/query/react';
import { Individual } from '../../components/typescript/types';
import { baseQuery } from './index';

export const individualApi = createApi({
  reducerPath: 'individualApi',
  baseQuery,
  endpoints: (builder) => ({
    getIndividualByOrgIdAndIndividualId: builder.query<
      Individual,
      { orgId: string; individualId: string }
    >({
      query: ({ orgId, individualId }) => `individual/${orgId}/${individualId}`,
    }),
  }),
});

export const { useGetIndividualByOrgIdAndIndividualIdQuery } = individualApi;
