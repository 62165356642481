import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { Conversation, Chat } from '../components/typescript/types';

interface ChatState {
  conversations: Conversation[];
  allChats: Chat[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: ChatState = {
  conversations: [],
  allChats: [],
  status: 'idle',
  error: null,
};

type VerifyUserResponse = { isVerified: boolean };

export const fetchConversations = createAsyncThunk(
  'chat/fetchConversations',
  async (params: { limit: number; page: number; token: string }) => {
    return axios
      .get(`${process.env.REACT_APP_SERVER_URL}/api/b2b/chat/conversations`, {
        params: {
          limit: params.limit,
          page: params.page,
        },
        headers: {
          Authorization: `Bearer ${params.token}`,
        },
      })
      .then((response) => response.data)
      .catch((err) => {
        return err.message;
      });
  }
);

export const fetchMessages = createAsyncThunk(
  'chat/fetchMessages',
  async (params: { userId: string; token: string; limit: number }) => {
    return axios
      .get(`${process.env.REACT_APP_SERVER_URL}/api/b2b/chat/user/${params.userId}`, {
        headers: {
          Authorization: `Bearer ${params.token}`,
        },
        params: {
          limit: params.limit,
        },
      })
      .then((response) => response.data)
      .catch((err) => {
        return err.message;
      });
  }
);

export const sendMessage = createAsyncThunk(
  'chat/sendMessage',
  async (
    params: { content: string; toUserId: string; fromUserId: string; token: string },
    { rejectWithValue }
  ) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/b2b/chat`,
        {
          content: params.content,
          toUserId: params.toUserId,
          fromUserId: params.fromUserId,
        },
        {
          headers: { Authorization: `Bearer ${params.token}` },
        }
      );
      return { content: params.content, toUserId: params.toUserId, fromUserId: params.fromUserId };
    } catch (err: any) {
      return rejectWithValue(err.response?.data || 'Failed to send message');
    }
  }
);

export const verifyUser = createAsyncThunk(
  'user/verifyUser',
  async (params: { userId: string; token: string }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/b2b/chat/verify/${params.userId}`,
        {
          headers: {
            Authorization: `Bearer ${params.token}`,
          },
        }
      );
      return response.data;
    } catch (err: any) {
      throw new Error(err.response?.data?.error || 'Failed to verify user');
    }
  }
);

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    resetChatState: (state) => {
      state.conversations = [];
      state.allChats = [];
      state.status = 'idle';
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    // Fetch Conversations
    builder.addCase(fetchConversations.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(
      fetchConversations.fulfilled,
      (state, action: PayloadAction<Conversation[]>) => {
        state.status = 'succeeded';
        state.conversations = action.payload;
      }
    );
    builder.addCase(fetchConversations.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || 'Failed to fetch conversations';
    });

    builder.addCase(fetchMessages.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(fetchMessages.fulfilled, (state, action: PayloadAction<Chat[]>) => {
      state.status = 'succeeded';
      state.allChats = action.payload;
    });
    builder.addCase(fetchMessages.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || 'Failed to fetch messages';
    });

    builder.addCase(sendMessage.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(sendMessage.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.allChats.push({
        id: Date.now(), // temporary
        from_user_id: action.payload.fromUserId, // temporary
        from_user_type: 'provider',
        to_user_id: action.payload.toUserId,
        to_user_type: 'user',
        content: action.payload.content,
        created_at: new Date().toISOString(), // temporary
      });
    });
    builder.addCase(sendMessage.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || 'Failed to send message';
    });
  },
});

export const { resetChatState } = chatSlice.actions;

export default chatSlice.reducer;
