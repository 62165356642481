import { useState } from 'react';

function useImgUpload() {
  const [imgUrl, setImgUrl] = useState<string>('');
  const [imgFile, setImgFile] = useState(null);

  function uploadImgFn(e: any) {
    const file: File | null = e.target?.files[0] || null;

    if (imgUrl === '' && file !== null && file!.type.includes('image')) {
      setImgUrl(URL.createObjectURL(e.target.files[0]));
      setImgFile(e.target.files[0]);
      console.log(e.target.files[0]);
    } else if (e === 'clear-img' && file === null) {
      setImgUrl('');
      setImgFile(null);
    } else if (imgUrl && file !== null && file!.type.includes('image')) {
      URL.revokeObjectURL(imgUrl); //remove previous file uploaded
      setImgUrl(URL.createObjectURL(e.target.files[0]));
      setImgFile(e.target.files[0]);
    } else {
      alert('Try again please, you can only upload one image file.');
    }
  }

  function setImgUrlOnLoad(url: string) {
    setImgUrl(url);
  }

  return {
    imgUrl,
    imgFile,
    uploadImgFn,
    setImgUrlOnLoad,
  };
}

export default useImgUpload;
