import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

interface PushNotificationState {
  subscribed: boolean | null;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: PushNotificationState = {
  subscribed: null,
  status: 'idle',
  error: null,
};

export const checkUserPushNotificationStatus = createAsyncThunk(
  'pushNotification/checkUserPushNotificationStatus',
  async (params: { token: string; provId: string; orgId: string }) => {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/notification/push-subs/check`,
      {
        headers: {
          Authorization: `Bearer ${params.token}`,
        },
        params: {
          provId: params.provId,
          orgId: params.orgId,
        },
      }
    );

    return response.data.subscribed;
  }
);

export const unsubscribeUser = createAsyncThunk(
  'pushNotification/unsubscribe',
  async (params: { token: string; provId: string; orgId: string }) => {
    await axios.delete(`${process.env.REACT_APP_SERVER_URL}/api/v1/notification/push-subs`, {
      headers: { Authorization: `Bearer ${params.token}` },
      data: { provId: params.provId, orgId: params.orgId },
    });
  }
);

const pushNotificationSlice = createSlice({
  name: 'pushNotification',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(checkUserPushNotificationStatus.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(checkUserPushNotificationStatus.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.subscribed = action.payload;
      })
      .addCase(checkUserPushNotificationStatus.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to check subscription status';
      })
      .addCase(unsubscribeUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(unsubscribeUser.fulfilled, (state) => {
        state.subscribed = false;
        state.status = 'succeeded';
      })
      .addCase(unsubscribeUser.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export default pushNotificationSlice.reducer;
