import { useState } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material';

export const CustomToolTip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'var(--secondary-color)',
    color: 'white',
  },
}));

export const QuickCopyComponent = (props: { content: string }) => {
  const [tooltipIsOpen, setTooltipIsOpen] = useState<boolean>(false);

  return (
    <CustomToolTip
      open={tooltipIsOpen!}
      onOpen={() => setTooltipIsOpen(true)}
      onClose={() => setTooltipIsOpen(false)}
      disableFocusListener
      disableHoverListener
      title="Copied!"
    >
      <ContentCopyIcon
        onClick={() => {
          setTooltipIsOpen(true);
          navigator.clipboard.writeText(`${props.content}`).then(() => {
            setTimeout(() => {
              setTooltipIsOpen(false);
            }, 750);
          });
        }}
        sx={{ cursor: 'pointer', width: '18px', marginLeft: '15px' }}
      />
    </CustomToolTip>
  );
};
