import { Typography } from '@mui/material';
import useDetectResize from '../../hooks/useDetectResize';
import {
  ToggleFilterButton,
  StickyRecoFilterContainer,
} from '../UI/StyledComponents/RecommendationStyledComponent';
import { InlineContainer } from '../UI/StyledComponents/GlobalStyledComponents';
import useTranslate from '../../hooks/useTranslate';

interface PropsFilterOptions {
  searchState: string;
  setQueryType: (query: ToggleActivityList) => void;
}

export enum ToggleActivityList {
  provider = 'provider',
  organization = 'organization',
}

const ToggleIndividualsList = (props: PropsFilterOptions) => {
  const { searchState, setQueryType } = props;
  const t = useTranslate();

  return (
    <StickyRecoFilterContainer className="sticky-recom-toggle-ind">
      <InlineContainer
        className="recomm-toggle-container"
        justifyContent="space-between"
        maxWidth="450px"
      >
        <ToggleFilterButton
          id="location-filter-tag"
          variant={searchState === ToggleActivityList.provider ? 'contained' : 'text'}
          size="small"
          onClick={() => {
            setQueryType(ToggleActivityList.provider);
          }}
        >
          <Typography
            variant="body1"
            id="location-filter-tag"
            className="text-overflow reco-filter-text"
          >
            {t('my-clients')}
          </Typography>
        </ToggleFilterButton>

        <ToggleFilterButton
          variant={searchState === ToggleActivityList.organization ? 'contained' : 'text'}
          size="small"
          onClick={() => {
            setQueryType(ToggleActivityList.organization);
          }}
        >
          <Typography
            variant="body1"
            id="aligned-filter-tag"
            className="text-overflow reco-filter-text"
          >
            {t('qt-organization')}
          </Typography>
        </ToggleFilterButton>
      </InlineContainer>
    </StickyRecoFilterContainer>
  );
};

export default ToggleIndividualsList;
