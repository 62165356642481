import Avatar from '@mui/material/Avatar';
import { stringAvatar } from '../helpers/helperFunctions';
import defaultEmployer from '../asssets/icons/emp-default.gif';
import defaultJobIcon from '../asssets/icons/job-default.gif';
import useTranslate from '../hooks/useTranslate';
import { Typography } from '@mui/material';
import {
  FilledHoursBadge,
  MultiLineOverFlow,
  StatusBadge,
} from './UI/StyledComponents/GlobalStyledComponents';

interface PropsDetailsCard {
  avatarStr: string;
  indvTitle?: string;
  employerTitle?: string;
  employerSubtitle?: string | null;
  jobTitle?: string;
  jobType?: number;
  img?: string;
  isOpenToWork?: boolean | null;
  hrsTop?: number;
  hrsBottom?: number;
}

const CompanyDetailsComponent = (props: PropsDetailsCard) => {
  const t = useTranslate();

  const {
    indvTitle,
    jobTitle,
    employerTitle,
    avatarStr,
    img,
    isOpenToWork,
    hrsTop,
    hrsBottom,
    jobType,
    employerSubtitle,
  } = props;
  console.info('Card Details Generated: ', props);
  return (
    <div className="company-details-container">
      {employerTitle && jobTitle! === undefined ? (
        <>
          <div className="organization-details-card">
            <div className="details-img-title">
              {img?.startsWith('https') ? (
                <img src={img} alt="org-img" />
              ) : img?.startsWith('employer-default.png') ? (
                <img className="default-employer-img" src={defaultEmployer} alt="org-img" />
              ) : (
                <Avatar {...stringAvatar(avatarStr)} />
              )}
              <div>
                <MultiLineOverFlow variant="h1" className="comp-detail-mn-title" lineCount={1}>
                  {employerTitle}
                </MultiLineOverFlow>
                <MultiLineOverFlow variant="h5" className="job-sub-title" lineCount={1}>
                  {employerSubtitle}
                </MultiLineOverFlow>
                <Typography
                  variant="caption"
                  className={`status-badge ${isOpenToWork ? 'status-open' : 'status-closed'}`}
                >
                  {isOpenToWork ? t('active') : t('inactive')}
                </Typography>
              </div>
            </div>
          </div>
        </>
      ) : indvTitle ? (
        <div className="indv-details-card">
          <div className="details-img-title">
            <div className="avatar-img">
              <Avatar {...stringAvatar(avatarStr!)} />
            </div>
            <div className="title-and-status">
              {/* {!organization && <img src={img} alt='individual-img'/>} */}
              <Typography
                variant="h1"
                className="comp-detail-mn-title"
                sx={{ wordBreak: 'break-all' }}
              >
                {indvTitle}
              </Typography>
              {isOpenToWork !== null && (
                <div className="inline indiv-status">
                  <FilledHoursBadge className={`hrs-bdge`}>
                    <Typography variant="caption" color="white" className="hrs-bdge">
                      {`${hrsTop}/${hrsBottom} hrs`}
                    </Typography>
                  </FilledHoursBadge>
                  <StatusBadge
                    backgroundColor={isOpenToWork ? '#3d7652' : '#eb393d'}
                    marginLeft="3px"
                  >
                    {isOpenToWork ? t('indStatusOpen') : t('indStatusNotOpen')}
                  </StatusBadge>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="job-details-card">
          <div className="details-img-title">
            {img?.startsWith('https') ? (
              <img src={img} alt="org-img" />
            ) : (
              <img className="default-employer-img" src={defaultJobIcon} alt="org-img" />
            )}
            <div>
              <Typography variant="h1" className="comp-detail-mn-title">
                {jobTitle}
              </Typography>
              <Typography variant="h5" className="job-sub-title">
                {employerTitle}
              </Typography>
              <div>
                <Typography
                  variant="caption"
                  className={`status-badge ${isOpenToWork ? 'status-open' : 'status-closed'}`}
                >
                  {isOpenToWork ? t('jobOpened') : t('jobClosed')}{' '}
                </Typography>
                <Typography variant="caption" className="job-type-badge" marginLeft="3px">
                  {jobType! === 0
                    ? t('irlText')
                    : jobType! === 1
                    ? t('remoteText')
                    : jobType! === 2
                    ? t('hybridText')
                    : t('na')}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CompanyDetailsComponent;
