import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Box, List, ListItem, Typography } from '@mui/material';

import Modal from '../../components/UI/Popup/BackdropComponent';
import useTranslate from '../../hooks/useTranslate';
import useDetectResize from '../../hooks/useDetectResize';
import { CustomNavDrawer, InlineContainer } from '../UI/StyledComponents/GlobalStyledComponents';
import { NavTitle } from '../UI/StyledComponents/NavigationStyledComponents';
import { HelpModal, HelpOverlay } from '../UI/StyledComponents/NotificationModalStyledComponents';

import Icon from '../../asssets/icons';
import miniLogo from '../../asssets/imgs/new-logo-v.svg';
import Logo from '../../asssets/imgs/new-logo-lrg.svg';
import { useSelector } from 'react-redux';
import { getAccessToken } from '../../store/authSlice';

const navIconStyling = {
  justifyContent: 'center !important',
  width: '50px !important',
  marginLeft: 'auto !important',
  marginRight: 'auto !important',
};

const NavigationComponent = (props: {
  isCollapsed: boolean;
  setIsCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const token = useSelector(getAccessToken);

  const { isCollapsed, setIsCollapsed } = props;
  const { windowDimensions } = useDetectResize();
  let activeNavBtn = 'active-nav inline';
  let navBtn = 'inactive-nav inline';
  const location = useLocation(); //use this to toggle images when user navigates to different sections of the site.
  const [showHelpModal, setShowHelpModal] = useState<boolean>(false);
  const t = useTranslate();

  const toggleNav = () => {
    setIsCollapsed((currState) => !currState);
  };

  const resource =
    process.env.REACT_APP_RESOURCE_URL ?? 'https://dev.mjm.dartssolutions.ca/agency-login';
  const resourceURL = `${resource}/${token}`;
  return (
    //Add sx object which detects width and media type.
    //
    <CustomNavDrawer
      className="nav-bar"
      variant="permanent"
      anchor="left"
      sx={{ zIndex: 0 }}
      isCollapsed={isCollapsed}
    >
      <Box
        sx={{
          height: '90px',
        }}
      >
        {isCollapsed ? (
          <Box
            className="platform-logo nav-mini-logo "
            onClick={() => {
              toggleNav();
            }}
          >
            <img className={`dash-sm-logo`} src={miniLogo} alt="small-logo" loading="lazy" />
          </Box>
        ) : (
          <Box className="platform-logo">
            <img
              className="dash-lg-logo"
              src={Logo}
              alt="main-logo"
              loading="lazy"
              onClick={() => {
                toggleNav();
              }}
            />
          </Box>
        )}
      </Box>

      <List
        className="nav-top-list"
        sx={{
          paddingTop: '1.4rem',
          flex: 0.98,
          '@media (max-width: 1080px) and (min-height: 800px)': {
            paddingTop: '0.5em',
          },
          '@media (max-resolution: 1dppx) and (min-height: 1030px)': {
            paddingTop: '1.9rem',
          },
        }}
      >
        <ListItem sx={isCollapsed ? navIconStyling : {}}>
          <NavLink
            to="/dashboard"
            className={(props: { isActive: boolean }) => (props.isActive ? activeNavBtn : navBtn)}
          >
            <InlineContainer
              justifyContent="flex-start"
              className={`${!isCollapsed && 'nav-txt-wrpr'}`}
            >
              <img
                className="nav-icon"
                src={location.pathname === '/dashboard' ? Icon.WhiteDboard : Icon.WhiteDboard}
                alt="dash"
                loading="lazy"
              />
              {!isCollapsed && (
                <NavTitle variant="h3" className={!isCollapsed && 'animate-rotate-y'}>
                  {t('dashboardNav')}
                </NavTitle>
              )}
            </InlineContainer>
          </NavLink>
        </ListItem>

        <ListItem sx={isCollapsed ? navIconStyling : {}}>
          <NavLink
            to="/employers"
            className={(props: { isActive: boolean }) => (props.isActive ? activeNavBtn : navBtn)}
          >
            <InlineContainer
              justifyContent="flex-start"
              className={`${!isCollapsed && 'nav-txt-wrpr'}`}
            >
              <img
                className="nav-icon"
                src={
                  location.pathname.startsWith('/employers') ||
                  location.pathname.startsWith('/Employers')
                    ? Icon.WhiteEicon
                    : Icon.WhiteEicon
                }
                alt="employers"
                loading="lazy"
              />
              {!isCollapsed && (
                <NavTitle variant="h3" className={!isCollapsed && 'animate-rotate-y'}>
                  {t('employerNav')}
                </NavTitle>
              )}
            </InlineContainer>
          </NavLink>
        </ListItem>

        <ListItem sx={isCollapsed ? navIconStyling : {}}>
          <NavLink
            to="/jobs"
            className={(props: { isActive: boolean }) => (props.isActive ? activeNavBtn : navBtn)}
          >
            <InlineContainer
              justifyContent="flex-start"
              className={`${!isCollapsed && 'nav-txt-wrpr'}`}
            >
              <img
                className="nav-icon"
                src={
                  location.pathname.startsWith('/jobs') || location.pathname.startsWith('/Jobs')
                    ? Icon.WhiteJicon
                    : Icon.WhiteJicon
                }
                alt="jobs"
                loading="lazy"
              />
              {!isCollapsed && (
                <NavTitle variant="h3" className={!isCollapsed && 'animate-rotate-y'}>
                  {t('jobNav')}
                </NavTitle>
              )}
            </InlineContainer>
          </NavLink>
        </ListItem>

        <ListItem sx={isCollapsed ? navIconStyling : {}}>
          <NavLink
            to="/individuals"
            className={(props: { isActive: boolean }) => (props.isActive ? activeNavBtn : navBtn)}
          >
            <InlineContainer
              justifyContent="flex-start"
              className={`${!isCollapsed && 'nav-txt-wrpr'}`}
            >
              <img
                className="nav-icon"
                src={
                  location.pathname.startsWith('/individuals') ||
                  location.pathname.startsWith('/Individuals')
                    ? Icon.WhiteIicon
                    : Icon.WhiteIicon
                }
                alt="individuals"
                loading="lazy"
              />
              {!isCollapsed && (
                <NavTitle variant="h3" className={!isCollapsed && 'animate-rotate-y'}>
                  {t('individualNav') + 's'}
                </NavTitle>
              )}
            </InlineContainer>
          </NavLink>
        </ListItem>

        <ListItem sx={isCollapsed ? navIconStyling : {}}>
          <NavLink
            to="/activity"
            className={(props: { isActive: boolean }) => (props.isActive ? activeNavBtn : navBtn)}
          >
            <InlineContainer
              justifyContent="flex-start"
              className={`${!isCollapsed && 'nav-txt-wrpr'}`}
            >
              <img
                className="nav-icon"
                src={
                  location.pathname.startsWith('/activity') ||
                  location.pathname.startsWith('/activity')
                    ? Icon.ActivityFeedIcon
                    : Icon.ActivityFeedIcon
                }
                alt="activity"
                loading="lazy"
              />
              {!isCollapsed && (
                <NavTitle variant="h3" className={!isCollapsed && 'animate-rotate-y'}>
                  {t('activity-feed')}
                </NavTitle>
              )}
            </InlineContainer>
          </NavLink>
        </ListItem>

        <ListItem sx={isCollapsed ? navIconStyling : {}}>
          <NavLink
            to="/insights"
            className={(props: { isActive: boolean }) => (props.isActive ? activeNavBtn : navBtn)}
          >
            <InlineContainer
              justifyContent="flex-start"
              className={`${!isCollapsed && 'nav-txt-wrpr'}`}
            >
              <img
                className="nav-icon"
                src={
                  location.pathname.startsWith('/insights') ||
                  location.pathname.startsWith('/Insights')
                    ? Icon.InsightIcon
                    : Icon.InsightIcon
                }
                alt="insight"
                loading="lazy"
              />
              {!isCollapsed && (
                <NavTitle variant="h3" className={!isCollapsed && 'animate-rotate-y'}>
                  {t('reportsTitle')}
                </NavTitle>
              )}
            </InlineContainer>
          </NavLink>
        </ListItem>
      </List>

      <List sx={{ paddingTop: '1.4em' }}>
        <ListItem sx={isCollapsed ? navIconStyling : {}}>
          <a target="_blank" className={navBtn} rel="noopener noreferrer" href={resourceURL}>
            <InlineContainer
              justifyContent="flex-start"
              className={`${!isCollapsed && 'nav-txt-wrpr'}`}
            >
              <img className="nav-icon" src={Icon.SlateSettings} alt="dash" />
              {!isCollapsed && (
                <NavTitle variant="h3" className={!isCollapsed && 'animate-rotate-y'}>
                  Resource Center
                </NavTitle>
              )}
            </InlineContainer>
          </a>
        </ListItem>

        <ListItem sx={isCollapsed ? navIconStyling : {}}>
          <Box
            className="inactive-nav"
            onClick={() => {
              setShowHelpModal(true);
            }}
          >
            <InlineContainer
              justifyContent="flex-start"
              className={`${!isCollapsed && 'nav-txt-wrpr'}`}
            >
              <img
                className="nav-icon"
                src={location.pathname === '/nothing-here-yet' ? Icon.WhiteHelp : Icon.SlateHelp}
                alt="dash"
              />
              {!isCollapsed && (
                <NavTitle variant="h3" className={!isCollapsed && 'animate-rotate-y'}>
                  {t('helpBtn')}
                </NavTitle>
              )}
            </InlineContainer>
          </Box>
        </ListItem>
      </List>
      {showHelpModal && (
        <Modal className="modal" onHide={() => [setShowHelpModal(false)]}>
          <HelpOverlay
            className="responsive-help-overlay"
            onClick={(e) => {
              e.stopPropagation();
            }}
            top={windowDimensions.h}
            right={windowDimensions.w}
          >
            <HelpGuideComponent t={t} />
          </HelpOverlay>
        </Modal>
      )}
    </CustomNavDrawer>
  );
};

export default NavigationComponent;

export const HelpGuideComponent = (params: { t: (str: string) => any }) => {
  const { t } = params;
  return (
    <HelpModal>
      <Box className="help-modal-header">
        <Box className="help-modal-title">
          <Typography variant="h3" textAlign={'center'}>
            {t('helpPrompt')}
          </Typography>
        </Box>
      </Box>
      <Box className="help-modal-content">
        <a href="https://airtable.com/shrqKk1pjydUYFTgR" target="_blank" rel="noopener noreferrer">
          {t('helpReportOption')}
        </a>
        <br />
        <br />
        <a href="mailto: support@myjobmatch.ca" target="_blank" rel="noopener noreferrer">
          {t('helpEmailOption')}
        </a>
        <br />
        <br />
        <Box className="help-modal-content help-download inline-ca">
          <a href="https://guide.myjobmatch.ca/" target="_blank" rel="noopener noreferrer">
            {t('helpGuideOption')} {t('here')}{' '}
          </a>
        </Box>
      </Box>
    </HelpModal>
  );
};
