import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { Notification } from '../components/typescript/types';

export interface NotificationSlice {
  notifications: Notification[];
  isConnected: boolean;
  containsUnreadMessages: boolean;
  isEstablishingConnection: boolean;
  status: string;
  error: string | null;
}

interface NotificationQuery {
  notification: Notification;
  myProviderId: string;
}

const initialState: NotificationSlice = {
  notifications: [],
  containsUnreadMessages: false,
  isConnected: false,
  isEstablishingConnection: false,
  status: 'idle',
  error: null,
};

export const fetchNotifications = createAsyncThunk(
  'notification/fetchNotifications',
  async (params: { providerId: string; token: string }) => {
    return axios
      .get(`${process.env.REACT_APP_SERVER_URL}/api/v1/notification/${params.providerId}`, {
        headers: {
          Authorization: `Bearer ${params.token}`,
        },
      })
      .then((response) => {
        console.info('Notifications Payload: ', response.data.data.notifications);
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
);

export const thunkMarkNotificationRead = createAsyncThunk(
  'notifications/updateAsRead',
  async (query: NotificationQuery) => {
    return axios({
      method: 'put',
      url: `${process.env.REACT_APP_SERVER_URL}/api/v1/notification/update-read`,
      data: query,
    })
      .then((response) => {
        return response.status;
      })
      .catch((err) => {
        return err.message;
      });
  }
);

export const thunkMarkNotificationHide = createAsyncThunk(
  'notifications/updateAsHidden',
  async (query: NotificationQuery) => {
    return axios({
      method: 'put',
      url: `${process.env.REACT_APP_SERVER_URL}/api/v1/notification/update-hidden`,
      data: query,
    })
      .then((response) => {
        return response.status;
      })
      .catch((err) => {
        return err.message;
      });
  }
);

export const thunkMarkNotificationsAllRead = createAsyncThunk(
  'notifications/allRead',
  async (myProvId: string) => {
    return axios({
      method: 'put',
      url: `${process.env.REACT_APP_SERVER_URL}/api/v1/notification/update-all-read`,
      data: { myProviderId: myProvId },
    })
      .then((response) => {
        return response.status;
      })
      .catch((err) => {
        return err.message;
      });
  }
);

export const thunkMarkNotificationsHideAll = createAsyncThunk(
  'notifications/allHidden',
  async (myProvId: string) => {
    return axios({
      method: 'put',
      url: `${process.env.REACT_APP_SERVER_URL}/api/v1/notification/update-all-hidden`,
      data: { myProviderId: myProvId },
    })
      .then((response) => {
        return response.status;
      })
      .catch((err) => {
        return err.message;
      });
  }
);

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    startConnecting: (state) => {
      state.isEstablishingConnection = true;
    },
    connectionEstablished: (state) => {
      state.isConnected = true;
      state.isEstablishingConnection = true;
    },
    markAllNotificationsRead: (state: NotificationSlice, action) => {
      const updatedNotifications = state.notifications.map((notification: Notification) => {
        //mark all notifications as read
        return {
          ...notification,
          isRead: true,
        };
      });
      return {
        ...state,
        notifications: [...updatedNotifications],
        containsUnreadMessages: false,
      };
    },
    markAllNotificationsHidden: (state: NotificationSlice, action) => {
      console.log('Mark all as hidden!', action);
      const updatedNotifications = state.notifications.map((notification: Notification) => {
        return {
          ...notification,
          isRead:
            // notification.notificationType === 'INDIVIDUAL_OPEN' ||
            notification.notificationType === 'JOB_CLOSE' ||
            notification.notificationType === 'INDIVIDUAL_MATCH'
              ? notification.isRead
              : true,
          isVisible:
            // notification.notificationType === 'INDIVIDUAL_OPEN' ||
            notification.notificationType === 'JOB_CLOSE' ||
            notification.notificationType === 'INDIVIDUAL_MATCH'
              ? notification.isVisible
              : false,
        };
      });
      return {
        ...state,
        notifications: [...updatedNotifications],
        containsUnreadMessages: false,
      };
    },
    singleNotificationRead: (
      state: NotificationSlice,
      action: PayloadAction<{ ts: number; path: string; type: string }>
    ) => {
      const notificationIndex = state.notifications.findIndex(
        (notif) =>
          notif.createdAt === action.payload.ts &&
          notif.objectPath === action.payload.path &&
          notif.notificationType === action.payload.type
      );
      state.notifications[notificationIndex].isRead = true;
      state.containsUnreadMessages = state.notifications.some(
        (notification) => !notification.isRead
      );
    },
    singleNotificationHidden: (
      state: NotificationSlice,
      action: PayloadAction<{ ts: number; path: string; type: string }>
    ) => {
      let notificationIndex = null;
      if (action.payload.type !== 'INDIVIDUAL_MATCH') {
        notificationIndex = state.notifications.findIndex(
          (notif) =>
            notif.createdAt === action.payload.ts &&
            notif.objectPath === action.payload.path &&
            notif.notificationType === action.payload.type
        );
      } else {
        notificationIndex = state.notifications.findIndex(
          (notif) =>
            notif.objectPath === action.payload.path &&
            notif.notificationType === action.payload.type
        );
      }
      state.notifications[notificationIndex].isVisible = false;
      state.notifications[notificationIndex].isRead = true;
      const containsUnreadMessages = state.notifications.some(
        (notification) => notification.isVisible
      );
      state.containsUnreadMessages = containsUnreadMessages;
    },
    receiveMessage: (state, action: PayloadAction<{ data: Notification }>) => {
      let event = action.payload.data;
      let index = null;
      if (event.notificationType !== 'INDIVIDUAL_MATCH') {
        index = state.notifications.findIndex(
          (notif: Notification) =>
            notif.objectPath === event.objectPath &&
            notif.notificationType === event.notificationType
        ); // used to over-write if someone's changing same record multiple times.
      } else {
        // DUPLICATE INDIVIDUALS
        index = state.notifications.findIndex(
          (notif: Notification) =>
            notif.objectPath === event.objectPath &&
            notif.notificationType === event.notificationType
        );
      }

      if (index === -1) {
        state.notifications = [action.payload.data, ...state.notifications];
      } else {
        state.notifications[index] = event;
        state.notifications = [...state.notifications];
      }
      state.containsUnreadMessages = true;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchNotifications.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchNotifications.fulfilled, (state, action) => {
        state.status = 'success';

        state.notifications = action.payload.data.notifications
          .map((notification: Notification) => {
            return notification;
          })
          .sort((a: Notification, b: Notification) => b.createdAt - a.createdAt);

        state.containsUnreadMessages = state.notifications.some(
          (notification) => !notification.isRead
        );
      });
  },
});

export const {
  startConnecting,
  markAllNotificationsRead,
  singleNotificationRead,
  singleNotificationHidden,
  markAllNotificationsHidden,
  receiveMessage,
} = notificationSlice.actions;
export const notificationActions = notificationSlice.actions;
export const allNotifications = (state: { notification: NotificationSlice }) =>
  state.notification.notifications;
export const notificationListenerConnected = (state: { notification: NotificationSlice }) =>
  state.notification.isConnected;
export const hasUnreadNotifications = (state: { notification: NotificationSlice }) =>
  state.notification.containsUnreadMessages;
export default notificationSlice.reducer;
