import { Box, Button, Typography } from '@mui/material';
import React, { ReactNode, useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import IndividualComponent from '../components/IndividualComponents/IndividualComponent';
import IndividualCreateComponent from '../components/IndividualComponents/IndividualCreateComponent';
import IndividualsTable from '../components/IndividualComponents/IndividualsTableComponent';
import RecommendationsComponent from '../components/RecommendationComponents/RecommendationsComponent';
import AddIcon from '@mui/icons-material/Add';
import Icon from '../asssets/icons';
import NavigationPlaceholderComponent from '../components/NavigationComponent/NavigationPlaceholderComponent';
import { addItemBtnStyling } from '../helpers/styling';
import PageTitleComponent from '../components/PageTitleComponent';
import useTranslate from '../hooks/useTranslate';
import useDetectResize from '../hooks/useDetectResize';
import IndividualMobileTable from '../components/IndividualComponents/MobileTablet/IndividualMobileTable';
import {
  InlineContainer,
  SectionHeaderWrapper,
  SectionTitle,
  TitleBadgeWrapper,
} from '../components/UI/StyledComponents/GlobalStyledComponents';
import MobileIndividualViewComponent from '../components/IndividualComponents/MobileTablet/MobileIndividualViewComponent';
import MobileRecommendationsComponent from '../components/RecommendationComponents/MobileTablet/MobileRecommendationsComponent';

type ErrorBoundaryProps = {
  children: ReactNode;
};

type ErrorBoundaryState = {
  hasError: boolean;
};

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state: ErrorBoundaryState = { hasError: false };

  static getDerivedStateFromError(error: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    console.error('Error caught in boundary:', error, info);
  }

  render() {
    if (this.state.hasError) {
      return <div>Something went wrong. Please try again.</div>;
    }
    return this.props.children;
  }
}

const IndividualsComponent = (props: { isNavCollapsed: boolean }) => {
  const navigate = useNavigate();
  const t = useTranslate();
  const { isNavCollapsed } = props;
  const { windowDimensions, isMobile, isTablet, isLaptop, isDesktop } = useDetectResize();

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <React.Suspense fallback={<NavigationPlaceholderComponent />}>
              {isDesktop || isLaptop ? (
                <>
                  <PageTitleComponent greeting={t('individualNav') + 's'} />

                  <div className="employers-table-cont">
                    <SectionHeaderWrapper className="section-header">
                      <InlineContainer
                        width={isTablet ? '90% !important' : '100%'}
                        marginLeft={isMobile ? 'none' : 'auto'}
                        marginRight={isMobile ? 'none' : 'auto'}
                        flexGrow={isMobile || isTablet ? 'none' : 1}
                      >
                        <TitleBadgeWrapper>
                          <SectionTitle variant="h2" className="table-text">
                            {t('individualList').toUpperCase()}&nbsp;
                          </SectionTitle>

                          <Typography
                            className="badge"
                            variant="caption"
                            fontWeight={'bold'}
                            color="#050505"
                            style={{ minHeight: '20px' }}
                          >
                            <img src={Icon.LockIcon} alt="Public" style={{ paddingRight: '5px' }} />
                            {t('privateBtn')}
                          </Typography>
                        </TitleBadgeWrapper>
                        <Button
                          variant="contained"
                          startIcon={<AddIcon />}
                          onClick={() => {
                            navigate('create');
                          }}
                          sx={addItemBtnStyling}
                        >
                          <Typography variant="body1" color="white" className="link-text">
                            {t('addIndividualBtn')}
                          </Typography>
                        </Button>
                      </InlineContainer>
                    </SectionHeaderWrapper>
                    <IndividualsTable />
                  </div>
                </>
              ) : (
                <IndividualMobileTable />
              )}
            </React.Suspense>
          }
        />
        <Route
          path="create" //individuals/create
          element={
            <React.Suspense fallback={<NavigationPlaceholderComponent />}>
              {
                <ErrorBoundary>
                  <IndividualCreateComponent isEdit={false} isNavCollapsed={isNavCollapsed} />
                </ErrorBoundary>
              }
            </React.Suspense>
          }
        />
        <Route
          path=":leadProviderOrgId/:individualId" //individuals/providerOrgID/indvidiualId
          element={
            <React.Suspense fallback={<NavigationPlaceholderComponent />}>
              {isDesktop || isLaptop ? (
                <IndividualComponent />
              ) : isMobile || isTablet ? (
                <MobileIndividualViewComponent />
              ) : (
                <p>Condition not met -_-</p>
              )}
            </React.Suspense>
          }
        />
        <Route
          path=":leadProviderOrgId/:individualId/edit" //providerOrgID/indvidiualId
          element={
            <React.Suspense fallback={<NavigationPlaceholderComponent />}>
              <ErrorBoundary>
                <IndividualCreateComponent isEdit isNavCollapsed={isNavCollapsed} />
              </ErrorBoundary>
            </React.Suspense>
          }
        />
        <Route
          path=":leadProviderOrgId/:individualId/recommendations/:searchState/:userType"
          element={
            <React.Suspense fallback={<>Loading...</>}>
              {isDesktop || isLaptop ? (
                <RecommendationsComponent />
              ) : isMobile || isTablet ? (
                <MobileRecommendationsComponent />
              ) : (
                <p>Condition not met -_-</p>
              )}
            </React.Suspense>
          }
        />
      </Routes>
    </>
  );
};

export default IndividualsComponent;

export const IndividualsHeaderContent = () => {
  const navigate = useNavigate();
  const { isMobile, isTablet } = useDetectResize();
  const t = useTranslate();

  return (
    <SectionHeaderWrapper className="table-header">
      <InlineContainer
        width={isTablet ? '90% !important' : '100%'}
        marginLeft={isMobile ? 'none' : 'auto'}
        marginRight={isMobile ? 'none' : 'auto'}
        flexGrow={isMobile || isTablet ? 'none' : 1}
      >
        <TitleBadgeWrapper>
          <SectionTitle variant="h2" marginRight={isMobile || isTablet ? '5px' : '10px'}>
            {t('individualList').toUpperCase()}
          </SectionTitle>
          <Typography
            className="badge"
            variant="caption"
            color="text.secondary"
            style={{ minHeight: '20px' }}
          >
            <img src={Icon.LockIcon} alt="private" style={{ paddingRight: '5px' }} />
            {isMobile ? null : t('privateBtn')}
          </Typography>
        </TitleBadgeWrapper>

        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => {
            navigate('create');
          }}
          sx={addItemBtnStyling}
        >
          {isMobile || isTablet ? 'Add' : t('addIndividualBtn')}
        </Button>
      </InlineContainer>
    </SectionHeaderWrapper>
  );
};
