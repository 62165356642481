import { GetTokenSilentlyOptions } from '@auth0/auth0-react';
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';

let getAccessTokenSilently: (options?: GetTokenSilentlyOptions | undefined) => Promise<string>;

export const sec = {
  getAccessTokenSilently: () => getAccessTokenSilently,
  setAccessTokenSilently: (
    func: (options?: GetTokenSilentlyOptions | undefined) => Promise<string>
  ) => (getAccessTokenSilently = func),
};

export const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_SERVER_URL}/api/v1/`,
  prepareHeaders: async (headers) => {
    const access_token = await sec.getAccessTokenSilently()();
    if (access_token) {
      headers.set('Authorization', `Bearer ${access_token}`);
    }
    return headers;
  },
});
