import React from 'react';
import { Button } from '@mui/material';

export default function TranslationComponent() {
  const [selectedIndex, setSelectedIndex] = React.useState<number>(() => {
    // getting stored value
    const preferredLang = localStorage.getItem('language');
    return preferredLang === 'fr' ? 1 : 0;
  });

  const handleLanguageToggle = () => {
    let newResult = selectedIndex === 0 ? 1 : 0;
    setSelectedIndex((currState) => (currState = newResult));
    localStorage.setItem('language', newResult === 0 ? 'en' : 'fr');
    window.location.reload();
  };

  return (
    <Button
      variant="outlined"
      onClick={handleLanguageToggle}
      sx={{
        marginRight: '5px',
        border: '1px solid var(--secondary-color)',
        color: 'var(--secondary-color)',
        '&:hover': {
          border: '1px solid var(--secondary-color)',
        },
        fontFamily: 'Nunito',
      }}
    >
      {selectedIndex === 0 ? 'Français' : 'English'}
    </Button>
  );
}
