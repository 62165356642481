import { Send } from '@mui/icons-material';
import { Box, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import useDetectResize from '../../hooks/useDetectResize';
import { useAppDispatch, useAppSelector } from '../typescript/hooks';
import { getAccessToken } from '../../store/authSlice';
import { useEffect, useRef, useState } from 'react';
import { fetchMessages, resetChatState, sendMessage } from '../../store/chatSlice';
import { Message } from './MessagesComponent';
import { Chat } from '../typescript/types';
import { getMyProviderDetails } from '../../store/providerSlice';
import { useSelector } from 'react-redux';

interface ChatBoxComponentProps {
  individualId: string;
  individualName: string;
}
const ChatBoxComponent = ({ individualId, individualName }: ChatBoxComponentProps) => {
  const { isMobileOrTablet } = useDetectResize();
  const token = useAppSelector(getAccessToken);
  const myProviderDetails = useSelector(getMyProviderDetails);
  const [inputValue, setInputValue] = useState('');
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();

  const messages = useAppSelector((state) => state.chat.allChats);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (!individualId || !token) return;

    dispatch(resetChatState());

    dispatch(fetchMessages({ userId: individualId, token: token!, limit: 100 }));
  }, [dispatch, token, individualId]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSendMessage = async () => {
    if (inputValue.trim() === '') return;

    try {
      const toUserId = individualId;

      await dispatch(
        sendMessage({
          content: inputValue,
          toUserId,
          fromUserId: myProviderDetails.id,
          token: token!,
        })
      ).unwrap();

      setInputValue('');
    } catch (err: any) {
      console.error('Failed to send message:', err);
    }
  };

  return (
    <Box display={'flex'} flexDirection={'column'} height={'100%'}>
      <Box
        flex={1}
        paddingX={isMobileOrTablet ? 'unset' : '1rem'}
        paddingTop={'0.4rem'}
        paddingBottom={'1rem'}
        display={'flex'}
        flexDirection={'column'}
        rowGap={'1.5rem'}
        overflow={'auto'}
        height={'100%'}
      >
        {messages && messages.length > 0 ? (
          <>
            {messages.map((message: Chat) => (
              <Message message={message} key={message.id} individualName={individualName} />
            ))}
            <div ref={messagesEndRef} />
          </>
        ) : (
          <Typography variant="h3" textAlign={'center'}>
            No messages to view
          </Typography>
        )}
      </Box>

      <Box>
        <TextField
          placeholder="Type a message"
          multiline
          fullWidth
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              handleSendMessage();
            }
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleSendMessage}>
                  <Send />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </Box>
  );
};

export default ChatBoxComponent;
