import { useSelector } from 'react-redux';
import { getAccessToken } from '../../store/authSlice';
import { useAppDispatch, useAppSelector } from '../typescript/hooks';
import React, { useEffect } from 'react';
import {
  checkUserPushNotificationStatus,
  unsubscribeUser,
} from '../../store/pushNotificationSlice';
import { Button } from '@mui/material';
import { AskForNotificationPermission } from '../../helpers/service-worker-helpers';
import { getMyProviderDetails } from '../../store/providerSlice';

function PushNotificationView() {
  const token = useSelector(getAccessToken);
  const dispatch = useAppDispatch();
  const myProviderDetails = useSelector(getMyProviderDetails);

  const { subscribed } = useAppSelector((state) => state.pushNotification);

  useEffect(() => {
    if (token && myProviderDetails?.id && myProviderDetails?.orgId) {
      dispatch(
        checkUserPushNotificationStatus({
          token: token!,
          provId: myProviderDetails.id,
          orgId: myProviderDetails.orgId,
        })
      );
    }
  }, [token, myProviderDetails?.id, myProviderDetails?.orgId]);

  function unsubscribeFromNotifications() {
    if (!('serviceWorker' in navigator)) {
      return;
    }

    navigator.serviceWorker.ready
      .then((swRegister: ServiceWorkerRegistration) => swRegister.pushManager.getSubscription())
      .then((sub: PushSubscription | null) => {
        if (sub) {
          return sub.unsubscribe();
        } else {
          console.log('No subscription found.');
        }
      })
      .then((wasUnsubscribed) => {
        if (wasUnsubscribed) {
          console.log('Successfully unsubscribed from notifications.');
          return dispatch(
            unsubscribeUser({
              token: token as string,
              provId: myProviderDetails.id,
              orgId: myProviderDetails?.orgId,
            })
          );
        }
      })
      .catch((err) => {
        console.log('Error during unsubscription:', err);
      });
  }
  if ('Notification' in window && 'serviceWorker' in navigator && !subscribed) {
    return (
      <Button variant="contained" onClick={AskForNotificationPermission}>
        Enable Notifications
      </Button>
    );
  }

  return (
    <Button variant="contained" onClick={unsubscribeFromNotifications}>
      Disable Notifications
    </Button>
  );
}

export default PushNotificationView;
