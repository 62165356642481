import { Button, SxProps, Theme } from '@mui/material';
import {
  DataGrid,
  GridCallbackDetails,
  GridColDef,
  GridLocaleText,
  GridRowParams,
  GridToolbar,
  GridToolbarQuickFilter,
  MuiEvent,
} from '@mui/x-data-grid';
import React from 'react';
import { Employer, Individual, Job } from '../../typescript/types';
import { CustomDataGridDesign } from '../../UI/StyledComponents/GlobalStyledComponents';

const ReusableTableComponent = (
  tableData: Individual[] | Job[] | Employer[],
  columns: GridColDef<any, any, any>[],
  pageSize: number,
  setPageSize: React.Dispatch<React.SetStateAction<number>>,
  displayData: (
    params: GridRowParams,
    event: MuiEvent<React.MouseEvent>,
    details: GridCallbackDetails
  ) => void,
  gridLocaleSettings: Partial<GridLocaleText>,
  t: (key: string) => any,
  specificStyling?: SxProps<Theme>,
  hideHeader?: boolean,
  mobileToolbarComponent?: () => JSX.Element,
  isMobile?: boolean
) => {
  return (
    <CustomDataGridDesign
      getRowId={(row) => row.id}
      rows={tableData}
      columns={columns}
      pageSize={pageSize}
      rowsPerPageOptions={[5, 10, 15, 20]}
      onPageSizeChange={(newPage) => setPageSize(newPage)}
      onRowClick={displayData}
      pagination
      headerHeight={hideHeader ? 0 : 56}
      components={{ Toolbar: mobileToolbarComponent || GridToolbar }}
      localeText={gridLocaleSettings}
      componentsProps={{
        toolbar: {
          showQuickFilter: true,
          quickFilterProps: { debounceMs: 300 },
          csvOptions: { disableToolbarButton: true },
        },
        pagination: {
          labelRowsPerPage: t('rowPerPage'),
        },
      }}
      disableVirtualization
      {...tableData}
      sx={specificStyling}
      disableDensitySelector
      disableColumnSelector
      disableColumnMenu
      disableColumnFilter
      isMobile={isMobile || false}
    />
  );
};

export default ReusableTableComponent;
