import { Middleware } from '@reduxjs/toolkit';
import { io, Socket } from 'socket.io-client';
import { notificationActions } from '../notificationSlice';
import {
  socketJobMetrics,
  socketJobPayload,
  socketJobAttachmentUpdate,
  setRemoveJobRecord,
} from '../jobSlice';
import {
  socketIndividualPayload,
  socketIndividualAttachmentUpdate,
  individualDeleted,
} from '../individualSlice';
import { socketEmployerMetrics, socketEmployerPayload } from '../employerSlice';

const notificationMiddleware: Middleware = (mwStore) => {
  let socket: Socket;

  return (next) => (action) => {
    const state = mwStore.getState();
    const isConnectionEsablished = socket && state.notification.isConnected;

    if (
      notificationActions.startConnecting.match(action) &&
      !isConnectionEsablished &&
      state.auth.token !== null
    ) {
      socket = io(process.env.REACT_APP_SERVER_URL, {
        withCredentials: true,
        auth: { token: state.auth.token },
      });
      mwStore.dispatch(notificationActions.connectionEstablished());

      socket.on('connect', () => {
        console.log('SocketIO connection established!');
        const engine = socket.io.engine;
        console.log('transport: ', engine.transport.name); // in most cases, prints "polling"

        engine.once('upgrade', () => {
          // called when the transport is upgraded (i.e. from HTTP long-polling to WebSocket)
          console.log('Transport upgraded: ', engine.transport.name); // in most cases, prints "websocket"
        });
      });

      socket.on('disconnect', (reason) => {
        console.log('Socket disconnected: ', reason);
        if (reason === 'transport error') {
          socket.connect();
        }
      });

      socket.on('ping', () => {
        console.log('Ping was recieved from the server!');
      });

      socket.on('pong', (latency) => {
        console.log('Pong was emitted to server: ' + latency + ' ms.');
      });

      socket.on('reconnect_error', (error) => {
        console.log('Socket reconnect error detected.');
        console.log(error);
      });

      socket.on('reconnect_attempt', (attempt) => {
        console.log(`reconnection to websockets attempted ${attempt} times`);
      });

      socket.on('connect_error', (error) => {
        console.log('SocketIO Connect Error!');
        console.log(error);
      });

      socket.on('message', (res) => {
        console.info('Notification recieved, storing.', res);
        mwStore.dispatch(notificationActions.receiveMessage(res));
      });

      socket.on('job', (res) => {
        console.info('job recieved, ', res);
        mwStore.dispatch(socketJobPayload(res));
      });

      socket.on('jobs', (res) => {
        mwStore.dispatch(setRemoveJobRecord(res.jobId));
      });

      socket.on('jobFileDel:update', (res) => {
        console.info('attachments event recieved, ', res);
        mwStore.dispatch(socketJobAttachmentUpdate(res));
      });

      socket.on('individualFileDel:update', (res) => {
        console.info('attachments event recieved, ', res);
        mwStore.dispatch(socketIndividualAttachmentUpdate(res));
      });

      socket.on('indv', (res) => {
        console.info('indv event recieved, ', res);
        //send individual object
        mwStore.dispatch(socketIndividualPayload(res));
      });

      socket.on('individual', (res) => {
        mwStore.dispatch(individualDeleted(res.individualId));
      });

      socket.on('jMetric', (res) => {
        console.log('Job Metrics Socket recieved');
        mwStore.dispatch(socketJobMetrics({ ijMetrics: res.ijMetrics, isNew: res.isNew }));
      });

      socket.on('eMetric', (res) => {
        console.log('Employer Metrics Socket recieved');
        mwStore.dispatch(socketEmployerMetrics({ ieMetrics: res.ieMetrics, isNew: res.isNew }));
      });

      socket.on('emp', (res) => {
        console.info('employer event recieved, ', res);
        mwStore.dispatch(socketEmployerPayload(res.data));
        mwStore.dispatch(
          socketEmployerMetrics({ ieMetrics: res.data.ieMetrics, isNew: res.data.isNew })
        );
      });

      socket.on('connect_error', (err) => {
        console.log(err.message); // prints the message associated with the error
      });
    }
    next(action);
  };
};

export default notificationMiddleware;
