import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import {
  useForm,
  SubmitHandler,
  Control,
  UseFormRegister,
  UseFormSetValue,
  Controller,
  useFieldArray,
  SubmitErrorHandler,
} from 'react-hook-form';
import {
  Checkbox,
  MenuItem,
  IconButton,
  FormHelperText,
  InputLabel,
  Box,
  SxProps,
  Typography,
  Button,
  TextField,
  Grid,
} from '@mui/material';
import Select, { GroupBase, MultiValue } from 'react-select';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import axios from 'axios';
import ReactQuill from 'react-quill';
import FileUploadRoundedIcon from '@mui/icons-material/FileUploadRounded';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

import { Employer, NoteBullet, SelectType } from '../typescript/types';
import { useAppDispatch } from '../typescript/hooks';
import {
  getEmployerById,
  getEmployers,
  postEmployer,
  setEmployerStatus,
  updateEmployer,
} from '../../store/employerSlice';
import useImgUpload from '../../hooks/useImgUpload';
import Icon from '../../asssets/icons';
import SuccessComponent from '../UI/Popup/SuccessComponent';
import { INDUSTRY, INDUSTRY_FR } from '../../helpers/constants';
import { getMyProviderDetails, getProvidersInOrganization } from '../../store/providerSlice';
import {
  displaySelectTypeOptions,
  displayStringOptions,
  getPreviousTags,
} from '../../helpers/displayOptions';
import { formatPhoneNumber } from '../../helpers/formatNumber';
import useAutoCompleteAPI from '../../hooks/useAutoCompleteAPI';
import { appendRemainingDetails, capitalize } from '../../helpers/helperFunctions';
import { getAccessToken, getSignedUrl } from '../../store/authSlice';
import PageTitleComponent from '../PageTitleComponent';
import {
  formDescriptionStyling,
  quillSelectLabel,
  reactSelectCustomStyles,
  reactSelectLabel,
} from '../../helpers/styling';
import { regXEmail, regXPhoneNumber } from '../../helpers/constants';
import { getTags } from '../../store/keywordSlice';
import HideFormOverlayComponent from '../UI/Popup/HideFormOverlayComponent';
import ControllerAutoComplete, { GlobalFormData } from '../FormComponents/ControllerAutoComplete';
import useAutoCompleteItems from '../../hooks/useAutoCompleteItems';
import { getAllJobs } from '../../store/jobSlice';
import Modal from '../UI/Popup/BackdropComponent';
import PopupMessageComponent from '../UI/Popup/PopupMessageComponent';
import useTranslate from '../../hooks/useTranslate';
import SelectAnOptionPopupComponent from '../UI/Popup/SelectAnOptionPopupComponent';
import { RootState } from '../../store';
import SubmitKeywordSuggestions from '../SharedComponents/SubmitKeywordSuggestions';
import ReusableToggleComponent from '../SharedComponents/ReusableToggleComponent';
import {
  CommentsWrapper,
  FormComponentWrapper,
  FormContainer,
  FormFieldCheckBoxContainer,
  FormFieldContainer,
  FormImgContainer,
  FormPanel,
  FormRow,
  FormSwitch,
  FormTextInput,
  HorizontalScrollContainer,
  ImgContainer,
  MobileFormFieldContainer,
  PanelCarousel,
  RichTextFormatWrapper,
  ReqErrorMsg,
  FormTextAreaInput,
  NewFormContainer,
  NewFormPanel,
} from '../UI/StyledComponents/FormsStyledComponents';
import useDetectResize from '../../hooks/useDetectResize';
import { InlineContainer, StackContents } from '../UI/StyledComponents/GlobalStyledComponents';
import StepperWidget from './SharedWidgets/StepperWidget';
import {
  CloseAllJobsOverlay,
  DeleteOverlay,
  KeywordSubmitOverlay,
} from '../UI/StyledComponents/NotificationModalStyledComponents';
import useFocusTrap from '../../hooks/useFocusTrap';
import 'dayjs/locale/en';
import 'react-quill/dist/quill.snow.css';
import { useGetEmployerByIdQuery } from '../../store/services/employerApi';
import { LoadingAnimation } from '../UI/Animation/LoadingAnimation';
import CharCountAlert from '../SharedComponents/CharCountAlert';

export interface EmployerFormData extends Partial<Employer> {
  logoUpload: FileList;
  creator?: string;
  calculateMetrics: boolean;
}

interface PropsEmployerForm {
  isEdit: boolean;
  employer?: Employer;
  isNavCollapsed: boolean;
}

const EmployersCreateComponent = (props: PropsEmployerForm) => {
  //isEdit sets defaultValues to new values. if creating a new one, the values below should populate when clicking 'add'
  let defaultValues = {
    id: '',
    imgLogo: 'employer-default.png',
    pocFirstName: '',
    pocLastName: '',
    pocPhone: '',
    pocEmail: '',
    orgName: '',
    parentCompName: '',
    industry: 'default',
    details: '',
    address: '',
    latitude: 0,
    longitude: 0,
    reqSkills: '',
    multiLocation: false,
    leadProviderId: 'default',
    status: true,
    notes: [],
    tags: [],
    activityType: '',
    calculateMetrics: true,
  };

  const {
    register,
    handleSubmit,
    formState,
    reset,
    control,
    setValue,
    getValues,
    setFocus,
    trigger,
    watch,
  } = useForm<EmployerFormData>({ defaultValues });
  const { fields, prepend, remove } = useFieldArray({ control, name: 'notes' });

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const t = useTranslate();
  const { errors, isValid } = formState;

  const { parentCompName, employerId } = useParams();
  const { isLoading } = useGetEmployerByIdQuery({
    orgName: parentCompName!,
    employerId: employerId!,
  });

  const { imgUrl, uploadImgFn, setImgUrlOnLoad } = useImgUpload();
  const [employerPath, setEmployerPath] = useState<string>('');
  const [displaySuccessModal, setDisplaySuccessModal] = useState(false);
  const [displayKeywordSubmit, setDisplayKeywordSubmit] = useState<boolean>(false);
  const [tags, setTags] = useState<number[]>([]);
  const myDetails = useSelector(getMyProviderDetails);

  const providers = useSelector(getProvidersInOrganization);
  const providerOptions: SelectType[] = providers.map((provider: any) => {
    return {
      value: provider.id,
      label: `${provider.firstName} ${provider?.lastName}`,
    } as unknown as SelectType;
  });
  const employer: Employer = useSelector((state: RootState) =>
    getEmployerById(state, employerId!)
  )!;
  const allEmployers = useSelector(getEmployers);
  const allJobs = useSelector(getAllJobs);
  const [newEmployer, setNewEmployer] = useState<Employer | null>(null);
  const accessToken = useSelector(getAccessToken);
  const keywords = useSelector((state: RootState) => getTags(state, t));

  const { autoComplete, setWidget: InitializeAutoComplete } = useAutoCompleteAPI(handlePlaceSelect); //Google API custom hook for accessing PlacesAPI
  const { isEdit, isNavCollapsed } = props;
  const [isAddressValid, setAddressValid] = useState<boolean>(isEdit ? true : false);
  const [tagOptions, setTagOptions] = useState<any>(
    isEdit ? getPreviousTags(employer?.tags!, keywords?.all as unknown as SelectType[]) : null
  );
  const [hideFormOverlay, setHideFormOverlay] = useState<boolean>(false);

  const { optionItems, setOptionItems } = useAutoCompleteItems();
  const { optionItems: parentItems, setOptionItems: setParentItems } = useAutoCompleteItems();
  const [disableFormSubmitOrg, setDisableFormInputOrg] = useState<boolean>(false);
  const [userEditOrg, setUserEditOrg] = useState<boolean>(false);
  const [hasOpenJobs, setHasOpenjobs] = useState<boolean>(false);
  const [modalJobPrompt, setModalJobPrompt] = useState<boolean>(false);
  const [updateJobsWithProvider, setUpdateJobsWithProvider] = useState<boolean>(false);
  const [isMultipleLocation, setIsMultipleLocation] = useState<boolean>(
    isEdit ? employer?.multiLocation : false
  );

  const [activeStep, setActiveStep] = useState<number>(0);
  const [formWidthStyling, setFormWidth] = useState<SxProps>({});
  const { windowDimensions, isDesktop, isLaptop, isMobile, isTablet } = useDetectResize();
  const industryList = (localStorage.getItem('language') || 'en') === 'en' ? INDUSTRY : INDUSTRY_FR;

  const formRef = useRef<null | HTMLDivElement>(null);
  const refPanel_1 = useRef<null | HTMLDivElement>(null);
  const refPanel_2 = useRef<null | HTMLDivElement>(null);
  const refPanel_3 = useRef<null | HTMLDivElement>(null);
  const refPanel_4 = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    //On startup, hook onto the form's address input, then feed it to custom hook to initialize the API.
    const input = document.getElementById('autocomplete') as HTMLInputElement;

    InitializeAutoComplete(input);

    if (!isLoading && isEdit && employer) {
      setTags((currState) => (currState = [...employer?.tags!]));
      setValue('longitude', employer?.longitude!);
      setValue('latitude', employer?.latitude!);
      setImgUrlOnLoad(employer?.imgLogo);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      let existingValues = {
        ...employer,
        tags: tags!,
        calculateMetrics: false,
        notes: JSON.parse(employer!.notes as unknown as string),
      }; //want this so if user toggles between editing a record or creating a new record, the form disposes of previous values.
      reset({ ...existingValues });
    } else {
      setAddressValid((currState) => (currState = false));
      reset({ ...defaultValues });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, employer, windowDimensions.w]);

  useEffect(() => {
    setOptionItems(
      allEmployers.map((employer: Employer) => {
        return employer.orgName;
      })
    );
    setParentItems(
      allEmployers.map((employer: Employer) => {
        return employer.parentCompName;
      })
    );
  }, [allEmployers]);

  //Renders select when user removes/adds keywords or refreshes page
  useEffect(() => {
    setTagOptions(getPreviousTags(tags, keywords?.all as unknown as SelectType[]));
    console.log('TAGS----->', tags);
  }, [tags]);

  const orgName = watch('orgName');

  useEffect(() => {
    if (isMultipleLocation) {
      return;
    }

    if (orgName) {
      setValue('parentCompName', orgName, { shouldDirty: true });
    }
  }, [isMultipleLocation, orgName, setValue]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!isLoading && formRef.current) {
        console.log('Adjusted form dimensions...');
        setFormWidth({ width: `${formRef.current.offsetWidth}px` });
      }
    }, 150); // 150ms timeout schedules for the next tick

    // Cleanup the timeout if the component unmounts before the timeout fires
    return () => clearTimeout(timer);
  }, [formRef, windowDimensions.w, windowDimensions.h, isNavCollapsed, isLoading]);

  // used to properly align panel on scroll for desktop, little bit of a hack, but works.
  useEffect(() => {
    console.log('errors', errors);

    if (
      errors?.orgName ||
      errors?.leadProviderId ||
      errors?.industry ||
      errors?.address ||
      errors?.parentCompName
    ) {
      setActiveStep(0);
      // return refPanel_1.current!.scrollIntoView({
      //   behavior: 'smooth',
      //   block: 'nearest',
      //   inline: 'center',
      // });
    } else if (errors?.pocFirstName || errors?.pocPhone) {
      setActiveStep(1);
      // return refPanel_2.current!.scrollIntoView({
      //   behavior: 'smooth',
      //   block: 'nearest',
      //   inline: 'center',
      // });
    } else if (errors?.tags) {
      setActiveStep(2);
      // return refPanel_3.current!.scrollIntoView({
      //   behavior: 'smooth',
      //   block: 'nearest',
      //   inline: 'center',
      // });
    }
  }, [
    errors?.orgName,
    errors?.parentCompName,
    errors?.leadProviderId,
    errors?.industry,
    errors?.address,
    errors?.pocFirstName,
    errors?.pocPhone,
    errors?.tags,
  ]);

  const handleChange = (e: MultiValue<any>) => {
    // console.log(e);
    setValue('calculateMetrics', true);
    let result: number[] = [];
    if (!!e.length) {
      result = [];
      e.forEach((selection: { label: string; value: string }) => {
        result.push(+selection!.value);
      });
    } else {
      result = [];
    }
    setTags(result);
  };

  function showUploadedImg(e: ChangeEvent<HTMLInputElement>) {
    uploadImgFn(e);
  }

  const clearImg = (e: { preventDefault: () => void }) => {
    uploadImgFn('clear-img');
    setValue('imgLogo', '');
  };

  //removes popup, sets reducer status to success, and navigates to new page with state.
  const navigateHandler = () => {
    setDisplaySuccessModal((currState) => (currState = false));
    setEmployerStatus('succeeded');
    navigate(`/employers/${employerPath}`, { state: newEmployer });
  };

  function handlePlaceSelect() {
    let addressObject = autoComplete!.getPlace();
    // console.info('AutoComplete Object: ', addressObject);
    if (addressObject !== undefined) {
      let addressName = addressObject.name + ', ' + appendRemainingDetails(addressObject);
      setValue('address', addressName);
      setValue('longitude', addressObject!.geometry!.location!.lng());
      setValue('latitude', addressObject!.geometry!.location!.lat());
      setValue('calculateMetrics', true);
      setAddressValid((currState: boolean) => (currState = true));
    } else {
      alert('You must enter a valid address');
      setValue('address', '');
      setFocus('address', { shouldSelect: true });
    }
  }

  function handleOrgDuplicates(input?: string) {
    //if input matches organization then disable the update employer button.
    let disableSubmit =
      allEmployers.find(
        (employer) => input!.replace(/\s+/g, '') === employer.orgName.replace(/\s+/g, '')
      ) !== undefined;
    setDisableFormInputOrg((currState) => {
      return (currState = disableSubmit);
    });
    setUserEditOrg((currState) => {
      return (currState = isEdit! && true);
    });
    console.log('triggered onChange org ');
    if (!isMultipleLocation) {
      setValue('parentCompName', input);
    }
  }

  function checkJobs(e: ChangeEvent<HTMLInputElement>) {
    let currentStatus = getValues('status');
    if (currentStatus === false && isEdit) {
      console.log('check active jobs for empId: ', employer.id);
      let openJobsArr = allJobs.filter((job) => job.employerId === employer.id && job.isJobActive);
      console.log(openJobsArr);
      if (openJobsArr.length > 0) {
        setHasOpenjobs(true);
        setValue('status', true);
      }
    }
  }

  // const goToPanel = (step: number) => {
  //   console.log('Current Step to scroll to is... ', step);
  //   step === 0
  //     ? refPanel_1.current!.scrollIntoView({
  //         behavior: 'smooth',
  //         block: 'nearest',
  //         inline: 'center',
  //       })
  //     : step === 1
  //       ? refPanel_2.current!.scrollIntoView({
  //           behavior: 'smooth',
  //           block: 'nearest',
  //           inline: 'center',
  //         })
  //       : step === 2
  //         ? refPanel_3.current!.scrollIntoView({
  //             behavior: 'smooth',
  //             block: 'nearest',
  //             inline: 'center',
  //           })
  //         : refPanel_4.current!.scrollIntoView({
  //             behavior: 'smooth',
  //             block: 'nearest',
  //             inline: 'center',
  //           });
  // };

  const errorHandler: SubmitErrorHandler<EmployerFormData> = (res: any) => {
    console.log(res);
    console.log('errorHandler initialized');
  };

  const onSubmit: SubmitHandler<EmployerFormData> = async (data) => {
    console.log('onSubmit initiated');
    setHideFormOverlay((currState) => (currState = true));

    data.tags = tags;
    data.providerOrganizationId = myDetails.orgId;
    const notes = data.notes ? data.notes.map(({ isNew, ...keepAttrs }) => keepAttrs) : [];
    data.notes = JSON.stringify(notes).replace(/</g, '\\u003c') as unknown as NoteBullet[];
    data.creator = myDetails.id;

    if (isEdit && data.longitude === null && data.latitude === null) {
      //has not been updated, keep previous.
      data.longitude = employer!.longitude;
      data.latitude = employer!.latitude;
    }

    const processImageUpload = new Promise((resolve, reject) => {
      if (data.logoUpload[0]! !== undefined) {
        //if it detects a file upload, append it. otherwise it will keep default/previously saved path.
        // this sends a PUT to the aws url with the data defined in bucketParams.body

        dispatch(
          getSignedUrl({
            orgId: myDetails.orgId,
            fileName: 'emp-logo',
            contentType: data.logoUpload![0].type,
            subFolder: 'employers',
            fileType: 'png',
            token: accessToken!,
            creator: myDetails.id,
          })
        )
          .then(unwrapResult)
          .then(async (res) => {
            console.info('EmployerCreateComponent - SignedURL response', res);
            return await axios({
              method: 'PUT',
              url: res.signedUrl,
              data: data.logoUpload![0],
            }).then((results) => {
              console.info('SignedURL results: ', results);
              if (results.status === 200) {
                resolve((data.imgLogo = `${process.env.REACT_APP_S3_RES_BUCKET}` + res.key));
              } else {
                console.warn(
                  'Error uploading image. Please try again or contact the administrator.'
                );
                reject(alert('Error saving image, please try again'));
              }
            });
          });
      } else {
        resolve(true);
      }
    });

    processImageUpload
      .then(() => {
        if (isEdit) {
          data.id = employer.id!;
          console.info('Form Submission', data);
          dispatch(
            updateEmployer({
              employerObject: data as unknown as EmployerFormData,
              token: accessToken!,
              updateJobs: userEditOrg,
              updateNewProviderToAllJobs: updateJobsWithProvider,
            })
          )
            .then(unwrapResult)
            .then((response: { data: { employers: Employer[] } }) => {
              console.log(response);

              //console.info('Employer update response: ', response.data.employers[0]!);
              handleRouteChange(response.data.employers[0]);
            })
            .catch((e) => {
              alert('Error saving data, please check your internet connectivity and try again.');
              setHideFormOverlay((currState) => (currState = true));
            })
            .finally(() => {
              setHideFormOverlay((currState) => (currState = false));
            });
        } else {
          dispatch(
            postEmployer({
              employerObject: data as unknown as EmployerFormData,
              token: accessToken!,
            })
          )
            .then(unwrapResult)
            .then((response: { data: { employers: Employer[] } }) => {
              console.info('Employer post response: ', response.data);
              handleRouteChange(response.data.employers[0]);
            })
            .catch((e) => {
              alert('Error saving data, please check your internet connectivity and try again.');
              setHideFormOverlay((currState) => (currState = true));
            })
            .finally(() => {
              setHideFormOverlay((currState) => (currState = false));
            });
        }
      })
      .catch((e) => {
        console.warn(
          'uh oh, something went wrong with image uploading, try again later, check your internet connection, or upload smaller files.\n',
          e
        );
      });

    function handleRouteChange(responseData: Employer) {
      setNewEmployer((currState) => (currState = responseData!));
      setEmployerPath(
        `${encodeURIComponent(responseData.parentCompName)}/${encodeURIComponent(responseData.id)}`
      );
      setDisplaySuccessModal((currState) => (currState = true));
    }
  };

  console.log(errors);
  console.log(isValid);

  useFocusTrap(refPanel_1);
  useFocusTrap(refPanel_2);
  useFocusTrap(refPanel_3);
  useFocusTrap(refPanel_4);

  if (isLoading) {
    return <LoadingAnimation />;
  }
  return (
    <>
      {/* Set conditional to oad mobile/tablet component here */}
      <>
        {(isDesktop || isLaptop) && (
          <PageTitleComponent greeting={isEdit ? t('employerViewEditBtn') : t('addEmployerBtn')} />
        )}
        <FormComponentWrapper>
          <form
            style={{ height: 'inherit' }}
            //onSubmit={() => {console.log('clicked!!!!'); handleSubmit(onSubmit, errorHandler); }}
            onKeyDown={(e) => {
              return e.code === 'Enter' ? e.preventDefault() : null;
            }}
          >
            <NewFormContainer>
              {isDesktop || isLaptop ? (
                <StepperWidget
                  steps={['Key Information', 'Employer Profile', 'Keywords', 'Notes/Comments']}
                  isMobileTabletWidth={false}
                  stackStepsTitle
                  disableFormSubmitOrg={disableFormSubmitOrg}
                  isEdit={isEdit!}
                  getValues={getValues}
                  deleteThisRecordId={employer?.id}
                  deleteThisRecordMsg={t('deleteAllJobWarningMsg')}
                  tags={tags}
                  isFormType="employer"
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                  handleSubmit={handleSubmit(onSubmit)}
                  trigger={trigger}
                  formState={formState}
                >
                  <NewFormPanel
                    className="panel-1"
                    ref={refPanel_1}
                    display={activeStep === 0 ? 'block' : 'none'}
                  >
                    <FormRow maxHeight={'125px'}>
                      {/* img upload  */}
                      <InlineContainer
                        justifyContent="flex-end"
                        alignItems="flex-end"
                        sx={{ marginBottom: '20px' }}
                      >
                        <div className="emp-logo-container">
                          <ImgContainer>
                            <FormImgContainer
                              width={windowDimensions.w}
                              height={windowDimensions.h}
                            >
                              <Controller
                                control={control}
                                name="logoUpload"
                                render={({ field }) => (
                                  <input //ImgInput
                                    className="employer-logo-input"
                                    type="file"
                                    alt="logo-upload"
                                    accept="image/*"
                                    {...register('logoUpload', {
                                      onChange: (e) => {
                                        showUploadedImg(e);
                                      },
                                    })}
                                  />
                                )}
                              />
                              <ImageOverlayComponent
                                imgUrl={imgUrl}
                                clearImg={clearImg}
                                isEdit={isEdit}
                                isMobileTabletView={isMobile || isTablet}
                              />
                            </FormImgContainer>
                          </ImgContainer>
                        </div>
                        {/* employer status inputs */}
                        <InlineContainer className="form-half-width">
                          <Controller
                            control={control}
                            name="status"
                            render={({ field: { value } }) => (
                              <FormSwitch>
                                <Typography variant="subtitle2">{t('empStatus')}</Typography>
                                <div className="inline-ca grey-stitch">
                                  <ReusableToggleComponent
                                    toggleValue={value}
                                    toggleSelect={() => {
                                      setValue('status', !value);
                                    }}
                                    firstToggle={t('active')}
                                    secondToggle={t('inactive')}
                                  />
                                </div>
                              </FormSwitch>
                            )}
                          />
                        </InlineContainer>
                      </InlineContainer>
                    </FormRow>

                    {/* lead provider | industry | orgName | multiLocation | parentComp | address |   */}
                    <FormRow justifyContent="space-between" maxHeight="75px">
                      <FormFieldContainer className="form-half-width">
                        <Controller
                          control={control}
                          name="leadProviderId"
                          rules={{
                            required: t('leadProviderReqMsg'),
                            pattern: {
                              value: /^(?!default)\w*/i,
                              message: 'You must select a lead provider.',
                            },
                          }}
                          render={({ field: { onChange, value } }) => (
                            <FormTextInput
                              id="outlined-required"
                              label={t('leadProviderLabel')}
                              fullWidth
                              required
                              select
                              autoFocus={
                                getValues('leadProviderId')!.length < 1 ||
                                getValues('leadProviderId') === undefined
                              }
                              disabled={
                                !isEdit
                                  ? false
                                  : isEdit && employer?.leadProviderId === myDetails?.id
                                    ? false
                                    : isEdit && myDetails?.isAdmin
                                      ? false
                                      : true
                              }
                              {...register('leadProviderId')}
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                                isEdit && setModalJobPrompt(true);
                              }}
                            >
                              <MenuItem value="default" disabled selected>
                                {t('selectLeadProv')}
                              </MenuItem>
                              {displaySelectTypeOptions(providerOptions)}
                            </FormTextInput>
                          )}
                        />
                        {errors?.leadProviderId && (
                          <ReqErrorMsg>{errors?.leadProviderId.message}</ReqErrorMsg>
                        )}
                      </FormFieldContainer>
                      <FormFieldContainer className="form-half-width">
                        <Controller
                          control={control}
                          name="industry"
                          rules={{
                            required: t('selectIndutryTextII'),
                            validate: () => {
                              if (getValues('industry') === 'default') {
                                return 'Select an industry.';
                              }
                            },
                          }}
                          render={({ field: { value, onChange } }) => (
                            <FormTextInput
                              label={t('industry')}
                              fullWidth
                              select
                              {...register('industry')}
                              value={value}
                              required={true}
                              onChange={onChange}
                            >
                              <MenuItem value="default" disabled>
                                {t('selectIndustryText')}
                              </MenuItem>
                              {displayStringOptions(industryList)}
                            </FormTextInput>
                          )}
                        />
                        {errors?.industry && <ReqErrorMsg>{errors?.industry.message}</ReqErrorMsg>}
                      </FormFieldContainer>
                    </FormRow>

                    <FormRow height={windowDimensions.h}>
                      <FormFieldContainer className="form-half-width">
                        <ControllerAutoComplete
                          control={control as Control<Partial<GlobalFormData>, any>}
                          name="orgName"
                          placeholder={t('jobInputPlaceholder')}
                          required={true}
                          register={register as UseFormRegister<Partial<GlobalFormData>>}
                          label={t('orgName')}
                          id="outlined-required"
                          optionItems={optionItems.filter(function (item, pos, self) {
                            return self.indexOf(item) == pos;
                          })}
                          rules={{ required: t('enterOrgName') }}
                          throwMessage={t('duplicateOrgFoundMsg')}
                          handleInput={(input?: string) => {
                            if (input) {
                              handleOrgDuplicates(input);
                              setValue('parentCompName', input, { shouldDirty: true });
                            }
                          }}
                        />
                        {errors?.orgName ? (
                          <ReqErrorMsg>{errors?.orgName.message}</ReqErrorMsg>
                        ) : disableFormSubmitOrg ? (
                          <ReqErrorMsg>{t('duplicateOrgFound')}</ReqErrorMsg>
                        ) : (
                          <FormHelperText sx={{ marginLeft: '15px' }}>
                            {t('orgNameHelperText')}
                          </FormHelperText>
                        )}
                      </FormFieldContainer>

                      <FormFieldCheckBoxContainer
                        className="inline-a cb-location multi-location form-half-width"
                        display="inline-flex"
                      >
                        <ControllerCheckBox
                          control={control}
                          name={'multiLocation'}
                          register={register}
                          setValue={setValue}
                          setDisableInput={setIsMultipleLocation}
                        />
                        <label>{t('multiLocation')}</label>
                      </FormFieldCheckBoxContainer>
                    </FormRow>

                    <FormRow height={windowDimensions.h} marginTop="20px">
                      <FormFieldContainer className="form-half-width">
                        <ControllerAutoComplete
                          className={''}
                          id="outlined-required"
                          control={control as Control<Partial<GlobalFormData>, any>}
                          register={register as UseFormRegister<Partial<GlobalFormData>>}
                          name="parentCompName"
                          rules={{ required: t('mainBranchNameReqMsg') }}
                          required={true}
                          placeholder={t('parentOrgNamePlaceholder')}
                          label={t('parentOrgNameLabel')}
                          optionItems={parentItems.filter(function (item, pos, self) {
                            return self.indexOf(item) == pos;
                          })}
                          throwMessage={t('duplicateParentOrgFoundMsg')}
                          disabled={!getValues('multiLocation')}
                        />
                        {errors?.parentCompName ? (
                          <ReqErrorMsg>{errors?.parentCompName.message}</ReqErrorMsg>
                        ) : (
                          <FormHelperText sx={{ marginLeft: '15px' }}>
                            {t('parentOrgNameHelperText')}
                          </FormHelperText>
                        )}
                      </FormFieldContainer>
                      <FormFieldContainer className="form-half-width">
                        <ControllerTextField
                          control={control}
                          register={register}
                          rules={{
                            required: t('addressReqMsg'),
                            validate: () => {
                              return isAddressValid;
                            },
                          }}
                          name="address"
                          required={true}
                          placeholder={t('address')}
                          className=" "
                          id="autocomplete"
                          onClick={() => {
                            setAddressValid((currState) => (currState = false));
                          }}
                        />
                        {errors?.address?.type === 'required' ? (
                          <ReqErrorMsg>{errors?.address.message}</ReqErrorMsg>
                        ) : !isAddressValid ? (
                          <ReqErrorMsg>{t('addressHelperText')}</ReqErrorMsg>
                        ) : null}
                      </FormFieldContainer>
                    </FormRow>
                  </NewFormPanel>

                  {/* employer description/details point of contact name, number email */}
                  <NewFormPanel
                    className="panel-2"
                    ref={refPanel_2}
                    display={activeStep === 1 ? 'block' : 'none'}
                  >
                    <FormRow justifyContent="space-between">
                      <FormFieldContainer className="form-half-width">
                        <ControllerTextField
                          className="form-half-width full-width"
                          control={control}
                          register={register}
                          rules={{ required: `Main contact first name required` }}
                          name="pocFirstName"
                          placeholder={t('mcFirstNameLabel')}
                          label={t('mcFirstNameLabel')}
                          required={true}
                          id="outlined-required"
                        />
                        {errors?.pocFirstName && (
                          <ReqErrorMsg>{errors?.pocFirstName.message}</ReqErrorMsg>
                        )}
                      </FormFieldContainer>

                      <FormFieldContainer className="form-half-width">
                        <ControllerTextField
                          className="form-half-width full-width"
                          control={control}
                          register={register}
                          name="pocLastName"
                          placeholder={t('mcLastNameLabel')}
                          label={t('mcLastNameLabel')}
                          required={false}
                          id="outlined-required"
                        />
                        {errors?.pocLastName && (
                          <ReqErrorMsg>{errors?.pocLastName.message}</ReqErrorMsg>
                        )}
                      </FormFieldContainer>
                    </FormRow>

                    <FormRow justifyContent="space-between">
                      <FormFieldContainer className="form-half-width">
                        <ControllerTextField
                          className="form-half-width full-width"
                          control={control}
                          register={register}
                          rules={{
                            required: t('mcPhoneNumberMsg'),
                            maxLength: 35,
                            pattern: {
                              value: regXPhoneNumber,
                              message: t('validPhoneReq'),
                            },
                          }}
                          name="pocPhone"
                          placeholder={t('mcPhoneNumberLabel')}
                          customFunction={formatPhoneNumber}
                          required={true}
                          id="outlined-required"
                        />

                        {
                          <ReqErrorMsg>
                            {errors?.pocPhone?.type === 'required'
                              ? errors?.pocPhone.message
                              : errors?.pocPhone?.type === 'maxLength'
                                ? t('phoneErrorMsg')
                                : errors?.pocPhone?.type === 'pattern'
                                  ? t('phoneHelperText')
                                  : null}
                          </ReqErrorMsg>
                        }
                      </FormFieldContainer>

                      <FormFieldContainer className="form-half-width">
                        <ControllerTextField
                          className="form-half-width full-width"
                          control={control}
                          register={register}
                          rules={{
                            pattern: {
                              value: regXEmail,
                              message: t('emailErrorMsg'),
                            },
                          }}
                          name="pocEmail"
                          placeholder={t('mcEmailLabel')}
                          required={false}
                          id="outlined-required"
                        />
                        {errors?.pocEmail && <ReqErrorMsg>{errors?.pocEmail.message}</ReqErrorMsg>}
                      </FormFieldContainer>
                    </FormRow>

                    <FormRow height="fit-content" marginTop="0px">
                      <RichTextFormatWrapper>
                        <InputLabel sx={quillSelectLabel}>{t('description')}</InputLabel>
                        <Controller
                          control={control}
                          name="details"
                          rules={{
                            validate: (value) => {
                              if (value!.length > 3000) {
                                return `${t('descErrorMsg')} (${value!.length}/3000)`;
                              }
                            },
                          }}
                          render={({ field: { onChange, value } }) => (
                            <>
                              <CharCountAlert value={value} />
                              <ReactQuill
                                placeholder={t('employerDetails')}
                                theme="snow"
                                value={value}
                                onChange={onChange}
                              />
                            </>
                          )}
                        />
                        {errors.details?.type === 'validate' ? (
                          <ReqErrorMsg>{errors.details?.message}</ReqErrorMsg>
                        ) : (
                          <FormHelperText sx={{ marginLeft: '10px' }}>
                            {t('thousandMaxCharMsg')}
                          </FormHelperText>
                        )}
                      </RichTextFormatWrapper>
                    </FormRow>
                  </NewFormPanel>

                  {/* Keyword tags */}
                  <NewFormPanel
                    className="panel-3"
                    ref={refPanel_3}
                    display={activeStep === 2 ? 'block' : 'none'}
                  >
                    <div className="full-width" style={{ marginTop: '20px' }}>
                      <Controller
                        control={control}
                        name="tags"
                        rules={{
                          validate: () => {
                            if (tags.length < 2) {
                              return 'Provide at least two keywords.';
                            }
                          },
                        }}
                        render={({ field: { onChange } }) => (
                          <>
                            <InputLabel sx={reactSelectLabel} required>
                              {t('keywordLabel')}
                            </InputLabel>
                            <Select
                              required
                              closeMenuOnSelect={false}
                              blurInputOnSelect={false}
                              autoFocus={errors.tags !== undefined}
                              placeholder={t('keywordLabel')}
                              styles={reactSelectCustomStyles}
                              options={(keywords.all as unknown as GroupBase<SelectType>[]).filter(
                                (tag: any) => !tags.includes(tag.value)
                              )}
                              isMulti={true}
                              value={tagOptions!}
                              onChange={(e) => {
                                onChange(handleChange(e));
                              }}
                            />
                          </>
                        )}
                      />
                      {errors?.tags ? (
                        <ReqErrorMsg>{errors?.tags.message}</ReqErrorMsg>
                      ) : (
                        <FormHelperText sx={{ marginLeft: '15px' }}>
                          {t('keywordPlaceholder')}
                        </FormHelperText>
                      )}
                      <br></br>
                      <SubmitKeywordSuggestions
                        labelNoun={t('keywords').toLowerCase()}
                        displayModal={setDisplayKeywordSubmit}
                      />
                      <FormFieldContainer sx={{ display: 'none' }}>
                        <ControllerTextField
                          control={control}
                          register={register}
                          name="reqSkills"
                          placeholder={t('reqSkillsPlaceholder')}
                          label={t('reqSkills')}
                          className="full-width"
                        />
                        {errors?.reqSkills && (
                          <ReqErrorMsg>{errors?.reqSkills.message}</ReqErrorMsg>
                        )}
                      </FormFieldContainer>
                      <div tabIndex={0}></div> {/* keeping this here to trap the tab key */}
                    </div>
                  </NewFormPanel>

                  <NewFormPanel
                    className="panel-4"
                    ref={refPanel_4}
                    display={activeStep === 3 ? 'block' : 'none'}
                  >
                    <CommentsWrapper>
                      <Box className="full-width inline-btwn inline-a blue-stitch">
                        <Typography variant="subtitle2">{t('notesComments')}</Typography>
                        <Button
                          type="button"
                          variant="contained"
                          sx={{
                            background: 'var(--primary-color)',
                            '&:hover': {
                              background: 'var(--background)',
                              color: 'var(--primary-color)',
                              boxShadow: 'none',
                              border: '1px solid var(--primary-color)',
                            },
                          }}
                          onClick={() => {
                            prepend({
                              body: '',
                              ts: dayjs().format('MM/DD/YY'),
                              providerName: myDetails.firstName + ' ' + myDetails.lastName,
                              isNew: true,
                              activityType: null,
                            });
                          }}
                        >
                          + {t('notesComments')}
                        </Button>
                      </Box>
                      {fields.map(({ id }, index) => {
                        const isNew = fields[index].isNew;
                        return (
                          <div
                            style={{ marginTop: '1rem' }}
                            className="employment-record-item"
                            key={`${id}-${index}`}
                          >
                            <Grid container spacing={2}>
                              <Grid item md={8}>
                                <Controller
                                  control={control}
                                  name={`notes.${index}.body` as const}
                                  rules={{
                                    validate: (value) => {
                                      if (value!.length > 1000) {
                                        return `${t('notesErrorMsg')} (${value!.length}/1000)`;
                                      }
                                    },
                                  }}
                                  render={({ field: { onChange, value } }) => (
                                    <FormTextAreaInput
                                      {...register(`notes.${index}.body`)}
                                      fullWidth
                                      label={t('notesComments')}
                                      multiline
                                      rows={9}
                                      value={value}
                                      onChange={onChange}
                                      disabled={
                                        getValues(`notes.${index}.providerName`) !==
                                        `${myDetails?.firstName} ${myDetails?.lastName}`
                                      }
                                      error={!!errors.notes?.[index]?.body?.message}
                                      helperText={
                                        errors.notes?.[index]?.body?.type === 'validate'
                                          ? errors.notes?.[index]?.body?.message
                                          : t('thousandMaxCharMsg')
                                      }
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item md={isNew ? 3 : 4} container>
                                <Grid item md={12}>
                                  <FormFieldContainer className="full-width">
                                    <Controller
                                      control={control}
                                      name={`notes.${index}.activityType`}
                                      render={({ field: { onChange, value } }) => (
                                        <FormTextInput
                                          id="outlined-required"
                                          label={t('activityType')}
                                          fullWidth
                                          select
                                          value={value}
                                          onChange={onChange}
                                        >
                                          <MenuItem value="initialContract">
                                            {t('initialContract')}
                                          </MenuItem>
                                          <MenuItem value="followUpContract">
                                            {t('followUpContract')}
                                          </MenuItem>
                                          <MenuItem value="presentation">
                                            {t('presentation')}
                                          </MenuItem>
                                          <MenuItem value="jobPostingNew">
                                            {t('jobPostingNew')}
                                          </MenuItem>
                                          <MenuItem value="jobPostingRenew">
                                            {t('jobPostingRenew')}
                                          </MenuItem>
                                        </FormTextInput>
                                      )}
                                    />
                                  </FormFieldContainer>
                                </Grid>
                                <Grid
                                  container
                                  item
                                  md={12}
                                  spacing={2}
                                  direction="column"
                                  alignItems="center"
                                  alignContent="center"
                                  // justifyContent="space-between"
                                >
                                  <Grid item>
                                    <Controller
                                      control={control}
                                      name={`notes.${index}.providerName` as const}
                                      render={({ field: { onChange, value } }) => (
                                        <TextField
                                          {...register(`notes.${index}.providerName`)}
                                          id="standard-basic"
                                          disabled
                                          InputProps={{ disableUnderline: true }}
                                          label={t('creator')}
                                          variant="standard"
                                          // sx={{
                                          //   '.MuiInputBase-input': { textAlign: 'center' },
                                          // }}
                                          value={
                                            value ||
                                            `${myDetails?.firstName} ${myDetails?.lastName}`
                                          }
                                          onChange={onChange}
                                        />
                                      )}
                                    />
                                  </Grid>
                                  <Grid item>
                                    <Controller
                                      control={control}
                                      name={`notes.${index}.ts` as const}
                                      render={({ field: { onChange } }) => (
                                        <TextField
                                          {...register(`notes.${index}.ts`)}
                                          id="standard-basic"
                                          disabled
                                          label={t('created')}
                                          InputProps={{ disableUnderline: true }}
                                          variant="standard"
                                          // sx={{
                                          //   '.MuiInputBase-input': { textAlign: 'center' },
                                          // }}
                                          value={dayjs().format('MM/DD/YY')}
                                          onChange={onChange}
                                        />
                                      )}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>

                              {isNew && (
                                <Grid item>
                                  <IconButton
                                    color="error"
                                    onClick={() => {
                                      remove(index);
                                    }}
                                  >
                                    {<DeleteOutlineOutlinedIcon />}
                                  </IconButton>
                                </Grid>
                              )}
                            </Grid>
                          </div>
                        );
                      })}
                    </CommentsWrapper>
                  </NewFormPanel>
                </StepperWidget>
              ) : (
                <StepperWidget
                  steps={['Key Information', 'Employer Profile', 'Keywords', 'Notes/Comments']}
                  isMobileTabletWidth={true}
                  stackStepsTitle
                  disableFormSubmitOrg={disableFormSubmitOrg}
                  isEdit={isEdit!}
                  getValues={getValues}
                  deleteThisRecordId={employer?.id}
                  deleteThisRecordMsg={t('deleteAllJobWarningMsg')}
                  tags={tags}
                  isFormType="employer"
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                  handleSubmit={handleSubmit(onSubmit)}
                  trigger={trigger}
                  formState={formState}
                >
                  <NewFormPanel
                    className="panel-1"
                    ref={refPanel_1}
                    display={activeStep === 0 ? 'block' : 'none'}
                  >
                    <InlineContainer margin="20px 0">
                      <div className="emp-logo-container">
                        <ImgContainer>
                          <FormImgContainer width={windowDimensions.w} height={windowDimensions.h}>
                            <Controller
                              control={control}
                              name="logoUpload"
                              render={({ field }) => (
                                <input //ImgInput
                                  className="employer-logo-input"
                                  type="file"
                                  alt="logo-upload"
                                  accept="image/*"
                                  {...register('logoUpload', {
                                    onChange: (e) => {
                                      showUploadedImg(e);
                                    },
                                  })}
                                />
                              )}
                            />
                            <ImageOverlayComponent
                              imgUrl={imgUrl}
                              clearImg={clearImg}
                              isEdit={isEdit}
                              isMobileTabletView={isMobile || isTablet}
                            />
                          </FormImgContainer>
                        </ImgContainer>
                      </div>
                      <Controller
                        control={control}
                        name="status"
                        render={({ field: { value } }) => (
                          <FormSwitch>
                            <Typography variant="subtitle2" marginLeft="5px">
                              {t('empStatus')}
                            </Typography>
                            <div className="inline-ca grey-stitch">
                              <ReusableToggleComponent
                                toggleValue={value}
                                toggleSelect={() => {
                                  setValue('status', !value);
                                }}
                                firstToggle={'Active'}
                                secondToggle={'Inactive'}
                              />
                            </div>
                          </FormSwitch>
                        )}
                      />
                    </InlineContainer>

                    {/* lead provider | industry | orgName | multiLocation | parentComp | address |   */}

                    <MobileFormFieldContainer>
                      <FormRow
                        height={windowDimensions.h}
                        marginTop="10px"
                        maxHeight="60px"
                        display="block"
                      >
                        <Controller
                          control={control}
                          name="leadProviderId"
                          rules={{
                            required: t('leadProviderReqMsg'),
                            pattern: {
                              value: /^(?!default)\w*/i,
                              message: 'You must select a lead provider',
                            },
                          }}
                          render={({ field: { onChange, value } }) => (
                            <FormTextInput
                              id="outlined-required"
                              label={t('leadProviderLabel')}
                              fullWidth
                              required
                              select
                              disabled={
                                !isEdit
                                  ? false
                                  : isEdit && employer?.leadProviderId === myDetails?.id
                                    ? false
                                    : isEdit && myDetails?.isAdmin
                                      ? false
                                      : true
                              }
                              {...register('leadProviderId')}
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                                isEdit && setModalJobPrompt(true);
                              }}
                            >
                              <MenuItem value="default" disabled selected>
                                {t('selectLeadProv')}
                              </MenuItem>

                              {displaySelectTypeOptions(providerOptions)}
                            </FormTextInput>
                          )}
                        />
                        {errors?.leadProviderId && (
                          <ReqErrorMsg>{errors?.leadProviderId.message}</ReqErrorMsg>
                        )}
                      </FormRow>
                    </MobileFormFieldContainer>

                    <MobileFormFieldContainer>
                      <FormRow
                        height={windowDimensions.h}
                        marginTop="10px"
                        maxHeight="60px"
                        display="block !important"
                      >
                        <ControllerAutoComplete
                          control={control as Control<Partial<GlobalFormData>, any>}
                          name="orgName"
                          placeholder={t('jobInputPlaceholder')}
                          required={true}
                          register={register as UseFormRegister<Partial<GlobalFormData>>}
                          label={t('orgName')}
                          id="outlined-required"
                          optionItems={optionItems.filter(function (item, pos, self) {
                            return self.indexOf(item) == pos;
                          })}
                          rules={{ required: t('enterOrgName') }}
                          throwMessage={t('duplicateOrgFoundMsg')}
                          handleInput={(input?: string) => {
                            if (input) {
                              handleOrgDuplicates(input);
                              setValue('parentCompName', input, { shouldDirty: true });
                            }
                          }}
                        />
                        {errors?.orgName ? (
                          <ReqErrorMsg>{errors?.orgName.message}</ReqErrorMsg>
                        ) : disableFormSubmitOrg ? (
                          <ReqErrorMsg>{t('duplicateOrgFound')}</ReqErrorMsg>
                        ) : null}
                      </FormRow>
                    </MobileFormFieldContainer>

                    <MobileFormFieldContainer>
                      <FormRow
                        height={windowDimensions.h}
                        marginTop="10px"
                        maxHeight="60px"
                        display="block !important"
                      >
                        <Controller
                          control={control}
                          name="multiLocation"
                          render={({ field: { onChange, value } }) => (
                            <FormTextInput
                              id="outlined-required"
                              label={t('multiLocation')}
                              fullWidth
                              select
                              {...register('multiLocation')}
                              value={getValues('multiLocation')}
                              onChange={(e) => {
                                onChange(e);
                                console.log(e.target.value);
                                setIsMultipleLocation(Boolean(e.target.value));
                              }}
                            >
                              <MenuItem value="true" selected>
                                {capitalize(t('yes'))}
                              </MenuItem>
                              <MenuItem value="false" selected>
                                {capitalize(t('no'))}
                              </MenuItem>
                            </FormTextInput>
                          )}
                        />
                      </FormRow>
                    </MobileFormFieldContainer>
                    <MobileFormFieldContainer>
                      <FormRow
                        height={windowDimensions.h}
                        marginTop="10px"
                        maxHeight="60px"
                        display="block !important"
                      >
                        <ControllerAutoComplete
                          className={''}
                          id="outlined-required"
                          control={control as Control<Partial<GlobalFormData>, any>}
                          register={register as UseFormRegister<Partial<GlobalFormData>>}
                          name="parentCompName"
                          rules={{ required: t('mainBranchNameReqMsg') }}
                          required={true}
                          placeholder={t('parentOrgNamePlaceholder')}
                          label={t('parentOrgNameLabel')}
                          optionItems={parentItems.filter(function (item, pos, self) {
                            return self.indexOf(item) == pos;
                          })}
                          throwMessage={t('duplicateParentOrgFoundMsg')}
                          disabled={!getValues('multiLocation')}
                        />
                        {errors?.parentCompName ? (
                          <ReqErrorMsg>{errors?.parentCompName.message}</ReqErrorMsg>
                        ) : null}
                      </FormRow>
                    </MobileFormFieldContainer>
                    <MobileFormFieldContainer>
                      <FormRow
                        height={windowDimensions.h}
                        marginTop="10px"
                        maxHeight="60px"
                        display="block !important"
                        width="100%"
                      >
                        <ControllerTextField
                          control={control}
                          register={register}
                          rules={{
                            required: t('addressReqMsg'),
                            validate: () => {
                              return isAddressValid;
                            },
                          }}
                          name="address"
                          required={true}
                          placeholder={t('address')}
                          className="full-width"
                          id="autocomplete"
                          onClick={() => {
                            setAddressValid((currState) => (currState = false));
                          }}
                        />
                        {errors?.address?.type === 'required' ? (
                          <ReqErrorMsg>{errors?.address.message}</ReqErrorMsg>
                        ) : !isAddressValid ? (
                          <ReqErrorMsg>{t('addressHelperText')}</ReqErrorMsg>
                        ) : null}
                      </FormRow>
                    </MobileFormFieldContainer>
                    <MobileFormFieldContainer>
                      <FormRow
                        height={windowDimensions.h}
                        marginTop="10px"
                        maxHeight="60px"
                        display="block !important"
                      >
                        <Controller
                          control={control}
                          name="industry"
                          rules={{
                            required: t('selectIndutryTextII'),
                            validate: () => {
                              if (getValues('industry') === 'default') {
                                return 'Select an industry.';
                              }
                            },
                          }}
                          render={({ field: { value, onChange } }) => (
                            <FormTextInput
                              label={t('industry')}
                              fullWidth
                              select
                              {...register('industry')}
                              value={value}
                              required={true}
                              onChange={onChange}
                            >
                              <MenuItem value="default" disabled>
                                {t('selectIndustryText')}
                              </MenuItem>
                              {displayStringOptions(industryList)}
                            </FormTextInput>
                          )}
                        />
                        {errors?.industry && <ReqErrorMsg>{errors?.industry.message}</ReqErrorMsg>}
                      </FormRow>
                    </MobileFormFieldContainer>
                  </NewFormPanel>

                  <NewFormPanel
                    className="panel-2"
                    ref={refPanel_2}
                    display={activeStep === 1 ? 'block' : 'none'}
                  >
                    <MobileFormFieldContainer>
                      <FormRow
                        height={windowDimensions.h}
                        marginTop="0px"
                        maxHeight="60px"
                        display="block !important"
                      >
                        <ControllerTextField
                          className="full-width"
                          control={control}
                          register={register}
                          rules={{ required: `Main contact first name required` }}
                          name="pocFirstName"
                          placeholder={t('mcFirstNameLabel')}
                          label={t('mcFirstNameLabel')}
                          required={true}
                          id="outlined-required"
                        />
                        {errors?.pocFirstName && (
                          <ReqErrorMsg>{errors?.pocFirstName.message}</ReqErrorMsg>
                        )}
                      </FormRow>
                    </MobileFormFieldContainer>

                    <MobileFormFieldContainer>
                      <FormRow
                        height={windowDimensions.h}
                        marginTop="0px"
                        maxHeight="60px"
                        display="block !important"
                      >
                        <ControllerTextField
                          className="full-width"
                          control={control}
                          register={register}
                          name="pocLastName"
                          placeholder={t('mcLastNameLabel')}
                          label={t('mcLastNameLabel')}
                          required={false}
                          id="outlined-required"
                        />
                        {errors?.pocLastName && (
                          <ReqErrorMsg>{errors?.pocLastName.message}</ReqErrorMsg>
                        )}
                      </FormRow>
                    </MobileFormFieldContainer>

                    <MobileFormFieldContainer>
                      <FormRow
                        height={windowDimensions.h}
                        marginTop="0px"
                        maxHeight="60px"
                        display="block !important"
                      >
                        <ControllerTextField
                          className=" full-width"
                          control={control}
                          register={register}
                          rules={{
                            required: t('mcPhoneNumberMsg'),
                            maxLength: 35,
                            pattern: {
                              value: regXPhoneNumber,
                              message: t('validPhoneReq'),
                            },
                          }}
                          name="pocPhone"
                          placeholder={t('mcPhoneNumberLabel')}
                          customFunction={formatPhoneNumber}
                          required={true}
                          id="outlined-required"
                        />

                        {
                          <ReqErrorMsg>
                            {errors?.pocPhone?.type === 'required'
                              ? errors?.pocPhone.message
                              : errors?.pocPhone?.type === 'maxLength'
                                ? t('phoneErrorMsg')
                                : errors?.pocPhone?.type === 'pattern'
                                  ? t('phoneHelperText')
                                  : null}
                          </ReqErrorMsg>
                        }
                      </FormRow>
                    </MobileFormFieldContainer>

                    <MobileFormFieldContainer>
                      <FormRow
                        height={windowDimensions.h}
                        marginTop="0px"
                        maxHeight="60px"
                        display="block !important"
                      >
                        <ControllerTextField
                          className="full-width"
                          control={control}
                          register={register}
                          rules={{
                            pattern: {
                              value: regXEmail,
                              message: t('emailErrorMsg'),
                            },
                          }}
                          name="pocEmail"
                          placeholder={t('mcEmailLabel')}
                          required={false}
                          id="outlined-required"
                        />
                        {errors?.pocEmail && <ReqErrorMsg>{errors?.pocEmail.message}</ReqErrorMsg>}
                      </FormRow>
                    </MobileFormFieldContainer>

                    <RichTextFormatWrapper>
                      <InputLabel sx={quillSelectLabel}>{t('description')}</InputLabel>
                      <Controller
                        control={control}
                        name="details"
                        rules={{
                          validate: (value) => {
                            if (value!.length > 3000) {
                              return `${t('descErrorMsg')} (${value!.length}/3000)`;
                            }
                          },
                        }}
                        render={({ field: { onChange, value } }) => (
                          <>
                            <CharCountAlert value={value} />
                            <ReactQuill
                              placeholder={t('employerDetails')}
                              theme="snow"
                              value={value}
                              onChange={onChange}
                            />
                          </>
                        )}
                      />
                      {errors.details?.type === 'validate' ? (
                        <ReqErrorMsg>{errors.details?.message}</ReqErrorMsg>
                      ) : (
                        <FormHelperText sx={{ marginLeft: '10px' }}>
                          {t('thousandMaxCharMsg')}
                        </FormHelperText>
                      )}
                    </RichTextFormatWrapper>
                  </NewFormPanel>

                  <NewFormPanel
                    className="panel-3"
                    ref={refPanel_3}
                    display={activeStep === 2 ? 'block' : 'none'}
                  >
                    <div className="full-width" style={{ marginTop: '20px' }}>
                      <Controller
                        control={control}
                        name="tags"
                        rules={{
                          validate: () => {
                            if (tags.length < 2) {
                              return 'Provide at least two keyword.';
                            }
                          },
                        }}
                        render={({ field: { onChange } }) => (
                          <>
                            <InputLabel sx={reactSelectLabel} required>
                              {t('keywordLabel')}
                            </InputLabel>
                            <Select
                              required
                              closeMenuOnSelect={false}
                              blurInputOnSelect={false}
                              autoFocus={errors.tags !== undefined}
                              placeholder={t('keywordLabel')}
                              styles={reactSelectCustomStyles}
                              options={(keywords.all as unknown as GroupBase<SelectType>[]).filter(
                                (tag: any) => !tags.includes(tag.value)
                              )}
                              isMulti={true}
                              value={tagOptions!}
                              onChange={(e) => {
                                onChange(handleChange(e));
                              }}
                            />
                          </>
                        )}
                      />
                      {errors?.tags ? (
                        <ReqErrorMsg>{errors?.tags.message}</ReqErrorMsg>
                      ) : (
                        <FormHelperText sx={{ marginLeft: '15px' }}>
                          {t('keywordPlaceholder')}
                        </FormHelperText>
                      )}
                      <br></br>

                      <SubmitKeywordSuggestions
                        labelNoun={t('keywords').toLowerCase()}
                        displayModal={setDisplayKeywordSubmit}
                      />

                      <MobileFormFieldContainer display="none">
                        <FormRow
                          height={windowDimensions.h}
                          marginTop="35px"
                          maxHeight="60px"
                          display="block !important"
                        >
                          <ControllerTextField
                            control={control}
                            register={register}
                            name="reqSkills"
                            placeholder={t('reqSkillsPlaceholder')}
                            label={t('reqSkills')}
                            className="full-width"
                          />
                          {errors?.reqSkills && (
                            <ReqErrorMsg>{errors?.reqSkills.message}</ReqErrorMsg>
                          )}
                        </FormRow>
                      </MobileFormFieldContainer>
                    </div>
                  </NewFormPanel>

                  <NewFormPanel
                    className="panel-4"
                    ref={refPanel_4}
                    display={activeStep === 3 ? 'block' : 'none'}
                  >
                    <CommentsWrapper>
                      <InlineContainer className="full-width blue-stitch" marginTop="20px">
                        <Button
                          type="button"
                          variant="contained"
                          sx={{
                            background: 'var(--primary-color)',
                            '&:hover': {
                              background: 'var(--background)',
                              color: 'var(--primary-color)',
                              boxShadow: 'none',
                              border: '1px solid var(--primary-color)',
                            },
                          }}
                          onClick={() => {
                            prepend({
                              body: '',
                              ts: dayjs().format('MM/DD/YY'),
                              providerName: myDetails.firstName + ' ' + myDetails.lastName,
                              isNew: true,
                              activityType: null,
                            });
                          }}
                        >
                          + {t('notesComments')}
                        </Button>
                      </InlineContainer>
                      {fields.map(({ id }, index) => {
                        return (
                          <StackContents className="employment-record-item" key={`${id}-${index}`}>
                            <Box className="employer-note-cont">
                              <Controller
                                control={control}
                                name={`notes.${index}.body` as const}
                                rules={{
                                  validate: (value) => {
                                    if (value!.length > 1000) {
                                      return `${t('notesErrorMsg')} (${value!.length}/1000)`;
                                    }
                                  },
                                }}
                                render={({ field: { onChange, value } }) => (
                                  <FormTextInput
                                    {...register(`notes.${index}.body`)}
                                    id="outlined-multiline-static"
                                    sx={{
                                      ...formDescriptionStyling,
                                      height: '120px !important',
                                      '.MuiInputBase-input': { padding: '0 10px' },
                                    }}
                                    fullWidth
                                    label={t('notesComments')}
                                    multiline
                                    rows={4}
                                    value={value}
                                    onChange={onChange}
                                    disabled={
                                      getValues(`notes.${index}.providerName`) !==
                                      `${myDetails?.firstName} ${myDetails?.lastName}`
                                    }
                                    error={!!errors.notes?.[index]?.body?.message}
                                    helperText={
                                      errors.notes?.[index]?.body?.type === 'validate'
                                        ? errors.notes?.[index]?.body?.message
                                        : t('thousandMaxCharMsg')
                                    }
                                  />
                                )}
                              />
                              <FormFieldContainer className="full-width">
                                <Controller
                                  control={control}
                                  name={`notes.${index}.activityType`}
                                  render={({ field: { onChange, value } }) => (
                                    <FormTextInput
                                      id="outlined-required"
                                      label={t('activityType')}
                                      fullWidth
                                      select
                                      value={value}
                                      onChange={onChange}
                                    >
                                      <MenuItem value="initialContract">
                                        {t('initialContract')}
                                      </MenuItem>
                                      <MenuItem value="followUpContract">
                                        {t('followUpContract')}
                                      </MenuItem>
                                      <MenuItem value="presentation">{t('presentation')}</MenuItem>
                                      <MenuItem value="jobPostingNew">
                                        {t('jobPostingNew')}
                                      </MenuItem>
                                      <MenuItem value="jobPostingRenew">
                                        {t('jobPostingRenew')}
                                      </MenuItem>
                                    </FormTextInput>
                                  )}
                                />
                              </FormFieldContainer>
                              <InlineContainer>
                                <InlineContainer
                                  className="disabled-note-props"
                                  justifyContent="flex-start"
                                  alignContent={'baseline'}
                                  marginTop="10px"
                                >
                                  <Typography variant="caption" padding="0 10px" width="80px">
                                    Created by:
                                  </Typography>
                                  <Controller
                                    control={control}
                                    name={`notes.${index}.providerName` as const}
                                    render={({ field: { onChange, value } }) => (
                                      <FormTextInput
                                        {...register(`notes.${index}.providerName`)}
                                        id="standard-basic"
                                        disabled
                                        InputProps={{ disableUnderline: true }}
                                        variant="standard"
                                        sx={{
                                          '.MuiInputBase-input': {
                                            textAlign: 'left',
                                            width: 'fit-content',
                                          },
                                          '.MuiInputBase-root': { width: '90px' },
                                        }}
                                        value={
                                          value || `${myDetails?.firstName} ${myDetails?.lastName}`
                                        }
                                        onChange={onChange}
                                      />
                                    )}
                                  />
                                  <Box width="28%">
                                    <Controller
                                      control={control}
                                      name={`notes.${index}.ts` as const}
                                      render={({ field: { onChange } }) => (
                                        <FormTextInput
                                          {...register(`notes.${index}.ts`)}
                                          id="standard-basic"
                                          disabled
                                          InputProps={{ disableUnderline: true }}
                                          variant="standard"
                                          sx={{
                                            '.MuiInputBase-input': {
                                              textAlign: 'left',
                                              width: '50px',
                                            },
                                          }}
                                          value={dayjs().format('MM/DD/YYYY')}
                                          onChange={onChange}
                                        />
                                      )}
                                    />
                                  </Box>
                                  <Box className="meat-balls" marginLeft="auto">
                                    <IconButton
                                      color="error"
                                      onClick={() => {
                                        remove(index);
                                      }}
                                    >
                                      {<DeleteOutlineOutlinedIcon />}
                                    </IconButton>
                                  </Box>
                                </InlineContainer>
                              </InlineContainer>
                            </Box>
                          </StackContents>
                        );
                      })}
                    </CommentsWrapper>
                  </NewFormPanel>
                </StepperWidget>
              )}
            </NewFormContainer>

            {/* conditional popup components */}
            {hideFormOverlay && <HideFormOverlayComponent noun={t('savingEmployer')} />}
            {displaySuccessModal && (
              <SuccessComponent noun={t('employer')} onAcknowledged={navigateHandler} />
            )}
            {modalJobPrompt && (
              <Modal onHide={() => {}}>
                <DeleteOverlay
                  className="responsive-del-overlay"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  top={windowDimensions.h}
                  right={windowDimensions.w}
                >
                  <SelectAnOptionPopupComponent
                    msg={t('assignLeadToJobMsg')}
                    confirmAction={() => {
                      setUpdateJobsWithProvider(true);
                      setModalJobPrompt(false);
                    }}
                    goBack={() => {
                      setModalJobPrompt(false);
                    }}
                  />
                </DeleteOverlay>
              </Modal>
            )}
            {displayKeywordSubmit && (
              <Modal
                onHide={() => {
                  setDisplayKeywordSubmit(false);
                }}
              >
                <KeywordSubmitOverlay
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  top={windowDimensions.h}
                  right={windowDimensions.w}
                >
                  <PopupMessageComponent
                    className="keyword-submit-popup"
                    icon={Icon.SwooshGif}
                    message={
                      'Your submission has been recieved, keep an eye out in the weeks to come!'
                    }
                    closeModal={() => {
                      setDisplayKeywordSubmit(false);
                    }}
                    overrideButtonText={'Go Back'}
                  />
                </KeywordSubmitOverlay>
              </Modal>
            )}
          </form>
        </FormComponentWrapper>
      </>

      {hasOpenJobs && (
        <Modal
          onHide={() => {
            setHasOpenjobs(false);
          }}
        >
          <CloseAllJobsOverlay
            onClick={(e) => {
              e.stopPropagation();
            }}
            top={windowDimensions.h}
            right={windowDimensions.w}
          >
            <PopupMessageComponent
              className="open-jobs-popup"
              message={t('closeAllJobsMsgPrompt')}
              icon={Icon.JobSearchIcon}
              closeModal={() => {
                setHasOpenjobs(false);
              }}
            />
          </CloseAllJobsOverlay>
        </Modal>
      )}
    </>
  );
};

export default EmployersCreateComponent;

export const EmployerCreateHeader = () => {
  const t = useTranslate();
  return <PageTitleComponent greeting={t('addEmployerBtn')} />;
};

export const EmployerEditHeader = () => {
  const t = useTranslate();
  return <PageTitleComponent greeting={t('employerViewEditBtn')} />;
};

// TODO: create a custom TS component for these props so you can register multiple FormData types. Right now, this is repeat code per form.
interface PropsControllerTextField {
  register: UseFormRegister<EmployerFormData>;
  customFunction?: (e: string) => void;
  control: Control<EmployerFormData, any>;
  name: any;
  rules?: {
    required?: boolean | string;
    pattern?: { value: RegExp; message: string };
    maxLength?: number;
    validate?: () => boolean;
  };
  required?: boolean;
  placeholder: string;
  className: string;
  id?: string;
  label?: string;
  focused?: boolean;
  helperText?: string;
  onClick?: () => void;
}

const ControllerTextField = (props: PropsControllerTextField) => {
  const {
    control,
    name,
    placeholder,
    rules,
    required,
    register,
    className,
    customFunction,
    label,
    focused,
    id,
    helperText,
    onClick,
  } = props;
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, value } }) => (
        <FormTextInput
          {...register(name)}
          id={id ? id : 'outlined-required'}
          className={className + ' global-style'}
          type="text"
          placeholder={placeholder}
          label={label ? label : placeholder}
          value={value || ''}
          autoComplete="off"
          required={required}
          autoFocus={focused ? true : false}
          helperText={helperText ? helperText : null}
          onChange={(e) => {
            if (customFunction) {
              onChange(customFunction(e.target.value));
            } else {
              onChange(e.target.value);
            }
          }}
          onClick={onClick && onClick}
        />
      )}
    />
  );
};

interface PropsControllerCheckbox {
  control: Control<EmployerFormData, any>;
  name: any;
  register: UseFormRegister<EmployerFormData>;
  setValue: UseFormSetValue<EmployerFormData>;
  setDisableInput: React.Dispatch<React.SetStateAction<boolean>>;
}

const ControllerCheckBox = (props: PropsControllerCheckbox) => {
  const { control, register, name, setValue, setDisableInput } = props;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, name } }) => (
        <Checkbox
          {...register(name)}
          onChange={(e) => {
            onChange(e.target.checked);
            setValue(name, e.target.checked);
            setDisableInput((currState: boolean) => (currState = e.target.checked));
            console.log(e.target.checked);
          }}
          checked={value}
          disableRipple={true}
        />
      )}
    />
  );
};

const ImageOverlayComponent = (props: {
  imgUrl: string;
  clearImg: any;
  isEdit: boolean;
  isMobileTabletView: boolean;
}) => {
  const { imgUrl, clearImg, isEdit, isMobileTabletView } = props;
  const t = useTranslate();
  return (
    <>
      <div className="ImgDisplay">
        <div className="LoadedImgCont">
          {!isEdit &&
            (imgUrl ? (
              <img className="loaded-img" src={imgUrl} alt="uploaded-img" />
            ) : (
              <>
                {isMobileTabletView ? (
                  <FileUploadRoundedIcon />
                ) : (
                  <img className="no-img" src={Icon.AddImage} alt="add-img" />
                )}
                <Typography variant="caption">{t('addImg')}</Typography>
              </>
            ))}
          {isEdit &&
            (imgUrl !== 'employer-default.png' && imgUrl !== '' ? (
              <img className="loaded-img" src={imgUrl} alt="edit-uploaded-img" />
            ) : isMobileTabletView ? (
              <img className="no-img" src={Icon.AddImage} alt="add-img" />
            ) : (
              <>
                <img className="no-img" src={Icon.AddImage} alt="add-img" />
                <Typography variant="body1">{t('addImg')}</Typography>
              </>
            ))}
        </div>
      </div>
      <div className="remove-img-wrapper">
        {imgUrl !== '' && (
          <IconButton color="inherit" disableRipple onClick={clearImg}>
            {<DeleteOutlineOutlinedIcon />}
          </IconButton>
        )}
      </div>
    </>
  );
};
