import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';

interface ToggleProps {
  toggleValue?: boolean;
  toggleSelect: (toggleValue: boolean) => void;
  firstToggle: string;
  firstIcon?: string;
  secondToggle: string;
  secondIcon?: string;
}

const ReusableToggleComponent = (props: ToggleProps) => {
  const { toggleValue, firstToggle, secondToggle, firstIcon, secondIcon } = props;

  const handleAlignment = (event: React.MouseEvent<HTMLElement>, toggleValue: boolean) => {
    props.toggleSelect(toggleValue);
    console.log('change detected!');
  };

  React.useEffect(() => {}, [toggleValue]);

  return (
    <ToggleButtonGroup
      value={props.toggleValue}
      exclusive
      onChange={handleAlignment}
      aria-label="text alignment"
    >
      <ToggleButton
        value={true}
        aria-label="left aligned"
        sx={{
          width: 'max-content',
          backgroundColor: toggleValue ? '#0379a0 !important' : null,
          padding: '2px 10px',
        }}
      >
        {firstIcon && (
          <img style={{ marginRight: '5px' }} src={firstIcon} alt="privacy-badge"></img>
        )}{' '}
        <Typography color={toggleValue ? 'white !important' : 'lightgrey'}>
          {firstToggle}
        </Typography>
      </ToggleButton>
      <ToggleButton
        value={false}
        aria-label="centered"
        sx={{
          width: 'max-content',
          backgroundColor: !toggleValue ? '#0379a0 !important' : null,
          padding: '2px 10px',
        }}
      >
        {secondIcon && (
          <img style={{ marginRight: '5px' }} src={secondIcon} alt="privacy-badge"></img>
        )}{' '}
        <Typography color={!toggleValue ? 'white !important' : 'lightgrey'}>
          {secondToggle}
        </Typography>
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default ReusableToggleComponent;
