import { Button, Typography } from '@mui/material';

import { createTheme, ThemeProvider } from '@mui/material';
import useDetectResize from '../../hooks/useDetectResize';
import { Box, breakpoints } from '@mui/system';
import {
  ToggleFilterButton,
  StickyRecoFilterContainer,
} from '../UI/StyledComponents/RecommendationStyledComponent';

interface PropsFilterOptions {
  userType: string;
  searchState: string;
  setQueryType: (query: string) => void;
}

const RecommendationFilterOptions = (props: PropsFilterOptions) => {
  const { userType, searchState, setQueryType } = props;
  const { isMobile, isTablet } = useDetectResize();

  return (
    <StickyRecoFilterContainer className="sticky-recom-toggle-rec">
      <Box className="recomm-toggle-container inline-btwn">
        <ToggleFilterButton
          id="location-filter-tag"
          variant={searchState === 'location' ? 'contained' : 'text'}
          size="small"
          onClick={() => {
            setQueryType('location');
          }}
        >
          <Typography
            variant="body1"
            id="location-filter-tag"
            className="text-overflow reco-filter-text"
          >
            {isMobile || isTablet
              ? 'Nearest'
              : `Nearest ${
                  userType === 'individualsToJob' || userType === 'individualsToEmployer'
                    ? 'Individuals'
                    : userType === 'job'
                    ? 'Jobs'
                    : 'Employers'
                }`}
          </Typography>
        </ToggleFilterButton>
        <ToggleFilterButton
          variant={searchState === 'aligned' ? 'contained' : 'text'}
          size="small"
          onClick={() => {
            setQueryType('aligned');
          }}
        >
          <Typography
            variant="body1"
            id="aligned-filter-tag"
            className="text-overflow reco-filter-text"
          >
            {isMobile || isTablet
              ? 'Aligned'
              : `Most Aligned ${
                  userType === 'individualsToJob' || userType === 'individualsToEmployer'
                    ? 'Individuals'
                    : userType === 'job'
                    ? 'Jobs'
                    : 'Employers'
                }`}
          </Typography>
        </ToggleFilterButton>
        <ToggleFilterButton
          variant={searchState === 'cumulative' ? 'contained' : 'text'}
          size="small"
          onClick={() => {
            setQueryType('cumulative');
          }}
        >
          <Typography
            variant="body1"
            id="cumulative-filter-tag"
            className="text-overflow reco-filter-text"
          >
            Cumulative
          </Typography>
        </ToggleFilterButton>
      </Box>
    </StickyRecoFilterContainer>
  );
};

export default RecommendationFilterOptions;
