import { useSelector } from 'react-redux';
import { stringAvatar } from '../../helpers/helperFunctions';
import useTranslate from '../../hooks/useTranslate';
import { RootState } from '../../store';
import { getOrgDetails } from '../../store/providerSlice';
import { Avatar, Box, Typography } from '@mui/material';
import { InlineContainer } from '../UI/StyledComponents/GlobalStyledComponents';
import { CustomToolTip, QuickCopyComponent } from '../SharedComponents/QuickCopyComponent';
import { useEffect } from 'react';

const EmploymentSupportLeadDetails = (props: {
  provider?: any;
  isVisible?: boolean;
  photo?: string;
  orgId?: string;
}) => {
  const { provider, isVisible, photo, orgId } = props;
  const organization = useSelector((state: RootState) => getOrgDetails(state, orgId || ''));
  const t = useTranslate();

  useEffect(() => {
    console.warn(provider);
  }, [provider]);

  return (
    <>
      {isVisible ? (
        <Box className="es-details-cont" width="100%">
          <Box>
            <Typography variant="h5" marginBottom="5px !important">
              {t('providerCardTitle')}
            </Typography>
            <Box className="es-img-info">
              {photo?.startsWith('https') ? (
                <img src={photo} alt="ofProvider" />
              ) : (
                <Avatar {...stringAvatar(provider?.name)} />
              )}
              <Box className="provider-details">
                <CustomToolTip placement="right-start" title={organization?.orgDetails!}>
                  <Typography variant="body1" sx={{ cursor: 'pointer' }}>
                    {organization?.orgName!}
                  </Typography>
                </CustomToolTip>
                <InlineContainer>
                  <Typography variant="body1">
                    {provider?.name!} - {provider?.email!}
                  </Typography>
                  <QuickCopyComponent content={provider?.email!} />
                </InlineContainer>
                <Typography variant="body1">{provider?.phone!}</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box className="details-provider-cover"></Box>
      )}
    </>
  );
};

export default EmploymentSupportLeadDetails;
