import { Avatar, Typography } from '@mui/material';
import { Box } from '@mui/system';
import useDetectResize from '../../hooks/useDetectResize';
import { stringAvatar } from '../../helpers/helperFunctions';
import dayjs from 'dayjs';

interface IndividualMessagesProps {
  individualName: string;
  timestamp: string;
  message: string;
}
export const IndividualMessages = ({
  individualName,
  timestamp,
  message,
}: IndividualMessagesProps) => {
  const { isMobileOrTablet } = useDetectResize();

  return (
    <Box
      display={'flex'}
      flexDirection={'row'}
      columnGap={isMobileOrTablet ? '0rem' : '0.25rem'}
      alignItems={'end'}
    >
      <Avatar {...stringAvatar(individualName)} />
      <Box
        borderRadius={'8px'}
        padding={'1rem'}
        minWidth={isMobileOrTablet ? '45%' : '40%'}
        maxWidth={isMobileOrTablet ? '65%' : '45%'}
        boxShadow={5}
      >
        <Box
          display={'flex'}
          flexDirection={isMobileOrTablet ? 'column' : 'row'}
          paddingBottom={'0.5rem'}
        >
          <Typography variant="body1" fontWeight={'bold'} flex={1}>
            {individualName}
          </Typography>
          <Typography variant="body1" fontWeight={'bold'}>
            {dayjs(timestamp).format('MMM D, YYYY, h:mm A')}
          </Typography>
        </Box>
        <Typography variant="body1" style={{ wordWrap: 'break-word' }}>
          {message}
        </Typography>
      </Box>
    </Box>
  );
};
