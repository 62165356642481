import { Box, Button, Select, Stepper, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { InlineContainer, StyledProps } from './GlobalStyledComponents';

export const FormComponentWrapper = styled(Box)(({ theme }) => ({
  width: '-webkit-fill-available',
  maxWidth: '1366px',
  position: 'relative',

  [theme.breakpoints.down('md')]: {
    height: '90vh',
    overflow: 'hidden',
  },
  '@media (max-width: 1080px) and (min-height: 700px)': {
    padding: '0em 1em!important',
  },

  [theme.breakpoints.up('md')]: {
    height: '76vh',
    padding: '1em',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  [theme.breakpoints.up('xs')]: {},
}));

export const SaveButton = styled(Button)(({ theme, variant }) => ({
  textTransform: 'initial',
  marginRight: '0px',
  ...(variant === 'contained' && {
    '&:disabled': {
      backgroundColor: 'gray !important',
      color: 'white',
    },
  }),
  [theme.breakpoints.up('md')]: {
    marginRight: '10px',
  },
  [theme.breakpoints.down('md')]: {
    padding: '6px 14px',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '6px 14px',
  },
}));

export const ButtonOutlined = styled(SaveButton)({
  border: `1px solid #0F1177`,
  borderRadius: '16px',
  backgroundColor: 'white',
  color: '#050505',
  fontWeight: 600,
  padding: '10px',
  '@media (min-width: 1025px) and (max-width: 1440px)': {
    padding: '10px 15px',
    fontSize: '0.775rem',
  },
  '&:hover': {
    backgroundColor: '#69697a17',
    color: '#0f1177',
    border: '1px solid #0f1177',
  },
});

export const SaveButtonContained = styled(SaveButton)({
  backgroundColor: '#0F1177',
  color: '#E4F2F6',
  fontWeight: 600,
  '&:hover': {},
});

export const FormContainer = styled(Box)(({ theme }) => ({
  height: '100%',
  maxHeight: '80vh',
}));

export const NewFormContainer = styled(Box)(({ theme }) => ({
  display: 'inline-grid',

  gridTemplateRows: '18% 70% 10%',
  gridTemplateColumns: '100%',

  alignItems: 'flex-start',

  height: '100%',
  width: '-webkit-fill-available',

  [theme.breakpoints.down('md')]: {
    '@media (min-height: 500px)': { gridTemplateRows: '18% 70% 10%' },
    '@media (min-height: 550px)': { gridTemplateRows: '16% 72.5% 10%' },
    '@media (min-height: 650px)': { gridTemplateRows: '14% 75% 10%' },
    '@media (min-height: 750px)': { gridTemplateRows: '12% 77.5% 10%' },
    '@media (min-height: 850px)': { gridTemplateRows: '10% 80% 10%' },
    '@media (min-height: 950px)': { gridTemplateRows: '11% 79% 10%' },
    '@media (min-height: 1000px)': { gridTemplateRows: '15% 75% 10%' },
    '@media (min-height: 1050px)': { gridTemplateRows: '13% 77% 10%' },
  },
  [theme.breakpoints.up('md')]: {
    maxHeight: '850px',
    '@media (min-height: 650px)': { gridTemplateRows: '20% 71% 10%' },
    '@media (min-height: 750px)': { gridTemplateRows: '17.5% 72.5% 10%' },
    '@media (min-height: 850px)': { gridTemplateRows: '18% 72% 10%' },
  },
}));

// export const PanelAlignment = styled(Box)(({height}) => ({
//   height: `calc(${height}px * 0.50)`,
//   '@media (min-height: 500px)'  : {height: `calc(${height}px * 0.505)`},
//   '@media (min-height: 550px)'  : {height: `calc(${height}px * 0.545)`},
//   '@media (min-height: 600px)'  : {height: `calc(${height}px * 0.585)`},
//   '@media (min-height: 750px)'  : {height: `calc(${height}px * 0.625)`},
//   '@media (min-height: 850px)'  : {height: `calc(${height}px * 0.645)`},
//   '@media (min-height: 950px)'  : {height: `calc(${height}px * 0.63)`},
//   '@media (min-height: 1000px)'  : {height: `calc(${height}px * 0.60)`},
// }));

export const HorizontalScrollContainer = styled(Box)(({ theme, height }) => ({
  borderRadius: '0px',
  borderTop: 'none',
  overflowX: 'hidden',
  overflowY: 'scroll',
  scrollBehavior: 'smooth',
  position: 'relative',
  height: `calc(${height}px * 0.58)`,

  [`&::-webkit-scrollbar`]: {
    width: '0px',
    background: 'transparent',
    height: '0px',
  },

  [theme.breakpoints.up('xs')]: {
    '@media (max-height: 700px)': {
      height: `calc(${height}px * 0.56)`,
    },
    '@media (min-height: 700px)': {
      height: `calc(${height}px * 0.58)`,
    },

    '@media (min-height: 800px)': {
      height: `calc(${height}px * 0.66)`,
    },
  },

  [theme.breakpoints.up('sm')]: {
    '@media (min-height: 550px)': {
      height: `calc(${height}px * 0.5)`,
    },
    '@media (min-height: 700px)': {
      height: `calc(${height}px * 0.54)`,
    },
    '@media (min-height: 750px)': {
      height: `calc(${height}px * 0.56)`,
    },
    '@media (min-height: 790px)': {
      height: `calc(${height}px * 0.57)`,
    },
    '@media (min-height: 1000px)': {
      height: `calc(${height}px * 0.65)`,
    },
    '@media (min-height: 1100px)': {
      height: `calc(${height}px * 0.67)`,
    },
  },
  [theme.breakpoints.up('md')]: {
    border: '2px solid #D9E1E7',
  },
  [theme.breakpoints.up('xl')]: {
    '@media (min-height: 1090px)': {
      maxHeight: '700px',
    },
  },
}));

export const PanelCarousel = styled(Box)(({ theme, height }) => ({
  display: 'inline-flex',
  height: '100%',
  overflowX: 'hidden',
  scrollSnapType: 'x mandatory',
  [theme.breakpoints.up('xs')]: {
    '@media (max-height: 700px)': {
      height: `calc(${height}px * 0.56)`,
    },

    '@media (min-height: 800px)': {
      height: `calc(${height}px * 0.66)`,
    },
  },
  [theme.breakpoints.up('md')]: {
    height: 'inherit',
  },
}));

export const NewFormPanel = styled(Box)(({ theme, height }) => ({
  height: 'inherit',
  padding: '15px',
  border: '2px solid #D9E1E7',
  overflow: 'auto',
  touchAction: 'pan-y',

  [`&::-webkit-scrollbar`]: {
    width: '10.5px',
    height: '0px',
  },

  [`&::-webkit-scrollbar-track`]: {
    background: '#ededed',
    borderRadius: '15px',
  },

  [`&::-webkit-scrollbar-thumb`]: {
    background: '#888',
    borderRadius: '15px',
  },

  [`&::-webkit-scrollbar-thumb:hover`]: {
    background: '#555',
  },

  [`&::-webkit-scrollbar-button:end`]: {
    height: '5px',
    width: '30%',
  },
  [`&::-webkit-scrollbar-button:start`]: {
    height: '5px',
    width: '30%',
  },
}));

export const FormPanel = styled(Box)(({ theme, height }) => ({
  height: 'inherit',
  padding: '15px',
  border: '2px solid #D9E1E7',
  overflow: 'auto',
  touchAction: 'pan-y',

  [`&::-webkit-scrollbar`]: {
    width: '10.5px',
    height: '0px',
  },

  [`&::-webkit-scrollbar-track`]: {
    background: '#ededed',
    borderRadius: '15px',
  },

  [`&::-webkit-scrollbar-thumb`]: {
    background: '#888',
    borderRadius: '15px',
  },

  [`&::-webkit-scrollbar-thumb:hover`]: {
    background: '#555',
  },

  [`&::-webkit-scrollbar-button:end`]: {
    height: '5px',
    width: '30%',
  },
  [`&::-webkit-scrollbar-button:start`]: {
    height: '5px',
    width: '30%',
  },
}));

export const FormStepperModal = styled(Stepper)(({ theme }) => ({}));

export const PanelDescriptor = styled(Box)(({ theme, display }) => ({
  height: 'min-content',
  display: `${display}`,
  alignItems: 'center',
}));

export const FormStepperContainer = styled(InlineContainer)(({ theme, border }) => ({
  display: 'inline-grid',
  width: '100%',
  height: '100%',

  padding: '5px',

  border: 'none',
  borderRadius: '16px 16px 0px 0px;',
  borderBottom: '2px solid #D9E1E7',

  [theme.breakpoints.up('md')]: {
    border: '2px solid #D9E1E7',
    borderBottom: 'none',
    padding: '10px',
  },
}));

export const RecommendationsInlineContainer = styled(InlineContainer)(({ theme }) => ({
  maxWidth: '1740px',
  height: '18vh',
  maxHeight: '150px',
  display: 'inline-flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  [theme.breakpoints.up('md')]: {
    width: '97% ',
  },

  [theme.breakpoints.up('lg')]: {
    width: '100%',
  },
}));

export const FormSubmitContainer = styled(Box)(({ theme }) => ({
  width: 'inherit',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderRadius: '0px 0px 10px 10px',
  padding: '10px 10px',
  border: 'none',
  borderTop: '2px solid #D9E1E7',

  [theme.breakpoints.up('md')]: {
    border: '2px solid #D9E1E7',
    borderTop: 'none',
  },
  [theme.breakpoints.down('md')]: {
    paddingTop: '25px',
  },
  '@media (max-width: 370px)': {
    padding: '30px 10px',
  },
}));

export const FormFieldContainer = styled(Box)(({ theme, display, minHeight }) => ({
  display: `${display || 'grid'}`,
  gridTemplateRows: 'min-content',
  marginBottom: '10px',
  marginTop: '20px',

  [theme.breakpoints.up('xs')]: {},

  [theme.breakpoints.down('md')]: {},
  [theme.breakpoints.up('md')]: {
    minHeight: `${minHeight || '100px'}`,
    marginTop: '5px',
  },

  [theme.breakpoints.up('lg')]: {
    minHeight: `${minHeight || '100px'}`,
    '@media (min-resolution: 1.25dppx)': {
      height: 'fit-content',
      maxHeight: 'fit-content',
      width: '80%',
      minHeight: '50px',
    },
  },
}));
export const KeyWordFFContainer = styled(FormFieldContainer)(({ theme, display, minHeight }) => ({
  [theme.breakpoints.up('md')]: {
    '@media (min-resolution: 1.25dppx)': {
      height: 'fit-content',
      minHeight: '50px',
      maxHeight: '205px',
      marginBottom: '0px',
    },
  },
}));

export const NewFormFieldContainer = styled(Box)(({ theme, display, minHeight }) => ({
  display: `${display || 'grid'}`,
  gridTemplateRows: 'min-content',
  gridTemplateColumns: '100%',

  marginBottom: '10px',
  marginTop: '20px',

  [theme.breakpoints.up('md')]: {
    minHeight: `${minHeight || '100px'}`,
    marginTop: '5px',
  },

  [theme.breakpoints.up('lg')]: {
    minHeight: `${minHeight || '100px'}`,
    '@media (min-resolution: 1.25dppx)': {
      minHeight: '50px',
    },
  },
}));

export const MobileFormFieldContainer = styled(NewFormFieldContainer)(({ theme, margin }) => ({
  width: '100%',
  marginTop: '0px',
}));

export const FormFieldCheckBoxContainer = styled(FormFieldContainer)(() => ({
  minHeight: 'fit-content',
  marginRight: 'auto',
  paddingLeft: '30px',
}));

export const FormTextAreaInput = styled(TextField)<StyledProps>(({ background }) => ({
  '& .MuiFormLabel-root': {
    padding: '3px',
    background: `${background || 'white'}`,
    top: '-3px',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '15px',
    fontWeight: '600',
    fontFamily: 'Nunito',
    padding: '5px 10px',
    margin: '0',
  },
}));

//Standard Textfield Form inputs, heights should sync with CustomMuiSelect
export const FormTextInput = styled(TextField)<StyledProps>(({ theme, background }) => ({
  '& .MuiFormLabel-root': {
    padding: '3px',
    background: `${background || 'white'}`,
    top: '-3px',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '15px',
    fontWeight: '600',
    fontFamily: 'Nunito',
    padding: '5px 10px',
    margin: '0',

    [theme.breakpoints.down('md')]: {
      height: '45px',
    },
    [theme.breakpoints.up('md')]: {
      height: '45px',
    },

    [theme.breakpoints.up('lg')]: {
      width: '100%',
      '@media (min-resolution: 1.25dppx)': {
        height: '45px !important',
        width: '90% !important',
      },
    },
  },
}));

//Form drop down select inputs, should have same heights as FormTextInput
export const CustomMuiSelect = styled(Select)(({ theme }) => ({
  borderRadius: '15px',
  fontWeight: '600',
  fontFamily: 'Nunito',
  height: '45px',
  width: '100%',

  [theme.breakpoints.down('md')]: {},
  [theme.breakpoints.up('md')]: {
    height: '45px',
  },
  [theme.breakpoints.up('lg')]: {
    width: '100%',
    '@media (min-resolution: 1.25dppx)': {
      height: '45px !important',
      width: '90% !important',
    },
  },
}));

//form row shoulud be 100px if label is below input (especially for 1.25 dpppx)
export const FormRow = styled(Box)(
  ({ theme, alignItems, justifyContent, height, marginTop, maxHeight, display }) => ({
    display: `${display || 'inline-flex'}`,
    justifyContent: `${justifyContent || 'space-between'}`,
    alignItems: `${alignItems || 'flex-start'}`,

    width: '100%',
    height: 'fit-content',

    minHeight: '65px',
    maxHeight: `${maxHeight || '85px'}`,

    marginTop: `${marginTop || '10px'}`,

    [theme.breakpoints.down('md')]: {
      maxHeight: `${maxHeight || 'unset'}`,
    },

    [theme.breakpoints.up('lg')]: {
      '@media (min-resolution: 1.25dppx)': {
        marginTop: '10px',
      },
    },
  })
);

export const TagFormRow = styled(FormRow)(
  ({ theme, alignItems, justifyContent, height, marginTop, maxHeight }) => ({
    maxHeight: 'fit-content !important',
    height: 'unset',
    [theme.breakpoints.up('lg')]: {
      '@media (min-resolution: 1.25dppx)': {
        minHeight: `calc(${height}px * 0.20)`,
        marginTop: '10px',
      },
    },
  })
);

export const FormImgContainer = styled(Box)(({ theme, width, height }) => ({
  position: 'relative',

  [theme.breakpoints.up('xs')]: {
    width: `calc(${width}px * 0.35)`,
    height: `calc(${height}px * 0.15)`,
  },

  [theme.breakpoints.up('lg')]: {
    width: `calc(${width}px * 0.15)`,
    height: `calc(${height}px * 0.18)`,

    '@media (min-resolution: 1.25dppx)': {},
    '@media (min-resolution: 1.5dppx)': {},
  },
}));

export const FormSwitch = styled(Box)(({ theme }) => ({
  width: 'fit-content',
  margin: '10px 0px',
  position: 'relative',
  fontFamily: `LexendDeca`,

  '& > h5': {
    position: 'absolute',
    top: '-8px',
    left: '15px',
    color: 'rgba(0, 0, 0, 0.6)',
  },

  [theme.breakpoints.up('lg')]: {
    margin: 0,

    '@media (min-resolution: 1.25dppx)': {},
  },
}));

export const RichTextFormatWrapper = styled(Box)(({ theme }) => ({
  width: '100%',

  // '@media (min-resolution: 1.25dppx)' : {
  //   width: '95%'
  // }
}));

export const CommentsWrapper = styled(Box)(({ theme }) => ({
  width: '99%',
  borderRadius: '15px',
  padding: '0px',
  marginLeft: 'auto',
  marginRight: 'auto',

  [theme.breakpoints.up('md')]: {
    padding: '20px',
  },
  [theme.breakpoints.down('md')]: {},
}));

export const ProfileSettingsBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'inline-grid',
    gridTemplateRows: '275px 210px 90px 80px',
    justifyItems: 'center',
    width: '98%',
    height: '80vh',
    padding: '10px',

    '& > div': {
      height: '100%',
    },

    '@media (min-height: 500px)': {
      height: `50vh`,
    },
    '@media (min-height: 650px)': {
      height: `55vh`,
    },
    '@media (min-height: 800px)': {
      height: `45vh`,
    },
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateRows: '255px 210px 90px 80px',
  },
  [theme.breakpoints.up('md')]: {
    display: 'block',
    width: '50%',
    maxWidth: '450px',
    // height: '300px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

export const ImgContainer = styled(Box)(({ theme }) => ({
  width: '100%',
}));

export const ProfImgWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',

  [theme.breakpoints.down('sm')]: {
    width: '65px',
    height: '85px',
  },
  [theme.breakpoints.down('md')]: {
    width: '100px',
    height: '100px',
  },
  [theme.breakpoints.up('md')]: {
    width: '80px',
    height: '80px',
  },
  [theme.breakpoints.up('lg')]: {
    '@media (min-resolution: 1.25dppx)': {
      width: '100px',
      height: '100px',
    },
    width: '130px',
    height: '160px',
  },
}));

export const ProfImgContainer = styled(Box)(({ theme }) => ({
  zIndex: '0',
  borderRadius: '50%',
  background: '#d6d9d9',
  position: 'absolute',
  top: '0',

  '& > div': {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    padding: '2px',
    width: '100%',
    height: '100%',

    ' & > img.loaded-img': {
      borderRadius: '50%',
    },
  },

  [theme.breakpoints.down('sm')]: {
    width: '75px',
    height: '75px',
  },
  [theme.breakpoints.down('md')]: {
    width: '100px',
    height: '100px',
  },
  [theme.breakpoints.up('md')]: {
    width: '80px',
    height: '80px',
    ' & > img.loaded-img': {
      width: '100px',
    },
  },
  [theme.breakpoints.up('lg')]: {
    width: '150px',
    height: '150px',
    '@media (min-resolution: 1.25dppx)': {
      width: '100px',
      height: '100px',
    },
  },
}));

export const InviteInfoText = styled(Box)(({ theme }) => ({
  marginBottom: '10px',
  padding: '10px',
  marginLeft: 'auto',
  marginRight: 'auto',
  '& > p': {
    padding: '10px 5px',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    paddingLeft: '20px',
    paddingRight: '20px',
    textAlign: 'center',
    marginTop: '60px',
    fontWeight: '600!important',
  },
  [theme.breakpoints.down('sm')]: {
    '& > p': {
      padding: '0',
      fontSize: '11.5px',
    },
  },
  [theme.breakpoints.up('md')]: {
    width: '60%',
  },
}));

export const ReqErrorMsg = styled(Box)(({ theme }) => ({
  color: '#e31c3d',
  fontWeight: 500,
  marginLeft: '10px',

  fontSize: '12px !important',
  [theme.breakpoints.down('md')]: {},
  [theme.breakpoints.up('md')]: {},
}));

export const Template = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {},
  [theme.breakpoints.up('md')]: {},
}));

export const XSymbol = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',

  width: '35px',
  height: '35px',

  position: 'relative',
  fontSize: '35px',

  margin: '0',
  padding: '0',

  borderRadius: '60px',
  transform: 'rotate(45deg)',

  '& > div': {
    height: '0',
    width: '0',

    position: 'absolute',
    top: '-9px',
    right: '26px',
  },
}));
