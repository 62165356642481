export function formatPhoneNumber(value: string) {
  if (!value) return value;
  const phoneNumber = value.replace(/[^\d]/g, '');

  const phoneNumberLength = phoneNumber.length;

  if (phoneNumberLength === 1 && phoneNumber[0] === '1') return phoneNumber;

  if (phoneNumberLength <= 4 && phoneNumber[0] !== '1') return phoneNumber;

  if (phoneNumberLength <= 4 && phoneNumber[0] === '1')
    return `${phoneNumber.slice(0, 1)}-${phoneNumber.slice(1)}`;

  if (phoneNumberLength <= 7 && phoneNumber[0] !== '1') {
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
  }
  if (phoneNumberLength <= 7 && phoneNumber[0] === '1') {
    return `${phoneNumber.slice(0, 1)}-${phoneNumber.slice(1, 4)}-${phoneNumber.slice(4)}`;
  }

  if (phoneNumberLength <= 10 && phoneNumber[0] !== '1') {
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  }
  if (phoneNumberLength <= 11 && phoneNumber[0] === '1') {
    return `${phoneNumber.slice(0, 1)}-${phoneNumber.slice(1, 4)}-${phoneNumber.slice(
      4,
      7
    )}-${phoneNumber.slice(7, 11)}`;
  }
  if (phoneNumberLength > 11 && phoneNumber[0] === '1') {
    return `${phoneNumber.slice(0, 1)}-${phoneNumber.slice(1, 4)}-${phoneNumber.slice(
      4,
      7
    )}-${phoneNumber.slice(7, 11)} ext. ${phoneNumber.slice(11)}`;
  }
  return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(
    6,
    10
  )} ext. ${phoneNumber.slice(10)}`;
}

export function formatOnlyNumbers(value: string) {
  const checkNumber = /^([0-9]|[1-7][0-9]|80)$/;
  if (checkNumber.test(value)) {
    return value.toString();
  } else {
    return value.slice(0, -1).toString();
  }
}

export function formatOnlyDecimals(value: string) {
  const checkDecimal = /^[+]?[0-9]{1,3}([.][0-9]{1,2})?$/;

  if (checkDecimal.test(value)) {
    return value.toString();
  } else if (!checkDecimal.test(value) && value.split('').pop() === '.') {
    return value.toString();
  } else {
    return value.slice(0, -1).toString();
  }
}
