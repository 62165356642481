import { configureStore } from '@reduxjs/toolkit';

import providerReducer from './providerSlice';
import jobsReducer from './jobSlice';
import individualReducer from './individualSlice';
import employerReducer from './employerSlice';
import notificationReducer from './notificationSlice';
import pushNotificationReducer from './pushNotificationSlice';
import notificationMiddleware from './middleware/notificationMiddleware';
import authReducer from './authSlice';
import activityReducer from './activitySlice';
import keywordReducer from './keywordSlice';
import { jobApi } from './services/jobApi';
import { setupListeners } from '@reduxjs/toolkit/query';
import { individualApi } from './services/individualApi';
import { employerApi } from './services/employerApi';
import chatReducer from './chatSlice';

const store = configureStore({
  reducer: {
    providers: providerReducer,
    jobs: jobsReducer,
    individuals: individualReducer,
    employers: employerReducer,
    notification: notificationReducer,
    pushNotification: pushNotificationReducer,
    auth: authReducer,
    keyword: keywordReducer,
    activity: activityReducer,
    [jobApi.reducerPath]: jobApi.reducer,
    [individualApi.reducerPath]: individualApi.reducer,
    [employerApi.reducerPath]: employerApi.reducer,
    chat: chatReducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware()
      .concat(jobApi.middleware)
      .concat(individualApi.middleware)
      .concat(employerApi.middleware)
      .concat(notificationMiddleware);
  },
});

setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {  provider: providerState, jobs: jobsState, individuals: individualState, employers: employerState  etc...}
export type AppDispatch = typeof store.dispatch;

export default store;
