import { Chat } from '../typescript/types';
import { IndividualMessages } from './IndividualMessagesComponent';
import { UserMessages } from './ProviderMessagesComponent';

interface MessageProps {
  message: Chat;
  individualName: string;
}
export const Message = ({ message, individualName }: MessageProps) => {
  console.log(message);

  return (
    <>
      {message.from_user_type === 'provider' ? (
        <UserMessages
          timestamp={message.created_at}
          message={message.content}
          providerId={message.from_user_id}
        />
      ) : (
        <IndividualMessages
          individualName={individualName}
          timestamp={message.created_at}
          message={message.content}
        />
      )}
    </>
  );
};
