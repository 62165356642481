import { ArrowBackIos, EditOutlined } from '@mui/icons-material';
import { Box, IconButton, Tooltip } from '@mui/material';
import React from 'react';
import { mobilebackToStyling, mobilebacktoIcon } from '../../../helpers/styling';
import { getFullName } from '../../../helpers/helperFunctions';
import { Employer, Individual, Job, Provider } from '../../typescript/types';
import {
  IconWrapper,
  InlineContainer,
  MobileContainerHeader,
} from '../../UI/StyledComponents/GlobalStyledComponents';
import EmploymentSupportLeadCardComponent from '../EmploymentSupportLeadCardComponent';
import { useNavigate } from 'react-router-dom';
import { getMyProviderDetails } from '../../../store/providerSlice';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { selectJobById } from '../../../store/jobSlice';

interface ButtonAndProviderProps {
  goBackUrl: string;
  leadProvider: Provider;
  refObj: Employer | Job | Individual;
}

const ViewComponentSharedButtons = (props: ButtonAndProviderProps) => {
  const navigate = useNavigate();
  const { leadProvider, refObj, goBackUrl } = props;

  const job = useSelector((rState: RootState) => selectJobById(rState, refObj.id!));
  const myProviderDetails = useSelector(getMyProviderDetails);

  return (
    <MobileContainerHeader>
      <IconWrapper
        className="go-back-button"
        onClick={() => navigate(goBackUrl)}
        marginRight="10px"
      >
        <IconButton sx={mobilebackToStyling}>
          <ArrowBackIos sx={mobilebacktoIcon} viewBox="0 0 15 24" />
        </IconButton>
      </IconWrapper>

      <EmploymentSupportLeadCardComponent
        name={getFullName(leadProvider)}
        email={leadProvider?.email}
        phone={leadProvider?.phone}
        displayBorder
        hideAvatar
        centered
      />

      <IconWrapper marginLeft="10px">
        <Tooltip
          title={
            goBackUrl === '/jobs' && myProviderDetails?.orgId !== job?.providerOrgId
              ? 'You do not have permission to edit this job'
              : ''
          }
          placement="top"
          enterTouchDelay={50}
          leaveTouchDelay={1500}
          followCursor
        >
          <span>
            <IconButton
              onClick={() => navigate('edit', { state: refObj! })}
              sx={mobilebackToStyling}
              disabled={goBackUrl === '/jobs' && myProviderDetails?.orgId !== job?.providerOrgId}
            >
              <EditOutlined sx={mobilebacktoIcon} />
            </IconButton>
          </span>
        </Tooltip>
      </IconWrapper>
    </MobileContainerHeader>
  );
};

export default ViewComponentSharedButtons;
