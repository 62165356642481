import React, { ReactElement, useEffect, useState } from 'react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Box, Button, Divider, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import useTranslate from '../../hooks/useTranslate';
import { RootState } from '../../store';
import { ActivityProp, Individual, Job } from '../typescript/types';
import { getTags } from '../../store/keywordSlice';
import { getAllJobs } from '../../store/jobSlice';
import {
  ActivityChildBox,
  ActivityParentBox,
  BackDrop,
  BorderBox,
  InlineContainer,
} from '../UI/StyledComponents/GlobalStyledComponents';
import { getFieldTitle, nTimeAgo } from '../../helpers/helperFunctions';
import {
  getAllActivity,
  getIndividualActivity,
  setActivityList,
  thunkFetchCustomActivity,
} from '../../store/activitySlice';
import { ActivityIcon } from '../UI/StyledComponents/NotificationModalStyledComponents';
import { convertTagsToString } from '../../helpers/displayOptions';
import { getAccessToken } from '../../store/authSlice';
import { useAppDispatch } from '../typescript/hooks';
import { FormTextInput, ReqErrorMsg } from '../UI/StyledComponents/FormsStyledComponents';
import { selectIndividualById } from '../../store/individualSlice';

import Icon from '../../asssets/icons';

interface ActivityProps {
  field: string;
  value: string | number[];
}

const ActivityLogComponent = (props: { indId: string; hideModal: () => void }) => {
  const activityList = useSelector((state: RootState) => getAllActivity(state));
  const { indId, hideModal } = props;

  return (
    <BackDrop className="jobs-backdrop">
      <CloseIcon
        onClick={() => {
          hideModal();
        }}
      />
      <BorderBox className="border-box">
        <ActivityParentBox className="activity-header">
          <DatePickerComponent indId={indId} />
          <ActivityChildBox className="hide-scroll">
            {activityList.map((item: ActivityProp) => {
              return <ActivityLogCard key={`activity-${item.activityId}`} activity={item} />;
            })}
          </ActivityChildBox>
        </ActivityParentBox>
      </BorderBox>
    </BackDrop>
  );
};

export const ActivityLogCard = (props: { activity: ActivityProp }) => {
  const { activity } = props;
  const t = useTranslate();
  const allJobs = useSelector(getAllJobs);
  const TAGS = useSelector((state: RootState) => getTags(state, t));
  const individual = useSelector((state: RootState) =>
    selectIndividualById(state, activity?.individualId!)
  );

  const buildMessage = (
    origin: string,
    values: ActivityProps[],
    tag: string,
    ts: string
  ): ReactElement<any, any> => {
    const activitySource = origin === 'b2c' ? `${individual?.firstName}` : 'You';

    let activityType = tag.startsWith('login') ? 'login' : tag;

    let phrase: ReactElement<any, any>; // builds inner content for activity card
    let cards: ReactElement<any, any>; // builds final ui for activity card

    switch (activityType) {
      case 'profile':
        // this activity type can be an array of profile properties, but needs to also separate skills/interest activities then determine if they were removed or added by user.
        cards = getEditedPropertyPhrases(activitySource, values, ts);
        break;
      case 'like-job':
        phrase = (
          <PopulateActivityContent
            icon={Icon.ActivityLike}
            text={`${activitySource} liked`}
            dynamicText={getJobTitle(values[0].value as string)}
          />
        );
        cards = <BuildCardStyilng phrase={phrase} ts={ts} />;
        break;
      case 'unlike-job':
        phrase = (
          <PopulateActivityContent
            icon={Icon.ActivityUnlike}
            text={`${activitySource} unliked`}
            dynamicText={getJobTitle(values[0].value as string)}
          />
        );
        cards = <BuildCardStyilng phrase={phrase} ts={ts} />;
        break;
      case 'express-interest':
        phrase = (
          <PopulateActivityContent
            icon={Icon.ActivityExpressInterest}
            text={`${activitySource} started an application for`}
            dynamicText={getJobTitle(values[0].value as string)}
          />
        );
        cards = <BuildCardStyilng phrase={phrase} ts={ts} />;
        break;
      case 'remove-applied-job':
        phrase = (
          <PopulateActivityContent
            icon={Icon.ActivityRemoved}
            text={`${activitySource} ${t('myactivity-removeApplied')}`}
            dynamicText={getJobTitle(values[0].value as string)}
          />
        );
        cards = <BuildCardStyilng phrase={phrase} ts={ts} />;
        break;
      case 'b2b-remove-applied-job':
        phrase = (
          <PopulateActivityContent
            icon={Icon.ActivityStatusUpdate}
            text={`${activitySource} ${t('myactivity-archive')}`}
            dynamicText={getJobTitle(values[0].value as string)}
          />
        );
        cards = <BuildCardStyilng phrase={phrase} ts={ts} />;
        break;
      case 'login':
        phrase = (
          <PopulateActivityContent
            icon={Icon.ActivityLoggedIn}
            text={`${activitySource} logged in `}
            dynamicText={dayjs(values[0].value as string).format('MMMM, DD, YYYY')}
          />
        );
        cards = <BuildCardStyilng phrase={phrase} ts={ts} />;
        break;
      case 'status-not-a-match':
        phrase = (
          <PopulateActivityContent
            icon={Icon.ActivityStatusUpdate}
            text={`${activitySource}'s application was updated to 'Not a match' for the position:  `}
            dynamicText={getJobTitle(values[0].value as any)}
          />
        );
        cards = <BuildCardStyilng phrase={phrase} ts={ts} />;
        break;
      case 'status-in-progress':
        phrase = (
          <PopulateActivityContent
            icon={Icon.ActivityStatusUpdate}
            text={`${activitySource}'s application was updated to 'In Progress' for the position:  `}
            dynamicText={getJobTitle(values[0].value as any)}
          />
        );
        cards = <BuildCardStyilng phrase={phrase} ts={ts} />;
        break;
      case 'status-interview-scheduled':
        phrase = (
          <PopulateActivityContent
            icon={Icon.ActivityStatusUpdate}
            text={`${activitySource}'s application was updated to 'Interview Scheduled' for the position:  `}
            dynamicText={getJobTitle(values[0].value as any)}
          />
        );
        cards = <BuildCardStyilng phrase={phrase} ts={ts} />;
        break;
      case 'status-offer-of-employment':
        phrase = (
          <PopulateActivityContent
            icon={Icon.ActivityStatusUpdate}
            text={`${activitySource}'s application was updated to 'Offer of employment!!!!' for the position:  `}
            dynamicText={getJobTitle(values[0].value as any)}
          />
        );
        cards = <BuildCardStyilng phrase={phrase} ts={ts} />;
        break;
      default:
        phrase = (
          <InlineContainer justifyContent={'flex-start'}>
            <Typography>⚠️⚠️⚠️ Unhandled case:&nbsp;</Typography>
            <Typography fontWeight={600}>{tag} ⚠️⚠️⚠️</Typography>
          </InlineContainer>
        );
        cards = <BuildCardStyilng phrase={phrase} ts={ts} />;
    }

    return cards;
  };

  // builds an array of activity items into one message. needs to separate skills and interests that are removed or added.
  const getEditedPropertyPhrases = (
    activitySource: string,
    values: ActivityProps[],
    ts: string
  ) => {
    const skillInterestActivities = values.filter(
      (value: ActivityProps) => value.field.endsWith('added') || value.field.endsWith('removed')
    );
    const allOtherProfileActivities = values.filter(
      (value: ActivityProps) => !value.field.endsWith('added') && !value.field.endsWith('removed')
    );

    let phrase: any; //generates the content, then feeds into final activity card UI.
    const isPlural = (count: number) => {
      return count === 1 && ' the ';
    };

    const TagCards = (
      <>
        {
          // if a profile change has been made, and skills or interests were touched, then build added/removed cards to reflect this.
          skillInterestActivities.length > 0 &&
            skillInterestActivities.map((activity: ActivityProps, index: number) => {
              if (activity.field.endsWith('added')) {
                phrase = (
                  <PopulateTagContent
                    icon={Icon.ActivityAdded}
                    noun={activitySource}
                    activityObject={activity}
                    connector={isPlural(activity.value.length) as string}
                  />
                );

                return <BuildCardStyilng phrase={phrase} ts={ts} key={activity.field + index} />;
              } else {
                phrase = (
                  <PopulateTagContent
                    icon={Icon.ActivityRemoved}
                    noun={activitySource}
                    activityObject={activity}
                    connector={isPlural(activity.value.length) as string}
                  />
                );

                return <BuildCardStyilng phrase={phrase} ts={ts} key={activity.field + index} />;
              }
            })
        }{' '}
        {allOtherProfileActivities.length > 0 && (
          <>
            <BuildCardStyilng
              ts={ts}
              phrase={
                <PopulateActivityContent
                  icon={Icon.ActivityEdited}
                  text={`${activitySource} edited `}
                  dynamicText={`${getFields(allOtherProfileActivities)} on their profile.`}
                />
              }
            />
          </>
        )}
      </>
    );

    return TagCards;
  };

  const getJobTitle = (id: string) => {
    const job = allJobs.find((job: Job) => job.id === id);
    return `${job?.title} at ${
      job?.employerOrganizationName.endsWith('.')
        ? job?.employerOrganizationName.slice(0, -1)
        : job?.employerOrganizationName
    }.`;
  };

  const getFields = (values: ActivityProps[]) => {
    let regex = /,(?=[^,]*$)/; // Matches the last comma

    return values.length === 1
      ? getFieldTitle(values[0].field as unknown as keyof Partial<Individual>)
      : values
          .map((value: ActivityProps) => {
            return getFieldTitle(value.field as unknown as keyof Partial<Individual>);
          })
          .join(', \n')
          .replace(regex, ' and');
  };

  const PopulateTagContent = (props: {
    icon: string;
    noun: string;
    activityObject: ActivityProps;
    connector: string;
  }) => {
    const { icon, noun, connector, activityObject } = props;
    const fromOrTo = activityObject.field.endsWith('removed') ? 'from' : 'to';
    const addedRemoved = activityObject.field.endsWith('removed') ? 'removed' : 'added';
    return (
      <InlineContainer justifyContent={'flex-start'}>
        <ActivityIcon component="img" src={icon} />
        <Typography>
          {noun} {addedRemoved} {connector}
          <b>{convertTagsToString(activityObject.value as number[], false, TAGS?.all)}</b>{' '}
          {activityObject.field.split('-')[0]} {fromOrTo} their profile.
        </Typography>
      </InlineContainer>
    );
  };

  const PopulateActivityContent = (props: { icon: string; text: string; dynamicText: string }) => {
    const { icon, text, dynamicText } = props;
    return (
      <InlineContainer justifyContent={'flex-start'}>
        <ActivityIcon component="img" src={icon} />
        <Typography>
          {text} <b>{dynamicText}</b>{' '}
        </Typography>
      </InlineContainer>
    );
  };

  const BuildCardStyilng = (props: { phrase: ReactElement<any, any>; ts: string }) => {
    return (
      <>
        <Box sx={{ padding: '10px 5px' }}>
          <InlineContainer justifyContent="space-between">
            {props.phrase}
            <Typography
              variant="subtitle1"
              sx={{
                padding: '0 15px',
                textWrap: 'nowrap',
              }}
            >
              {nTimeAgo(props.ts)}
            </Typography>
          </InlineContainer>
        </Box>
        <Divider />
      </>
    );
  };

  return (
    <>{buildMessage(activity.classification, activity.values, activity.tag, activity.timestamp)}</>
  );
};

const DatePickerComponent = (props: { indId: string }) => {
  const [start, setStart] = useState<any>(null);
  const [end, setEnd] = useState<any>(null);
  const [isIncorrectRange, setIsIncorrectRange] = useState<boolean>(true);
  const [displayValidation, setDisplayValidation] = useState<boolean>(false);

  const token = useSelector(getAccessToken);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (dayjs(start) > dayjs(end)) {
      setDisplayValidation(true);
    } else {
      setDisplayValidation(false);
    }
    const isInvalid = dayjs(start) > dayjs(end) || start === null || end === null;
    setIsIncorrectRange((currState) => (currState = isInvalid));
  }, [start, end]);
  return (
    <Box sx={{ padding: '30px 20px' }}>
      <InlineContainer justifyContent={'space-between'}>
        <Typography variant="h3" padding="0 20px">
          EARLIER
        </Typography>
        <InlineContainer justifyContent="space-between" width="525px !important">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              // {...register("dob")}
              label="start"
              views={['month', 'year']}
              disableFuture
              value={start}
              onChange={(e) => {
                setStart(e);
              }}
              renderInput={(params) => (
                <FormTextInput type="number" {...params} sx={{ width: '250px' }} error={false} />
              )}
            />

            <DatePicker
              // {...register("dob")}
              label="end"
              views={['month', 'year']}
              disableFuture
              value={end}
              onChange={(e) => {
                setEnd(e);
              }}
              renderInput={(params) => (
                <FormTextInput type="text" {...params} sx={{ width: '250px' }} error={false} />
              )}
            />
          </LocalizationProvider>
        </InlineContainer>
      </InlineContainer>
      <Box
        sx={{
          display: 'inline-grid',
          rowGap: '20px',
          gridTemplateRows: '20px 30px',
          justifyItems: 'end',
          width: '100%',
          padding: '10px',
        }}
      >
        <Box>
          {isIncorrectRange && displayValidation && (
            <ReqErrorMsg> Please select a valid date range to continue</ReqErrorMsg>
          )}
        </Box>
        <InlineContainer justifyContent="flex-end">
          <Button
            variant="text"
            onClick={() => {
              dispatch(getIndividualActivity({ indId: props.indId!, token: token! }))
                .unwrap()
                .then((res) => {
                  dispatch(setActivityList(res));
                });
            }}
          >
            Clear
          </Button>
          <Button
            variant="contained"
            disabled={isIncorrectRange}
            onClick={() => {
              dispatch(
                thunkFetchCustomActivity({
                  indId: props.indId!,
                  start: start.toISOString(),
                  end: end.toISOString(),
                  token: token!,
                })
              )
                .unwrap()
                .then((res) => {
                  //should decouple this to its own conditional.
                  console.warn('Retrieved activity', res);
                  dispatch(setActivityList(res));
                });
            }}
          >
            Search
          </Button>
        </InlineContainer>
      </Box>
    </Box>
  );
};

export default ActivityLogComponent;
