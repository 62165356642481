import { Box, Button, Typography } from '@mui/material';
import Card from '../Card/CardComponent';
import {
  AltSuccessModal,
  AltSuccessModalBox,
} from '../StyledComponents/NotificationModalStyledComponents';
import { useNavigate } from 'react-router-dom';

const AltSuccessDeleteComponent = (props: {
  message: string;
  animation: string;
  subject: string;
}) => {
  const navigate = useNavigate();
  return (
    <>
      <AltSuccessModal>
        <AltSuccessModalBox>
          <img src={props.animation} alt="success-gif" />
          <Typography variant="body1">{props.message}</Typography>
        </AltSuccessModalBox>
        <Box display="flex" justifyContent="center" marginBottom="20px">
          <Button variant="contained" onClick={() => navigate('/' + props.subject + 's')}>
            Return to {props.subject}s page
          </Button>
        </Box>
      </AltSuccessModal>
    </>
  );
};

export default AltSuccessDeleteComponent;
