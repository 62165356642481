import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import useDetectResize from '../../hooks/useDetectResize';
import { AccountCircle } from '@mui/icons-material';
import { getProviderById } from '../../store/providerSlice';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import dayjs from 'dayjs';

interface UserMessagesProps {
  timestamp: string;
  message: string;
  providerId?: string;
}
export const UserMessages = ({ timestamp, message, providerId }: UserMessagesProps) => {
  const { isMobileOrTablet } = useDetectResize();
  const providerDetails = useSelector((state: RootState) => getProviderById(state, providerId));

  return (
    <Box
      display={'flex'}
      flexDirection={'row'}
      columnGap={isMobileOrTablet ? '0.1rem' : '0.5rem'}
      alignItems={'end'}
      justifyContent="flex-end"
    >
      <Box
        borderRadius={'8px'}
        padding={'1rem'}
        minWidth={isMobileOrTablet ? '45%' : '40%'}
        maxWidth={isMobileOrTablet ? '65%' : '45%'}
        boxShadow={5}
        bgcolor={'#0F1177'}
      >
        <Box
          display={'flex'}
          flexDirection={isMobileOrTablet ? 'column' : 'row'}
          paddingBottom={'0.5rem'}
        >
          <Typography variant="body1" fontWeight={'bold'} color={'white'} flex={1}>
            {providerDetails
              ? `${providerDetails!.firstName} ${providerDetails!.lastName}`
              : 'Employment Support Provider'}
          </Typography>
          <Typography variant="body1" fontWeight={'bold'} color={'white'}>
            {dayjs(timestamp).format('MMM D, YYYY, h:mm A')}
          </Typography>
        </Box>
        <Typography variant="body1" style={{ wordWrap: 'break-word' }} color={'white'}>
          {message}
        </Typography>
      </Box>
      {providerDetails?.photoUrl?.startsWith('https://') ? (
        <img
          className={`profile-img-header`}
          src={providerDetails?.photoUrl}
          alt="headshot"
          loading="lazy"
        />
      ) : (
        <AccountCircle fontSize="large" color="primary" />
      )}
    </Box>
  );
};
