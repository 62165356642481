import dayjs from 'dayjs';
import { Individual, Job, Provider } from '../components/typescript/types';
import relativeTime from 'dayjs/plugin/relativeTime';
import IndImage from '../asssets/industry-images/industry-images';
import Image from '../asssets/images/Image';
dayjs.extend(relativeTime);

export const getFullName = (provider?: Partial<Provider | Individual>) => {
  let fullName = provider ? (provider?.firstName + ' ' + provider?.lastName).trim() : '';
  return fullName;
};

export function appendRemainingDetails(addressObject: google.maps.places.PlaceResult): string {
  return addressObject
    .address_components!.map((dataBlock) => {
      if (
        dataBlock.types.includes('locality') ||
        dataBlock.types.includes('country') ||
        dataBlock.types.includes('postal_code') ||
        dataBlock.types.includes('administrative_area_level_1')
      ) {
        return dataBlock.short_name;
      }
      return '';
    })
    .filter((obj) => obj)
    .join(', ');
}

export function numberOfDays(closeDateSeconds: number): number {
  let deltaSeconds = closeDateSeconds - dayjs().unix();
  let deltaDays = Math.floor(deltaSeconds / (3600 * 24));
  return deltaDays;
}
export function numberOfDaysOpenForWork(openTS: number): number {
  let deltaSeconds = dayjs().unix() - openTS;
  let deltaDays = Math.floor(deltaSeconds / (3600 * 24));
  return deltaDays;
}

export function stringToColor(str: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < str?.length; i += 1) {
    hash = str?.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return { bgColor: `${color}30`, fontColor: color };
}

export function stringAvatar(name?: string, overrideBgColor?: boolean, replaceWith?: string) {
  return {
    sx: {
      bgcolor: stringToColor(overrideBgColor ? replaceWith! : name!).bgColor,
      textShadow: '0px 0px 20px rgba(5,113,183,1)',
      marginRight: '0.85em',
      fontSize: '1em',
      fontFamily: 'LexendDeca',
      fontWeight: 600,
      color: stringToColor(overrideBgColor ? replaceWith! : name!).fontColor,
      '@media (max-resolution: 1dppx) and (min-height: 1030px)': {
        fontSize: '1.15em',
        width: '2.75em',
        height: '2.75em',
      },
    },
    children: getInitialsForAvatar(name!),
  };
}

export function getInitialsForAvatar(name: string) {
  //used to convert multiple scenarios.
  // return `${(name === undefined ? 'ES' :
  //   name?.split(' ').length > 1 ?
  //    (
  //      (isLetter(name?.split(' ')[1])) ?
  //       `${name?.split(' ')[0][0]}${name?.split(' ')[1][0]}`
  //       :
  //       `${name?.split(' ')[0][0]}${name?.split(' ')[0][1]}`
  //     )
  //   :
  //   (name?.split(' ').length === 1 && name.length > 1) ? `${name[0]}${name[1] }`
  //   :
  //   name[0])?.toUpperCase()
  // }`;
  return `${name === undefined ? 'ES' : getInitials(name!)}`;

  function getInitials(fullString: string) {
    const eachWordArr = fullString.split(' ');
    let initials: string[] = [];

    if (eachWordArr.length > 1) {
      eachWordArr.every((word: string) => {
        word.split('').every((letter: string) => {
          if (initials.length === 2) return false;

          if (letter.match(/^[a-zA-Z0-9]+$/)) {
            initials.push(letter);
            return false; //acts as a break. get first letter then break from looping word and go to next word
          } else {
            return true; //acts as a 'continue'. If not a number or a letter, continue to next character.
          }
        });
        return true;
      });
    } else {
      // if single word just get two consecutive letters/numbers
      initials.push(eachWordArr[0][0]);
      initials.push(eachWordArr[0][1]);
    }

    return `${initials.join('')?.toUpperCase()}`;
  }
}

export function stringDetailedColor(string: string) {
  const color = '#0F117730';

  return color;
}

export function validateURL(url: string) {
  const parsed = new URL(url);
  return ['https:'].includes(parsed.protocol);
}

export function compareLabel(
  a: { label: string; value: number },
  b: { label: string; value: number }
) {
  if (a.label < b.label) {
    return -1;
  }
  if (a.label > b.label) {
    return 1;
  }
  return 0;
}

export function capitalize(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export function getSortedRankedKeyValuePair(arr: number[]) {
  const map = arr.reduce(function (obj: any, b) {
    obj[b] = ++obj[b] || 1;
    return obj;
  }, {});

  let results = [];
  for (const property in map) {
    results.push({ id: `${property}`, count: `${map[property]}` });
  }
  return results.sort((a: { id: string; count: string }, b: { id: string; count: string }) => {
    return +a.count > +b.count ? -1 : 1;
  });
}

export function getGroupedTagsCount(arr: any[]) {
  const map = arr.reduce(function (obj: any, b) {
    let el = b.join('-');
    obj[el] = ++obj[el] || 1;
    return obj;
  }, {});
  let results = [];
  for (const property in map) {
    results.push({ id: `${property}`, count: `${map[property]}` });
  }
  //DEBUG
  // console.log('EL: ', el)
  // console.log('Map', map);
  // console.log('Property: ', property);
  // console.log('Final', results);
  return results;
}

export const getShortDays = (value: any): string[] => {
  function dayAbbr(day: string) {
    if (day === 'Monday') {
      return 'Mon';
    }
    if (day === 'Tuesday') {
      return 'Tues';
    }
    if (day === 'Wednesday') {
      return 'Wed';
    }
    if (day === 'Thursday') {
      return 'Thur';
    }
    if (day === 'Friday') {
      return 'Fri';
    }
    if (day === 'Saturday') {
      return 'Sat';
    }
    if (day === 'Sunday') {
      return 'Sun';
    } else {
      return day;
    }
  }
  const abbrDay = value.map((day: string) => {
    return dayAbbr(day);
  });
  console.log(abbrDay);
  return abbrDay;
};

export function calculateAvailableHours(obj: Individual) {
  return `${obj?.filledHours}/${obj?.availableHours} `;
}

export default function getDistCalc(
  lng1: number,
  lat1: number,
  lng2: number,
  lat2: number
): number {
  var R = 6371; // Radius of the earth in kilometers
  var dLat = deg2rad(lat2 - lat1); // deg2rad below
  var dLon = deg2rad(lng2 - lng1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in KM
  return +d;

  function deg2rad(deg: number) {
    return deg * (Math.PI / 180);
  }
}

export function isValidDate(date: any) {
  console.log(typeof date);
  console.log(date);
  console.log(!Number.isNaN(new Date(date).getTime()));
  console.log(dayjs(date).isAfter('01/01/1899', 'year'));
  if (date === '' || date == 'null' || date == null) {
    return true;
  } else if (
    !Number.isNaN(new Date(date).getTime()) &&
    dayjs(date).isAfter('01/01/1899', 'year') &&
    dayjs(date).isBefore(dayjs().add(100, 'year'), 'year')
  ) {
    return true;
  } else {
    return 'The date you entered is not a valid date, please try again MM/DD/YYYY.';
  }
}

export const PROPERTY_DICT: any = {
  id: 'id',
  profileImg: 'img',
  leadProviderOrgId: 'organiziation',
  firstName: 'first name',
  lastName: 'last name',
  pronouns: 'pronouns',
  agencyId: 'agency id',
  govId: 'government id',
  intakeDate: 'start date',
  preferredComms: 'preferred communication',
  requiredAccommodation: 'accomodations',
  email: 'profile email',
  leadProviderId: 'provider ID',
  leadProviderName: 'provider',
  address: 'address',
  phone: 'phone number',
  dob: 'birth date',
  policeCheck: 'police check date',
  creditCheck: 'credit check date',
  preferredRoles: 'preferred roles',
  minRate: 'min hourly rate',
  maxRate: 'max hourly rate',
  fileAttachments: 'file attachments',
  referenceAttachments: 'reference attachments',
  certificationAttachments: 'certification attachments',
  skills: 'skills',
  interests: 'interests',
  restrictions: 'restrictions',
  restrictionsText: 'restrictions',
  status: 'Job search status',
  daysAvailable: 'available days',
  availableHours: 'available hours',
  filledHours: 'filled hours',
  employmentRecord: 'employment record',
  employmentHistory: 'employment history',
  notes: 'notes',
  longitude: 'longitude',
  latitude: 'latitude',
};

export function getFieldTitle(propertyName: keyof Partial<Individual>) {
  console.warn(`get field for ${propertyName} : `, PROPERTY_DICT[propertyName]);
  let title: any = PROPERTY_DICT[propertyName];

  return title;
}

export function nTimeAgo(ts: string) {
  return `${dayjs().to(dayjs(ts), true)} ago`;
}

export function getIndustryImage(job: Job) {
  const initialIndustry = job.industry?.toLowerCase().replace(/\s+/g, '-');
  const postIndustry = initialIndustry!.replace(/,/g, '');

  const industry = postIndustry!.includes('-')
    ? postIndustry
        .split('-')
        .map((part: string) => part[0]?.toUpperCase() + part.slice(1))
        .join('')
    : postIndustry[0].toUpperCase() + postIndustry.slice(1);

  if (IndImage.hasOwnProperty(industry)) {
    return IndImage[industry as keyof typeof IndImage];
  } else {
    return Image.PlaceHolder;
  }
}

export function getIndustryBanner(job: Job) {
  console.log(job);
  const initialIndustry = job.industry?.toLowerCase().replace(/\s+/g, '-');
  const postIndustry = initialIndustry?.replace(/,/g, '');
  console.warn('PostIndustry: ', postIndustry);
  const industry = postIndustry!.includes('-')
    ? postIndustry
        .split('-')
        .map((part) => part[0]?.toUpperCase() + part.slice(1))
        .join('')
    : postIndustry[0].toUpperCase() + postIndustry.slice(1);

  if (IndImage.hasOwnProperty(industry)) {
    const wide = `${industry}Wide`;
    return IndImage[wide as keyof typeof IndImage];
  } else {
    return Image.PlaceHolderWide;
  }
}

export const classifyJobByHours = (value: string | number | boolean | number[] | string[] | undefined) => {
  let weeklyHours: number;
  if (typeof value === 'string') {
    const match = value.match(/\d+/);
    if (match) {
      weeklyHours = parseInt(match[0], 10);
    } else {
      weeklyHours = 0;
    }
  } else if (typeof value === 'number') {
    weeklyHours = value;
  } else {
    weeklyHours = -1;
  }

  if (weeklyHours >= 35) {
    return 'Full-time';
  } else if (weeklyHours < 35 && weeklyHours >= 20) {
    return 'Part-time';
  } else if (weeklyHours < 20 && weeklyHours >= 0) {
    return 'Casual';
  } else {
    return 'N/A';
  }
};
