import React from "react";
import useTranslate from "../../hooks/useTranslate";
import { Job } from "../typescript/types";
import SanitizeHTML from "../SharedComponents/SanitizeHTML";
import { Typography, Box } from "@mui/material";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { InlineContainer } from "../UI/StyledComponents/GlobalStyledComponents";

const JobDescription = (props: { job?: Job }) => {
  const { job } = props;
  const t = useTranslate();
  return (
    <>
      <InlineContainer justifyContent="flex-start">
        <DescriptionOutlinedIcon sx={{ marginRight: "1em" }} />
        <Typography variant="h3" color={"black"}>
          {t("jobDescriptionText").toUpperCase()}
        </Typography>
      </InlineContainer>
      <section className="listing">
        <Typography variant="body1">
          {!!job?.description?.length ? (
            <SanitizeHTML htmlString={job?.description} />
          ) : (
            t("noDetailsAvailable")
          )}
        </Typography>
      </section>
    </>
  );
};

export default JobDescription;
