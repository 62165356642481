import {
  createAsyncThunk,
  createSlice,
  isRejectedWithValue,
  PayloadAction,
} from '@reduxjs/toolkit';
import { Employer, ProtectedReq, EmployerMetrics } from '../components/typescript/types';
import axios from 'axios';
import type { RootState } from './index';
import { EmployerFormData } from '../components/EmployerComponents/EmployersCreateComponent';

export interface EmployerSlice {
  employers: Employer[];
  IEM: EmployerMetrics[];
  status: string;
  error: string | null;
  myOrgId: string;
}

export const fetchEmployerMetrics = createAsyncThunk(
  'employers/fetchEmployers',
  async (params: ProtectedReq) => {
    try {
      return axios
        .get(`${process.env.REACT_APP_SERVER_URL}/api/v1/employer/${params.orgId}`, {
          headers: {
            Authorization: `Bearer ${params.token}`,
          },
        })
        .then((response) => {
          console.info('Response Employer ', response);
          return response.data;
        })
        .catch((err) => {
          return err.message;
        });
    } catch (e) {
      console.info(e);
    }
  }
);

export const postEmployer = createAsyncThunk(
  'employers/postEmployer',
  async (params: { employerObject: EmployerFormData; token: string }) => {
    return axios
      .post(`${process.env.REACT_APP_SERVER_URL}/api/v1/employer/create`, params.employerObject, {
        headers: {
          Authorization: `Bearer ${params.token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err.message;
      });
  }
);

export const updateEmployer = createAsyncThunk(
  'employers/updateEmployer',
  async (
    params: {
      employerObject: EmployerFormData;
      token: string;
      updateJobs: boolean;
      updateNewProviderToAllJobs: boolean;
    },
    { rejectWithValue: string }
  ) => {
    console.info('Reducer Update', params.employerObject);
    return await axios({
      method: 'put',
      url: `${process.env.REACT_APP_SERVER_URL}/api/v1/employer/update`,
      data: params.employerObject,
      params: {
        isChanged: params.updateJobs,
        updateAllJobRecords: params.updateNewProviderToAllJobs,
      },
      headers: { Authorization: `Bearer ${params.token}` },
    })
      .then((res) => {
        if (res.status !== 200) {
          return isRejectedWithValue('Connection Timeout Error');
        }
        return res.data;
      })
      .catch((err) => {
        return err.message;
      });
  }
);

export const deleteEmployer = createAsyncThunk(
  'employers/deleteEmployer',
  async (params: {
    deletePayload: { creator: string; lookupId: string; removeAllJobs: boolean };
    token: string;
  }) => {
    console.info('Reducer delete employer', params.deletePayload);

    return axios({
      method: 'delete',
      url: `${process.env.REACT_APP_SERVER_URL}/api/v1/employer/delete`,
      data: params.deletePayload,
      headers: { Authorization: `Bearer ${params.token}` },
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.message;
      });
  }
);

const initialState: EmployerSlice = {
  employers: [],
  IEM: [],
  status: 'idle',
  error: null,
  myOrgId: '',
};

const employerSlice = createSlice({
  name: 'employers',
  initialState,
  reducers: {
    fetchEmployerByName: (state: EmployerSlice, action: PayloadAction<Employer>) => {
      return {
        ...state,
        employers: state.employers.filter(
          (employer) => employer.orgName === action.payload.orgName
        ),
      };
    },
    setEmployerStatus: (state: EmployerSlice, action: PayloadAction<string>) => {
      return {
        ...state,
        status: action.payload,
      };
    },
    setAllEmployers: (state: EmployerSlice, action: PayloadAction<Employer[]>) => {
      //console.info('SetEmpList: ', action.payload);
      state.employers = [...action.payload];
    },
    setEmployerMetrics: (state: EmployerSlice, action: PayloadAction<EmployerMetrics[]>) => {
      console.info('Reducer Updating EIM, action.payload: ', action.payload);
      state.IEM = [...action.payload];
    },
    socketEmployerPayload: (
      state: EmployerSlice,
      action: PayloadAction<{ employers: Employer[]; ieMetrics: EmployerMetrics[]; isNew: boolean }>
    ) => {
      const { employers: newEmployers, isNew } = action.payload;
      if (isNew && state.employers.length === 0) {
        void state.employers.push(newEmployers[0]);
      } else {
        const myOrgId = state.employers[0]?.providerOrganizationId || null;
        console.info('employerSocketPayload: ', action.payload);

        if (isNew && newEmployers[0].providerOrganizationId === myOrgId) {
          void state.employers.push(newEmployers[0]); //cannot return anything hence using 'void'.
        } else if (!isNew && myOrgId === newEmployers[0].providerOrganizationId) {
          console.log('employerSocket metric updated');
          let index = state.employers.findIndex((sEmployer) => sEmployer.id === newEmployers[0].id);
          state.employers[index] = newEmployers[0];
        }
      }
    },
    socketEmployerMetrics: (
      state: EmployerSlice,
      action: PayloadAction<{ ieMetrics: EmployerMetrics[]; isNew: boolean }>
    ) => {
      //ieMetrics is only newly updated/create metrics not entire array of ieMetrics
      const { ieMetrics, isNew } = action.payload;

      getUpdatedMetrics(); //either updates already existing or adds new metrics to store based off organization_id of provider.

      function getUpdatedMetrics() {
        let myNewMetrics = ieMetrics.filter((metric) => metric.iemOrg === state.myOrgId);
        console.log('WS employer metrics incoming: ', myNewMetrics.length);
        if (isNew) {
          myNewMetrics.forEach((metric) => {
            if (metric.iemOrg === state.myOrgId) {
              void state.IEM.push(metric); //cannot return anything for updates to render in site
            }
          });
        } else {
          myNewMetrics.forEach((newMetric) => {
            if (newMetric.iemOrg === state.myOrgId) {
              // console.log(state.IEM[0])
              let index = state.IEM.findIndex(
                (currMetric) =>
                  currMetric.iemIndividual === newMetric.iemIndividual &&
                  currMetric.iemEmployer === newMetric.iemEmployer
              );
              // console.info(state.IEM[index]);
              // console.info(newMetric);
              // console.log('--------------------------------');
              state.IEM[index] = { ...newMetric };
            }
          });
          state.IEM = [...state.IEM];
        }
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchEmployerMetrics.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchEmployerMetrics.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // console.info('EMP REDUCER PL' , action.payload.data)
        // const loadedEmployer = action.payload.data.employers.map((employer: Employer) => {
        //   return employer;
        // })
        // state.employers = loadedEmployer;

        const employerMetrics = action.payload.data.metrics.map((metric: EmployerMetrics) => {
          return metric;
        });

        state.IEM = employerMetrics;
        state.myOrgId = action.payload.data.myOrgId;
      })
      .addCase(fetchEmployerMetrics.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message! || 'error employer fetch slice';
      })
      .addCase(postEmployer.fulfilled, (state, action) => {
        //Removed items from here, as if you change state after POST, it blocks redux from properly updating via websocket updates.
        // console.info('postEmployer xReducer payload: ', action.payload);
        // state.IEM = [...action.payload.data.metrics || []];
        // state.employers = [...state.employers];
      })
      .addCase(updateEmployer.fulfilled, (state, action) => {
        console.info('updateEmployer xReducer payload: ', action);
        //console.info('updateEmployer xReducer payload: ', action.payload.data[0]);
        //Removed items from here, as if you change state after POST, it blocks redux from properly updating via websocket updates.
        // const updateIndex = state.employers.findIndex((employer:Employer) => employer.id === action.payload.data.employers[0].id);
        // state.employers[updateIndex] = action.payload.data.employers[0];
        // state.employers = [...state.employers];
        // state.IEM = [...action.payload.data.metrics];
      })
      .addCase(deleteEmployer.fulfilled, (state, action) => {
        console.info('udpate deleted user from system', action.payload.data);
      });
  },
});

export const {
  socketEmployerMetrics,
  fetchEmployerByName,
  setEmployerStatus,
  setEmployerMetrics,
  socketEmployerPayload,
  setAllEmployers,
} = employerSlice.actions;
export const getEmployers = (state: RootState) => [...state.employers.employers];
export const getIEMData = (state: RootState) => [...state.employers.IEM];
export const getEmployerStatus = (state: RootState) => state.employers.status;
export const getEmployerError = (state: RootState) => state.employers.error;

export const getEmployerById = (state: RootState, queryId: string) =>
  state.employers.employers.find((employer) => {
    return employer.id === queryId;
  });

//change this to id when you have DB hookedup
export const selectEmployerByName = (
  state: RootState,
  params: { orgName: string; parentCompName: string }
) =>
  state.employers.employers.find(
    (employer) =>
      employer.orgName === params.orgName && employer.parentCompName === params.parentCompName
  );

export const employerLogo = (state: RootState, empId: string): Employer => {
  return state.employers.employers.find((employer) => employer.id === empId)!;
};

export default employerSlice.reducer;
