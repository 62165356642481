import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { createTheme, ThemeProvider } from '@mui/material';
import { SCHEDULE_OPTIONS } from '../../helpers/constants';
import { Employment } from '../typescript/types';
import dayjs from 'dayjs';
import useTranslate from '../../hooks/useTranslate';
import { capitalize } from '../../helpers/helperFunctions';
import useDetectResize from '../../hooks/useDetectResize';

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: 'Nunito',
          fontWeight: '600',
          lineHeight: '1.92',
          color: 'white',
          borderBottom: 'solid 1px var(--secondary-color)',
          textWrap: 'nowrap',
        },
        body: {
          color: 'black',
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: 'var( --secondary-color)',
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          border: 'solid 2px var(--secondary-color)',
          borderRadius: '22px',
          boxShadow: 'none',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          fontFamily: 'LexendDeca',
        },
      },
    },
  },
});

const IndividualEmploymentTable = (props: { records?: Employment[], current: boolean }) => {
  const { records, current } = props;
  const t = useTranslate();
  const { isMobile, isTablet } = useDetectResize();

  const abbreviateDays = (dayArr: any): string => {
    // console.info('dayArr obj type: ', typeof dayArr);
    // console.log({dayArr})
    let result: string[] = [];
    SCHEDULE_OPTIONS.forEach((dayObj) => {
      if (dayArr.includes(dayObj.text)) result.push(dayObj.abbr);
    });
    return result.join('/');
  };

  return (
    <ThemeProvider theme={theme}>
      <TableContainer
        component={Paper}
        sx={{
          textAlign: 'center',
          'MuiTableCell-root': {
            padding: isMobile || isTablet ? '4px 16px' : '16px',
          },
          '&::-webkit-scrollbar': {
            width: 5,
            height: 2,
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: 'lightgrey',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'var(--primary-color)',
            borderRadius: 2,
          },
        }}
      >
        <Table sx={{ minWidth: 600 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">{t('prof-employmentAccordianStarted')}</TableCell>
              {!current ? (<TableCell align="center">{t('prof-employmentAccordianEnded')}</TableCell>) : null}
              <TableCell align="center">{t('dayCES')}</TableCell>
              <TableCell align="center">{t('hours')}</TableCell>
              <TableCell align="center">{capitalize(t('employer'))}</TableCell>
              <TableCell align="center">{t('role')}</TableCell>
              <TableCell align="center">{t('jobSource')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {records && records?.length > 0 ? (
              records?.map((row, index) => (
                <TableRow
                  key={row.employerName + '-' + index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align="center">{dayjs(row.startDate).format('MM/DD/YYYY')}</TableCell>
                  {!current ? (<TableCell align="center">{dayjs(row.endDate).format('MM/DD/YYYY')}</TableCell>) : null}
                  <TableCell align="center" component="th" scope="row">
                    {abbreviateDays(row.day)}
                  </TableCell>
                  <TableCell align="center">{row.hours}</TableCell>
                  <TableCell align="center">{row.employerName}</TableCell>
                  <TableCell align="center">{row.role}</TableCell>
                  <TableCell align="center">{row.isPlaceMaker ? 'MyJobMatch' : 'Other'}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow key="N/A-1" sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell align="center" component="th" scope="row">
                  {'-'}
                </TableCell>
                {!current ? (<TableCell align="center">{'-'}</TableCell>) : null}
                <TableCell align="center">{'-'}</TableCell>
                <TableCell align="center">{'-'}</TableCell>
                <TableCell align="center">{'-'}</TableCell>
                <TableCell align="center">{'-'}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </ThemeProvider>
  );
};

export default IndividualEmploymentTable;
