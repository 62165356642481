import { Autocomplete } from '@mui/material';
import { Control, Controller, FieldValues, UseFormRegister } from 'react-hook-form';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

import { EmployerFormData } from '../EmployerComponents/EmployersCreateComponent';
import { IndividualFormData } from '../IndividualComponents/IndividualCreateComponent';
import { JobFormData } from '../JobComponents/JobCreateComponent';
import { FormTextInput } from '../UI/StyledComponents/FormsStyledComponents';

export type GlobalFormData = EmployerFormData | IndividualFormData | JobFormData | FormData;

// PropsControllerTextField could be an interface too.
interface ControllerAutoCompleteProps<GlobalFormData extends FieldValues> {
  register: UseFormRegister<Partial<GlobalFormData>>;
  control: Control<Partial<GlobalFormData>>;
  name: any;
  rules?: {
    required?: boolean | string;
    pattern?: { value: RegExp; message: string };
    maxLength?: number;
    validate?: () => boolean;
  };
  className?: string;
  required?: boolean;
  placeholder: string;
  id?: string;
  label?: string;
  focused?: boolean;
  helperText?: string;
  optionItems: string[];
  throwMessage: string;
  disabled?: boolean;
  handleInput?: (input?: string) => void;
}

const ControllerAutoComplete = (props: ControllerAutoCompleteProps<GlobalFormData>) => {
  const {
    className,
    control,
    name,
    placeholder,
    required,
    register,
    label,
    id,
    optionItems,
    throwMessage,
    helperText,
    rules,
    disabled,
    handleInput,
  } = props;

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, value } }) => (
        <Autocomplete
          id={id}
          freeSolo
          options={optionItems}
          disabled={disabled}
          value={value}
          sx={{
            '& .MuiFormControl-root': {
              marginTop: '0px !important',
            },
          }}
          renderInput={(params) => (
            <FormTextInput
              {...params}
              {...register(name)}
              id="outlined-required"
              className={className}
              value={value}
              label={label}
              margin="normal"
              placeholder={placeholder}
              required={required}
              helperText={helperText}
              onChange={(e) => {
                handleInput?.(e.target.value!);
              }}
              sx={{
                '& .MuiAutocomplete-root': {
                  padding: '4.5px 4px 7.5px 6px',
                },
              }}
            />
          )}
          renderOption={(props, option, { inputValue }) => {
            const matches = match(option, inputValue, { insideWords: true });
            const parts = parse(option, matches);

            return (
              <li {...props}>
                <div>
                  {inputValue === option ? (
                    <span style={{ color: 'red' }}>{throwMessage}</span>
                  ) : (
                    parts.map((part: any, index: number) => (
                      <span
                        key={index + '-' + part.text}
                        style={{
                          fontWeight: part.highlight ? 700 : 400,
                          color: part.highlight ? 'red' : 'black',
                        }}
                      >
                        {part.text}
                      </span>
                    ))
                  )}
                </div>
              </li>
            );
          }}
        />
      )}
    />
  );
};

export default ControllerAutoComplete;

// Use this to pass into PropsControlleTextField > contorl property.

// interface FormData extends  (EmploeyrFormData | IndividualFormDate | JobFormData ){

// }

// type FormData   <- 99% of the time same as  interface types, cannot be extensible it's 'defined once'. Types can alias anything.
// keep interfaces of the border of your api and types inside.
//
